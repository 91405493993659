import React, { Component } from "react";
import { NavLink } from 'react-router-dom';

export class ProtectionSavingsCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            protectionCurrentAmount: this.props.protectionCurrentAmount,
            protectionsavingsCurrentAmount: this.props.protectionsavingsCurrentAmount
        };
    }

    render() {
        return (
            <div className="protecao">
                <div className="title-home">
                    Proteção
                </div>
                <div className="protecaopoupancadiv">
                    <NavLink to="/protecao" style={{ "textDecoration": "none" }}>
                        <div className="protecaopoupanca" style={{ "verticalAlign": "top" }}>
                            <img src={require('../../images/protecaopoupanca.svg')} alt=""></img>
                            <div className="poupanca-income-protecao">
                                {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(this.state.protectionsavingsCurrentAmount)}
                            </div>
                            <div className="poupanca-gain">
                                Proteção+Poupança
                            </div>
                            <div className="poupanca-description">
                                Capital Subscrito
                            </div>
                            <div className="subscribe" style={{ "marginTop": "23px" }}>
                                <img src={require('../../images/more.png')} alt="Botão de subscrever" width="60px" height="60px"></img>
                                <div>
                                    Subscrever
                                </div>
                            </div>
                        </div>
                    </NavLink>
                    <NavLink to="/protecao" style={{ "textDecoration": "none" }}>
                        <div className="protecaomod">
                            <img src={require('../../images/protecao.svg')} alt=""></img>
                            <div className="poupanca-income-protecao" style={{ "paddingTop": "12px" }}>
                                {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(this.state.protectionCurrentAmount)}
                            </div>
                            <div className="poupanca-gain">
                                Proteção
                            </div>
                            <div className="poupanca-description">
                                Capital Protegido
                            </div>
                            <div className="subscribe" style={{ "marginTop": "23px" }}>
                                <img src={require('../../images/more.png')} alt="Botão de subscrever" width="60px" height="60px"></img>
                                <div>
                                    Subscrever
                                </div>
                            </div>
                        </div>
                    </NavLink>
                </div>
            </div>
        )
    }
}