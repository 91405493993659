/* COMPONENT IMPORTS*/
import ProductDetails from '../../components/ProductDetails/ProductDetails';
//import { GlossaryPopup } from "../../components/Glossary/GlossaryPopup";
import ContextConstructor from "../../components/Context/Context";

/* MODULE IMPORTS */
import React, { Component } from 'react';
import Collapsible from 'react-collapsible';
//import ReactTable from "react-table-v6";
import Popup from "reactjs-popup";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';
import { createBrowserHistory } from "history";
import { withRouter } from 'react-router';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';

/* SAME FOLDER IMPORTS*/
import ProtectionSubscription from './ProtectionSubscription';
import ProtectionSubscriptionMobile from './ProtectionSubscriptionMobile';

/* CSS IMPORT */

/* VARIABLES */
const browserHistory = createBrowserHistory({ basename: 'Savings Page' });
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: 'a20b3d0d-87b9-41e6-bcde-892a425215fe',
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();

class TableSubscriptions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpened: [],

        }
        this.arrowIcon = []
    }

    transformActives = (a) => {
        if (a == '1')
            return a + ' subscrição ativa';
        else
            return a + ' subscrições ativas';
    }

    changeValueColor(value) {
        if (value != "0.0") {
            return (
                <React.Fragment>
                    <div className="table-applied">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value)}</div>
                </React.Fragment>
            )
        }
        else {
            return (
                <React.Fragment>
                    <div className="table-applied" style={{ "color": "#B2B2B2" }}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value)}</div>
                </React.Fragment>
            )
        }
    }

    toggleCollapsible(index) {
        //Altera a variavel de estado
        let temp = this.state.isOpened;
        temp[index] = !temp[index];
        this.setState({
            isOpened: temp
        })
        //faz triger do click para abrir/fechar a modalidade
        this.arrowIcon[index].click();
    }

    renderDetailsPopup(index, item) {
        return (
            <Popup
                key={index}
                trigger={<div style={{ 'display': 'none' }} id={'popupOpennerTrigger' + index}></div>}
                contentStyle={{ "background": "none", "border": "none" }} modal closeOnDocumentClick>
                {close =>
                    <div className="modalidades-mobile-detalhes">
                        <div className="title-section">
                            <div className="title-main">
                                {item.productName}
                                <img src={require('../../images/close.png')} alt="Fechar" onClick={close} style={{ "cursor": "pointer" }} />
                            </div>
                            <div className="title-subtitle">{this.transformSubscribe(item.subscriptionsNumber)}</div>
                        </div>
                        <div className="montantes-section">
                            <div className="tableCapitalCerto-amount">
                                <div className="tableCapitalCerto-givenvalue">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item.totalAmounts.totalCurrentAmount)}</div>
                                <div className="tableCapitalCerto-subtitle">Capital Protegido</div>
                            </div>
                        </div>
                        {item.subscriptions.map((subscription, indexSubscription) => {
                            if (item.productID == "7190") /*PROTEÇÃO CRÉDITO INDIVIDUAL*/ {
                                let contractInformation = (subscription.contractNumber == "" || subscription.contractNumber == null) ? null : <div className="subscricao-detalhe"><div className="label">N.º Contrato</div><div className="value">{subscription.contractNumber}</div></div>;
                                return (<div className="serie-mobile-wrapper">
                                    <div className="serie-mobile">
                                        <div className="subscricao-detalhe">
                                            <div className="label">N.º Incrição</div>
                                            <div className="value">
                                                {subscription.subscriptionNumber}
                                            </div>
                                        </div>
                                        {contractInformation}
                                        <div className="subscricao-detalhe">
                                            <div className="label">Prazo</div>
                                            <div className="value">
                                                {subscription.deadline} {subscription.deadline == 1 ? " Ano" : " Anos"}
                                            </div>
                                        </div>
                                        <div className="subscricao-detalhe">
                                            <div className="label">Data de início</div>
                                            <div className="value">
                                                {new Intl.DateTimeFormat('pt-PT').format(new Date(subscription.subscriptionDate))}
                                            </div>
                                        </div>
                                        <div className="subscricao-detalhe">
                                            <div className="label">Estado</div>
                                            <div className="value">
                                                {subscription.state}
                                            </div>
                                        </div>
                                        <div className="subscricao-detalhe">
                                            <div className="label">Capital subscrito</div>
                                            <div className="value">
                                                {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(subscription.currentAmount)}
                                            </div>
                                        </div>
                                        <div className="subscricao-detalhe">
                                            <div className="label">Valor da quota</div>
                                            <div className="value">
                                                {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(subscription.totalAmount)}
                                            </div>
                                        </div>
                                    </div>
                                </div>)
                            } else if (item.productID == "7170") /*PROTEÇÃO OUTROS ENCARGOS*/ {

                                return (<div className="serie-mobile-wrapper">
                                    <div className="serie-mobile">
                                        <div className="subscricao-detalhe">
                                            <div className="label">N.º Incrição</div>
                                            <div className="value">
                                                {subscription.subscriptionNumber}
                                            </div>
                                        </div>
                                        <div className="subscricao-detalhe">
                                            <div className="label">Prazo</div>
                                            <div className="value">
                                                {subscription.deadline} {subscription.deadline == 1 ? " Ano" : " Anos"}
                                            </div>
                                        </div>
                                        <div className="subscricao-detalhe">
                                            <div className="label">Data de início</div>
                                            <div className="value">
                                                {new Intl.DateTimeFormat('pt-PT').format(new Date(subscription.subscriptionDate))}
                                            </div>
                                        </div>
                                        <div className="subscricao-detalhe">
                                            <div className="label">Estado</div>
                                            <div className="value">
                                                {subscription.state}
                                            </div>
                                        </div>
                                        <div className="subscricao-detalhe">
                                            <div className="label">Capital subscrito</div>
                                            <div className="value">
                                                {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(subscription.currentAmount)}
                                            </div>
                                        </div>
                                        <div className="subscricao-detalhe">
                                            <div className="label">Valor da quota</div>
                                            <div className="value">
                                                {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(subscription.totalAmount)}
                                            </div>
                                        </div>
                                        <div className="subscricao-detalhe">
                                            <div className="label">Periodicidade da quota</div>
                                            <div className="value">
                                                {subscription.periodDescription}
                                            </div>
                                        </div>
                                    </div>
                                </div>)
                            } else { /*Proteção Crédito Habitação*/
                                let contractInformation = (subscription.contractNumber == "" || subscription.contractNumber == null) ? null : <div className="subscricao-detalhe"><div className="label">N.º Contrato</div><div className="value">  {subscription.contractNumber}  </div></div> ;
                                return (
                                    <div className="serie-mobile-wrapper">
                                        <div className="serie-mobile">
                                            <div className="subscricao-detalhe">
                                                <div className="label">N.º Incrição</div>
                                                <div className="value">
                                                    {subscription.subscriptionNumber}
                                                </div>
                                            </div>
                                            {contractInformation}
                                            <div className="subscricao-detalhe">
                                                <div className="label">Data de início</div>
                                                <div className="value">
                                                    {new Intl.DateTimeFormat('pt-PT').format(new Date(subscription.subscriptionDate))}
                                                </div>
                                            </div>
                                            <div className="subscricao-detalhe">
                                                <div className="label">Estado</div>
                                                <div className="value">
                                                    {subscription.state}
                                                </div>
                                            </div>
                                            <div className="subscricao-detalhe">
                                                <div className="label">Capital subscrito</div>
                                                <div className="value">
                                                    {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(subscription.currentAmount)}
                                                </div>
                                            </div>
                                            <div className="subscricao-detalhe">
                                                <div className="label">Valor da quota</div>
                                                <div className="value">
                                                    {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(subscription.totalAmount)}
                                                </div>
                                            </div>
                                            <div className="subscricao-detalhe">
                                                <div className="label">Periodicidade da quota</div>
                                                <div className="value">
                                                    {subscription.periodDescription}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        }
                        )}
                        <div className="close-detalhes-protecao-wrapper">
                            <div className="close-detalhes-protecao">
                                <div onClick={close}>Fechar</div>
                            </div>
                        </div>
                    </div>
                }
            </Popup>
        );
    }

    openPopup(index) {
        //faz triger do click para abrir os detalhes especificos
        document.getElementById('popupOpennerTrigger' + index).click();
    }

    openDetalhesPopup(index) {
        //faz triger do click para abrir os detalhes especificos
        document.getElementById('popupDetalhesTrigger' + index).click();
    }

    openGlossary() {
        //faz triger do click para abrir os detalhes especificos
        document.getElementById('glossaryTrigger').click();
    }

    renderMobileProtectionList() {

        let itemsToRender = this.props.protectionList.productTypes;

        let nItemsToRender = itemsToRender.length;

        let content = nItemsToRender == 0 ? "" : (
            <React.Fragment>
                {itemsToRender.map((item, index) =>
                    this.renderDetailsPopup(index, item)
                )}

                <div className="consulta-detalhes-clicaveis-poupanca">
                    {itemsToRender.map((item, index) =>
                        <ProductDetails key={index} elementID={'popupDetalhesTrigger' + index} productID={item.productID} subscriptionType="product" productName={item.productName} subscriptionActive={false} />
                    )}
                </div>

                <div className="carousel-wrapper">
                    <CarouselProvider
                        naturalSlideWidth={400}
                        naturalSlideHeight={500}
                        totalSlides={nItemsToRender}
                        visibleSlides={1}
                    >
                        <Slider className="carousel-slider-protecao">
                            {itemsToRender.map((item, index) =>
                                <Slide key={index} index={index}>
                                    <div className="modalidades modalidades-mobile">
                                        <div className="tableCapitalCerto">
                                            <div style={{ "cursor": "pointer" }} onClick={this.openDetalhesPopup.bind(this, index)} className="tableCapitalCerto-orangeback">
                                                <div className="tableCapitalCerto-title">{item.productName}</div>
                                                <div className="tableCapitalCerto-titlesubtitle">{this.transformSubscribe(item.subscriptionsNumber)}</div>
                                            </div>
                                            <div className="tableCapitalCerto-amount">
                                                <div className="tableCapitalCerto-givenvalue">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item.totalAmounts.totalCurrentAmount)}</div>
                                                <div className="tableCapitalCerto-subtitle" onClick={this.openGlossary.bind()} style={{ "cursor": "pointer" }}>Capital atual</div>
                                            </div>
                                            <div className="tableCapitalCerto-amount">
                                                <div className="tableCapitalCerto-boldvalue-active">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item.totalAmounts.totalPaidAmount)}</div>
                                                <div className="tableCapitalCerto-subtitle">Entrega periódica</div>
                                            </div>
                                            <div onClick={this.openPopup.bind(this, index)} className="consultar-detalhes">Ver detalhe</div>
                                        </div>
                                    </div>
                                </Slide>
                            )}
                        </Slider>
                        <DotGroup />
                    </CarouselProvider>
                </div>
            </React.Fragment >
        );

        return content
    }

    transformSubscribe(a) {
        if (a == '1')
            return a + ' subscrição';
        else
            return a + ' subscrições';
    }
    
    createSubscription(item) {
        var data = {
            subscriptionID: null,
            subscriptionSerieID: null,
            canReapply: false,
            canReinforce: false,
            /**startDateBFTeaser: null,
            endDateBFTeaser: null,
            startDateBF: null,
            endDateBF: null,
            actualDate: null**/
        };
        this.props.history.push({
            pathname: '/subscricao',
            state: { params: data, modalidade: item }
        })
    }

    //ProtectionCards() {
    //    let products = this.props.protectionList.productTypes;
    //    let isCoincidence = false;

    //    products.forEach((productElement) => {

    //        if (productElement['productID'] == '7170') {
    //            isCoincidence = true;
    //        }

    //    });

    //    let content = isCoincidence === true ? "" : (
    //            //<div className="card-new">
    //            //    <div className="card-new-table">
    //            //            <div className="card-new-title">
    //            //                    <div className="serie-name-wrapper" style={{ "position": "initial !important" }}>
    //            //                        <div style={{
    //            //                            "font-family": "rawline",
    //            //                            "font-weight": "bold",
    //            //                            "font-size": "18px",
    //            //                            "line-height": "140%",
    //            //                            "height": "100px",
    //            //                            "width": "215px",
    //            //                            "padding": "31px 15px 31px 30px",
    //            //                            "background": "rgba(109,110,113,.05)",
    //            //                            "color": "#6d6f71",
    //            //                            "cursor": "default",
    //            //                        }}>
    //            //                        PROTEÇÃO OUTROS ENCARGOS
    //            //                    </div>
    //            //                </div>
    //            //            </div>
    //            //            <div className="tableCapitalCerto-amount">
    //            //                    <div className="card-new-table-value">0 €</div>
    //            //                    <div className="tableCapitalCerto-subtitle-unactive">Montante entregue</div>
    //            //            </div>
    //            //            <div className="tableCapitalCerto-amount">
    //            //                <div className="tableCapitalCerto-boldvalue">-</div>
    //            //                <div className="tableCapitalCerto-subtitle-unactive">Rendimento acumulado Bruto</div>
    //            //            </div>
    //            //            <div className="create-subscription-button" onClick={this.createSubscription.bind(this, {
    //            //                    /*"totalAmounts": {
    //            //                        "totalCurrentAmount": "20000.00",
    //            //                        "totalPaidAmount": "4.90"
    //            //                    },*/
    //            //                    "subscriptions": [
    //            //                        {
    //            //                            /*"currentAmount": "20000.00",
    //            //                            "paidAmount": "4.90",
    //            //                            "period": "M01",
    //            //                            "totalAmount": 4.9,
    //            //                            "contractNumber": "",
    //            //                            "periodDescription": "Mensal",*/
    //            //                            "associateID": null,
    //            //                            "associateNumber": null,
    //            //                            /*"subscriptionID": "717770254809",
    //            //                            "subscriptionNumber": "4",
    //            //                            "subscriptionDate": "2015-01-01T00:00:00",
    //            //                            "subscriptionEndDate": "2030-01-01T00:00:00",
    //            //                            "deadline": 15,*/
    //            //                            "state": "Pendente de inscrição"
    //            //                        }
    //            //                    ],
    //            //                    "productID": "7170", /*provavelmente somente isso aqui*/
    //            //                    "productName": "PROTEÇÃO OUTROS ENCARGOS",
    //            //                    "productGroup": "000002",
    //            //                    "subscriptionsNumber": "1"
    //            //                })}>
    //            //                < img src={require('../../images/newseries.png')} style={{ "cursor": "pointer" }} height="50px" width="50px"></img>
    //            //            </div>
    //            //    </div> 
    //            //</div>
    //        <div className="card-new-sub card-new-sub-mobile" > {/*card-new*/}
    //            <table className="card-new-sub-table">
    //                <tbody>
    //                    <tr>
    //                        {/*<th style={{*/}
    //                        {/*    "font-family": "rawline",*/}
    //                        {/*    "font-weight": "bold",*/}
    //                        {/*    "font-size": "18px",*/}
    //                        {/*    "line-height": "140%",*/}
    //                        {/*    "height": "100px",*/}
    //                        {/*    "width": "auto",*/}
    //                        {/*    "padding": "31px 15px 31px 30px",*/}
    //                        {/*    "background": "rgba(109,110,113,.05)",*/}
    //                        {/*    "color": "#6d6f71",*/}
    //                        {/*    "cursor": "default",*/}
    //                        {/*}}>*/}
    //                        <th className="card-new-subs-table-title">
    //                            PROTEÇÃO OUTROS ENCARGOS
    //                        </th>
    //                        <th style={{ "width": "155px", "paddingLeft": "20px" }}>
    //                            <div className="card-new-sub-table-value">0 €</div>
    //                            <div className="card-new-sub-table-subtitle-unactive">Montante entregue</div>
    //                        </th>
    //                        <th style={{ "width": "205px", "paddingLeft": "10px" }}>
    //                            <div className="card-new-sub-table-boldvalue">-</div>
    //                            <div className="card-new-sub-table-subtitle-unactive">Rendimento acumulado Bruto</div>
    //                        </th>
    //                        <th>
    //                            {/*<div className="create-subscription-button" onClick={this.createSubscription.bind(this, item)}>< img src={require('../../images/newseries.png')} style={{ "cursor": "pointer" }} height="50px" width="50px"></img></div>*/}
    //                            <div className="card-new-sub-create-subscription-button"
    //                                onClick={this.createSubscription.bind(this, {
    //                                /*"totalAmounts": {
    //                                    "totalCurrentAmount": "20000.00",
    //                                    "totalPaidAmount": "4.90"
    //                                },*/
    //                                "subscriptions": [
    //                                    {
    //                                        /*"currentAmount": "20000.00",
    //                                        "paidAmount": "4.90",
    //                                        "period": "M01",
    //                                        "totalAmount": 4.9,
    //                                        "contractNumber": "",
    //                                        "periodDescription": "Mensal",*/
    //                                        "associateID": null,
    //                                        "associateNumber": null,
    //                                        /*"subscriptionID": "717770254809",
    //                                        "subscriptionNumber": "4",
    //                                        "subscriptionDate": "2015-01-01T00:00:00",
    //                                        "subscriptionEndDate": "2030-01-01T00:00:00",
    //                                        "deadline": 15,*/
    //                                        "state": "Pendente de inscrição"
    //                                    }
    //                                ],
    //                                "productID": "7170", /*provavelmente somente isso aqui*/
    //                                "productName": "PROTEÇÃO OUTROS ENCARGOS",
    //                                "productGroup": "000002",
    //                                "subscriptionsNumber": "1"
    //                            })}>
    //                                < img src={require('../../images/newseries.png')} style={{ "cursor": "pointer" }} height="50px" width="50px"></img>
    //                            </div>
    //                        </th>
    //                    </tr>
    //                </tbody>
    //            </table>
    //        </div> 
    //    )
    //    return content;
    //}

    render() {
        function tableContent(subscriptionID, currentAmount, paidAmount, totalPaidAmount, subscriptionNumber, subscriptionDate, deadline, initialAmount, growthrate, initialPaidAmount, receivingAge, subscriptionEndDate, contractNumber, totalAmount, periodDescription, state) {
            if (subscriptionID == "7190") /*PROTEÇÃO CRÉDITO INDIVIDUAL*/ {
                let contractInfo = (contractNumber == "" || contractNumber == null) ? "N.º Inscrição: " + subscriptionNumber : "N.º Inscrição: " + subscriptionNumber + " | N.º Contrato: " + contractNumber;
                return (
                    <div className="table">
                        <table className="detalhe-totalentregue-table">
                            <tbody>
                                <tr className="detalhe-totalentregue-tr">
                                    <td><div className="detalhe-totalentregue-label">{contractInfo}</div></td>
                                    <td><div className="detalhe-totalentregue-prazo">Prazo: {deadline} {deadline == 1 ? " Ano" : " Anos"}</div></td>
                                    <td style={{ "width": "33%" }}><div className="detalhe-totalentregue-valor">Data de Início: {new Intl.DateTimeFormat('pt-PT').format(new Date(subscriptionDate))}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Estado</div></td>
                                    <td colSpan="2"><div className="detalhe-outros-valor">{state}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Capital subscrito</div></td>
                                    <td><div className="detalhe-outros-label"></div></td>
                                    <td><div className="detalhe-outros-valor">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(currentAmount)}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Valor da quota</div></td>
                                    <td><div className="detalhe-outros-label"></div></td>
                                    <td><div className="detalhe-outros-valor">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(totalAmount)}</div></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )
            }  
            if (subscriptionID == "7170") /*PROTEÇÃO OUTROS ENCARGOS*/ {
                return (
                    <div className="table">
                        <table className="detalhe-totalentregue-table">
                            <tbody>
                                <tr className="detalhe-totalentregue-tr">
                                    <td><div className="detalhe-totalentregue-label">N.º Inscrição: {subscriptionNumber}</div></td>
                                    <td><div className="detalhe-totalentregue-prazo">Prazo: {deadline} {deadline == 1 ? " Ano" : " Anos"}</div></td>
                                    <td style={{ "width": "33%" }}><div className="detalhe-totalentregue-valor">Data de Início: {new Intl.DateTimeFormat('pt-PT').format(new Date(subscriptionDate))}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Estado</div></td>
                                    <td colSpan="2"><div className="detalhe-outros-valor">{state}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Capital subscrito</div></td>
                                    <td><div className="detalhe-outros-label"></div></td>
                                    <td><div className="detalhe-outros-valor">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(currentAmount)}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Valor da quota</div></td>
                                    <td><div className="detalhe-outros-label"></div></td>
                                    <td><div className="detalhe-outros-valor">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(totalAmount)}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Periodicidade da quota</div></td>
                                    <td><div className="detalhe-outros-label"></div></td>
                                    <td><div className="detalhe-outros-valor">{periodDescription}</div></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )
            }  
            else { /*Proteção Crédito Habitação*/
                let contractInfo = (contractNumber == "" || contractNumber == null) ? "N.º Inscrição: "+subscriptionNumber : "N.º Inscrição: "+subscriptionNumber+" | N.º Contrato: "+contractNumber ;
                    return (
                        <div className="table">
                            <table className="detalhe-totalentregue-table">
                                <tbody>
                                    <tr className="detalhe-totalentregue-tr">
                                        <td><div className="detalhe-totalentregue-label-with-contract">{contractInfo}</div></td>
                                        <td></td>
                                        <td style={{ "width": "33%" }}><div className="detalhe-totalentregue-valor">Data de Início: {new Intl.DateTimeFormat('pt-PT').format(new Date(subscriptionDate))}</div></td>
                                    </tr>
                                    <tr>
                                        <td><div className="detalhe-outros-label">Estado</div></td>
                                        <td colSpan="2"><div className="detalhe-outros-valor">{state}</div></td>
                                    </tr>
                                    <tr>
                                        <td><div className="detalhe-outros-label">Capital subscrito</div></td>
                                        <td><div className="detalhe-outros-label"></div></td>
                                        <td><div className="detalhe-outros-valor">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(currentAmount)}</div></td>
                                    </tr>
                                    <tr>
                                        <td><div className="detalhe-outros-label">Valor da quota</div></td>
                                        <td><div className="detalhe-outros-label"></div></td>
                                        <td><div className="detalhe-outros-valor">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(totalAmount)}</div></td>
                                    </tr>
                                    <tr>
                                        <td><div className="detalhe-outros-label">Periodicidade da quota</div></td>
                                        <td><div className="detalhe-outros-label"></div></td>
                                        <td><div className="detalhe-outros-valor">{periodDescription}</div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                )
            }
        }

        
        return (
            <React.Fragment>
                <div className="modalidades-bar modalidades-bar-protecao">
                    <div className="resumo-poupanca">
                        <div className="first-div">
                            <div className="table-modalidades">Proteção</div>
                            <div className="table-subscricoes">{this.transformActives(this.props.protectionList.subscriptionsNumber)}</div>
                        </div>
                        <div className="second-div">
                            <div style={{ "paddingRight": "10px", "paddingBottom": "5px" }}><img src={require('../../images/protecao.png')} height="48px" width="48px" alt="protação"></img></div>
                            <div>
                                <div className="table-appliedtitle">Capital Protegido</div>
                                <div>{this.changeValueColor(this.props.protectionList.totalAmounts.totalCurrentAmount)}</div>
                            </div>
                        </div>
                        <div className="third-div">
                            <div className="table-modalidades">Proteção</div>
                            <div className="table-subscricoes">{this.transformActives(this.props.protectionList.subscriptionsNumber)}</div>
                        </div>
                    </div>
                </div>
                <div className="card-box">
                    {this.props.protectionList.productTypes.map((item, headerkey) =>
                        <div key={headerkey} className="protecao-card">
                            {/*{ console.log(item)}*/}
                            <table className="tg">
                                <tbody>
                                    <tr key={headerkey}>
                                        <td className="card-protecao-lista-col1-firstline-single">
                                            <div className="table-value-col1"><ProductDetails productID={item.productID} productName={item.productName} subscriptionsNumber={item.subscriptionsNumber} subscriptionActive={true} /></div>
                                        </td>
                                        <td className="capital-subscrito-valor">
                                            <div className="table-value-col2">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item.totalAmounts.totalCurrentAmount)}</div>
                                            <div className="table-value2-col2" onClick={this.openGlossary.bind()} style={{ "cursor": "pointer" }}>Capital Subscrito &nbsp;&nbsp;&nbsp;&nbsp;</div>
                                        </td>
                                        <td className="entrega-periodica-valor">
                                            <div className="table-value-col3">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item.totalAmounts.totalPaidAmount)}</div>
                                            <div className="table-value2-col2">Entrega Periódica</div>
                                        </td>
                                        <td style={{ "paddingBottom": "0px" }}>
                                            <div className={this.state.isOpened[headerkey] ? "tableCapitalCerto-img opened" : "tableCapitalCerto-img closed"} ><img onClick={this.toggleCollapsible.bind(this, headerkey)} src={require('../../images/expand.png')} width="14px" height="14px" alt="capital certo"></img></div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <Collapsible trigger={<div ref={(input) => { this.arrowIcon[headerkey] = input }} style={{ 'display': 'none' }} ></div>}>

                                {/*ALTERAR FUNCIONAMENTO DO MAP*/}

                                {item.subscriptions.map((subscription, subscriptionkey) =>
                                    <div key={subscriptionkey}>
                                        {tableContent(item.productID, subscription.currentAmount, subscription.paidAmount, subscription.totalPaidAmount, subscription.subscriptionNumber, subscription.subscriptionDate, subscription.deadline, subscription.initialAmount, subscription.growthrate, subscription.initialPaidAmount, subscription.receivingAge, subscription.subscriptionEndDate, subscription.contractNumber, subscription.totalAmount, subscription.periodDescription, subscription.state)}
                                    </div>
                                )}

                                {/*TO SEE*/}
                             
                               {/* {((item.productID == "7170" && context.tokenUser === this.context.selectedUserID) ?*/}
                                {(item.productID == "7170" ?
                                    (""
                                        //<div className="new-series">
                                        //    <div style={{ "width": "50%", "paddingTop": "2%" }}>
                                        //        <div style={{
                                        //            "font-family": "rawline",
                                        //            "font-weight": "700",
                                        //            "font-size": "18px",
                                        //            "line-height": "140%",
                                        //            "display": "flex",
                                        //            "align-items": "center",
                                        //            "color": "#373737",
                                        //            "margin-bottom": "20px",
                                        //            "margin-top": "-3%",
                                        //        }}>
                                        //            Subscrever {item.productName}</div>
                                        //    </div>
                                        //    <div style={{ "width": "50%" }} >
                                        //        <img src={require('../../images/newseries.png')} onClick={this.createSubscription.bind(this, item)} className="new-series-img" width="50px" height="50px">
                                        //        </img>
                                        //    </div>
                                        //</div>
                                    ) :""        
                                )}
                                
                            </Collapsible>
                        </div>
                    )}
                </div>
                {/*{this.ProtectionCards()}*/}
                <ProtectionSubscription protectionList={this.props.protectionList} />
                <ProtectionSubscriptionMobile protectionList={this.props.protectionList} />
                {this.renderMobileProtectionList()}
            </React.Fragment>
        );
    }
}

TableSubscriptions.contextType = ContextConstructor;

export default withRouter(TableSubscriptions);