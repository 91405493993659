
/* COMPONENT IMPORTS*/
import ProductDetails from '../../components/ProductDetails/ProductDetails';
import CurrentAccountPopUp from './CurrentAccountPopUp';
import authentication from "../../msalb2creact";
import ContextConstructor from "../../components/Context/Context";

/* MODULE IMPORTS */
import { withRouter } from 'react-router';
import ReactTable from "react-table-v6";
import React, { Component } from 'react';
import Collapsible from 'react-collapsible';

/* SAME FOLDER IMPORTS*/

/* CSS IMPORT */
import "./tablestyling.css";

class SavingsItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            CanReapply: false,
            CanReinforce: false,
            collapsibleOpen: [],
            isOpened: [],
            /**actualDate: null,
            startDateBFTeaser: null,
            endDateBFTeaser: null,
            startDateBF: null,
            endDateBF: null,**/
            actualDateAVOA: null,
            startDateAVOA: null,
            endDateAVOA: null,
            flagAVOA: false
            
        }
        this.arrowIcon = []
        
    }

    componentDidMount() {
        this.getAVOApopup();
        //this.GetBlackWeekDates();
    
      }

    createSubscription(item) {
        var data = {
            subscriptionID: null,
            subscriptionSerieID: null,
            canReapply: false,
            canReinforce: false
        };
        this.props.history.push({
            pathname: '/subscricao',
            state: { params: data, modalidade: item }
        })
    }

    createSubscriptionRow(item) {
        var modalidade = null;

        this.props.savingsList.filter(item => item.subscriptionsNumber > 0).forEach(function (savings) {
            savings.subscriptions.forEach(function (subscription) {
                if (subscription.subscriptionID === item.subscriptionID) {
                    modalidade = savings;
                }
            });
        });

        this.props.history.push({
            pathname: '/subscricao',
            state: { params: item, modalidade: modalidade }
        })
    }

    transformSubscribe(a) {
        if (a == '1')
            return a + ' subscrição';
        else
            return a + ' subscrições';
    }

    changeCollapsible() {
        var x = this.state.collapsibleOpen;
        this.setState(
            {
                collapsibleOpen: !x,
            }
        );
    }

    changeSubscribeText(id) {
        if (id == "7385") {
            return "série";
        }
        else {
            return "inscrição";
        }
    }

    dynamicColumns(id) {
        if (id != "7385") {
            return [{
                Header: '',
                headerClassName: 'table-header-poupanca',
                headerStyle: {
                    textAlign: 'right'
                },
                Cell: ({ original }) => (<ProductDetails productID={id} subscriptionSerieID={original.subscriptionSerieID} subscriptionsSerie={original.subscriptionSerie} subscriptionType="serie" subscriptionState={original.subscriptionState} subscriptionNumber={original.subscriptionNumber} currentAccount={original.currentAccount} />)
            }, {
                Header: <div style={{ "marginLeft": "40px" }}>Montante Poupado</div>,
                headerClassName: 'table-header-poupanca',
                headerStyle: {
                    textAlign: 'right'
                },
                Cell: ({ original }) => (
                    original.subscriptionState == "ATIVA" ? <div className="SavingsItems-value right-align" >{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(original.investedAmount)}</div > : <div className="SavingsItems-value right-align" style={{ "color": "#B2B2B2" }}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(original.investedAmount)}</div >
                )
            }, {
                Header: <div style={{ "marginLeft": "40px" }}>Rendimento Acumulado</div>,
                headerClassName: 'table-header-poupanca',
                headerStyle: {
                    textAlign: 'right'
                },
                Cell: ({ original }) => (
                    original.subscriptionState == "ATIVA" ? <div className="SavingsItems-value right-align">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(original.receivedAmount)}</div> : <div className="SavingsItems-value right-align" style={{ "color": "#B2B2B2" }}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(original.receivedAmount)}</div >
                )
            }, {
                Header: <div style={{ "marginLeft": "40px" }}>Data Subscrição</div>,
                headerClassName: 'table-header-poupanca',
                headerStyle: {
                    textAlign: 'right'
                },
                Cell: ({ original }) => (
                    original.subscriptionState == "ATIVA" ? <div className={"SavingsItems-date right-align"}>{new Intl.DateTimeFormat('pt-PT').format(new Date(original.subscriptionDate))}</div> : <div className={"SavingsItems-date right-align"} style={{ "color": "#B2B2B2" }}>{new Intl.DateTimeFormat('pt-PT').format(new Date(original.subscriptionDate))}</div>
                )
            }, {
                Header: '',
                    Cell: ({ original }) => (original.canReapply ?
                        <div className="reaplicar-reforcar-buttons"><img className="reaplicar-button" src={require('../../images/reaplicar.png')} style={{ "cursor": "pointer", "margin-right":"5px , " }} width="98px" height="30px" onClick={this.createSubscriptionRow.bind(this, original)} ></img><CurrentAccountPopUp CurrentAccountList={original.currentAccount}> </CurrentAccountPopUp></div>
                        : original.canReinforce ?
                            <div className="reaplicar-reforcar-buttons" style={{ "display": "inline-flex" }}>< img className="reforcar-button" src={require('../../images/reinforce.png')} style={{ "cursor": "pointer", "margin-right": "5px"  }} width="98px" height="30px" onClick={this.createSubscriptionRow.bind(this, original)} ></img><CurrentAccountPopUp CurrentAccountList={original.currentAccount}> </CurrentAccountPopUp></div>
                            : <CurrentAccountPopUp CurrentAccountList={original.currentAccount}> </CurrentAccountPopUp>)
            }]
        }
        else
            return [{
                Header: '',
                headerClassName: 'table-header-poupanca width-70',
                headerStyle: {
                    textAlign: 'right'
                },
                className: 'header-with-margin width-70',
                Cell: ({ original }) => (<ProductDetails productID={id} subscriptionSerieID={original.subscriptionSerieID} subscriptionsSerie={original.subscriptionSerie} subscriptionType="serie" subscriptionState={original.subscriptionState} subscriptionNumber={original.subscriptionNumber} currentAccount={original.currentAccount} />)
            }, {
                    Header: <div className="header-with-margin">Montante Aplicado</div>,
                    headerClassName: 'table-header-poupanca width-80',
                headerStyle: {
                    textAlign: 'right'
                    },
                    className: 'header-with-margin width-80',
                Cell: ({ original }) => (
                    original.subscriptionState == "ATIVA" ? <div className="SavingsItems-value right-align" >{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(original.investedAmount)}</div > : <div className="SavingsItems-value right-align" style={{ "color": "#B2B2B2" }}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(original.investedAmount)}</div >

                )
            }, {
                    Header: <div className="header-with-margin">Rendimento Acumulado</div>,
                    headerClassName: 'table-header-poupanca width-80',
                headerStyle: {
                    textAlign: 'right'
                    },
                    className: 'header-with-margin width-80',
                Cell: ({ original }) => (
                    original.subscriptionState == "ATIVA" ? <div className="SavingsItems-value right-align">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(original.receivedAmount)}</div> : <div className="SavingsItems-value right-align" style={{ "color": "#B2B2B2" }}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(original.receivedAmount)}</div >

                )
            }, {
                    Header: <div className="header-with-margin">Data Subscrição</div>,
                    headerClassName: 'table-header-poupanca width-60',
                headerStyle: {
                    textAlign: 'right'
                    },
                    className: 'header-with-margin width-60',
                Cell: ({ original }) => (
                    original.subscriptionState == "ATIVA" ? <div className={"SavingsItems-date right-align"}>{new Intl.DateTimeFormat('pt-PT').format(new Date(original.subscriptionDate))}</div> : <div className={"SavingsItems-date right-align"} style={{ "color": "#B2B2B2" }}>{new Intl.DateTimeFormat('pt-PT').format(new Date(original.subscriptionDate))}</div>
                )
            }, {
                    Header: <div className="header-with-margin ">Data Vencimento</div>,
                    headerClassName: 'table-header-poupanca width-60',
                headerStyle: {
                    textAlign: 'right'
                    },
                    className: 'header-with-margin width-60',
                Cell: ({ original }) => (
                    original.subscriptionState == "ATIVA" ? <div className={"SavingsItems-date right-align"}>{new Intl.DateTimeFormat('pt-PT').format(new Date(original.subscriptionEndDate))}</div> : <div className={"SavingsItems-date right-align"} style={{ "color": "#B2B2B2" }}>{new Intl.DateTimeFormat('pt-PT').format(new Date(original.subscriptionEndDate))}</div>
                )
            }, {
                    Header: '',
                    className: 'width-105',
                    Cell: ({ original }) => (original.canReapply ?
                        <div className="reaplicar-reforcar-buttons" style={{ "display": "inline-flex" }}> <img className="reaplicar-button" src={require('../../images/reaplicar.png')} style={{ "cursor": "pointer", "margin-right": "5px"  }} width="98px" height="30px" onClick={this.createSubscriptionRow.bind(this, original)} ></img><CurrentAccountPopUp CurrentAccountList={original.currentAccount}> </CurrentAccountPopUp></div>
                        : original.canReinforce ?
                            <div className="reaplicar-reforcar-buttons" style={{ "display": "inline-flex" }}> < img className="reforcar-button" src={require('../../images/reinforce.png')} style={{ "cursor": "pointer", "margin-right": "5px"  }} width="98px" height="30px" onClick={this.createSubscriptionRow.bind(this, original)} ></img> <CurrentAccountPopUp CurrentAccountList={original.currentAccount}> </CurrentAccountPopUp></div>
                            : <CurrentAccountPopUp CurrentAccountList={original.currentAccount}> </CurrentAccountPopUp>)
                    
                }];
    }

    toggleCollapsible(index) {
        //Altera a variavel de estado
        let temp = this.state.isOpened;
        temp[index] = !temp[index];
        this.setState({
            isOpened: temp
        })         
        //faz triger do click para abrir/fechar a modalidade
        this.arrowIcon[index].click();
    }

    render() {
        
        
    return (
            <React.Fragment>
                {
                    this.props.savingsList.filter(item => item.subscriptionsNumber > 0).map((item, index) =>
                        
                        <div 
                            key={index} 
                            className="modalidades"
                        >
                            <table className="tableCapitalCerto">
                                <tbody>
                                    <tr>
                                        <th className="tableCapitalCerto-orangeback" style={{ "cursor": "auto" }}>
                                            <div className="tableCapitalCerto-title">{item.productName}</div>
                                            <div className="tableCapitalCerto-titlesubtitle">{this.transformSubscribe(item.subscriptionsNumber)}</div>
                                        </th>
                                        <th style={{ "width": "155px", "paddingLeft": "20px" }}><div className="tableCapitalCerto-givenvalue">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item.totalAmounts.totalInvestedAmount)}</div>
                                            <div className="tableCapitalCerto-subtitle">Montante entregue</div>
                                        </th>
                                        <th style={{ "width": "205px", "paddingLeft": "17px" }}><div className="tableCapitalCerto-boldvalue-active">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item.totalAmounts.totalReceivedAmount)}</div>
                                            <div className="tableCapitalCerto-subtitle">Rendimento acumulado Bruto</div>
                                        </th>
                                        <th style={{ "paddingBottom": "0px" }}>
                                            <div className={this.state.isOpened[index] ? "tableCapitalCerto-img opened" : "tableCapitalCerto-img closed"} ><img  onClick={this.toggleCollapsible.bind(this, index)} src={require('../../images/expand.png')} width="14px" height="14px"></img></div>
                                        </th>
                                    </tr>
                                </tbody>
                            </table>
                            <Collapsible 
                                trigger={<div ref={(input) => { this.arrowIcon[index] = input }} style={{ 'display': 'none' }} ></div>}>
                                <div className="table">
                                    <ReactTable
                                        columns={this.dynamicColumns(item.productID)}
                                        data={item.subscriptions}
                                        showPagination={false}
                                        resizable={true}
                                        minRows={1}
                                        sortable={true}
                                    >
                                    </ReactTable>
                                    <div className={this.changeSubscribeText(item.productID) == "série" &&
                                        this.state.flagAVOA == true ? "new-series-bw" : "new-series"}>
                                        <div style={{ "width": "50%", "paddingTop": "2%" }}><div className={this.changeSubscribeText(item.productID) == "série" &&
                                            this.state.flagAVOA == true ? "new-series-text-bw" : "new-series-text"}>Subscrever nova {this.changeSubscribeText(item.productID)}</div></div>
                                        <div style={{ "width": "50%" }} ><img src={require('../../images/newseries.png')} onClick={this.createSubscription.bind(this, item)} className="new-series-img" width="50px" height="50px"></img></div>
                                    </div>
                                </div>
                            </Collapsible>
                        </div>
                    )

                }
            </React.Fragment >

        );
    }
    
    /**async GetBlackWeekDates() {
    
          const atoken = authentication.getAccessToken();
    
          const headers = { Authorization: `Bearer ${atoken}` };
    
            const response = await fetch("/Elections/GetBlackWeekDates", {
    
            headers,
    
          });
    
          if (response.status !== 200) {
    
          } else {
    
            const data = await response.json();
    
            
            this.setState({
                actualDate: data.actualDate,
                startDateBFTeaser: data.startDateBFTeaser,
                endDateBFTeaser: data.endDateBFTeaser,
                startDateBF: data.startDateBF,
                endDateBF: data.endDateBF,
              });
    
          }
    
      }**/

    async getAVOApopup() {

        const atoken = authentication.getAccessToken();
        const headers = { Authorization: `Bearer ${atoken}` };
        const response = await fetch("/Elections/GetAVOADates", {
            headers,

        });

        if (response.status !== 200) {

        } else {

            const data = await response.json();


            this.setState({
                actualDateAVOA: data.actualDateAVOA,
                startDateAVOA: data.startDateAVOA,
                endDateAVOA: data.endDateAVOA,
                flagAVOA: data.flagAVOA
            });

        }

    }

}

SavingsItem.contextType = ContextConstructor;

export default withRouter(SavingsItem);