import React, { Component } from 'react';

class AssociadoTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: this.props.profile
        };
    }

    getNameCapitalize = (name) => {
        if (name != []) {
            return name?.toLowerCase()
                .split(' ')
                .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                .join(' ');
        }
        return name;
    };

    render() {
        return (
            <div className="User-Fields">
                        <div className="User-Fields">
                            <form>
                                <label>Nome</label>
                                <input
                                    type="text"
                                    name="Name" readOnly="readOnly"
                                    placeholder={this.getNameCapitalize(this.state.profile.name)}
                                />
                            </form>
                        </div>
                        <div className="Same-Line-Fields">
                            <form>
                                <label>NIF</label>
                                <input
                                    type="text"
                                    name="Nif"
                                    readOnly="readOnly"
                                    placeholder={this.state.profile.nif}
                                />
                            </form>
                            <form className="right-form">
                                <label>ID de Associado</label>
                                <input
                                    type="text"
                                    name="Nif" readOnly="readOnly"
                                    placeholder={this.state.profile.associateID}
                                />
                            </form>
                        </div>
                        <div className="User-Fields">
                            <form>
                                <label>IBAN</label>
                                <input
                                    type="text"
                                    name="Nif"
                                    readOnly="readOnly"
                                    placeholder={this.state.profile.iban}
                                />
                            </form>
                            <form>
                                <label>Morada Fiscal</label>
                                <input
                                    type="text"
                                    name="Nif" readOnly="readOnly"
                                    placeholder={this.state.profile.address}
                                />
                            </form>
                        </div>
                        <div className="Same-Line-Fields">
                            <form>
                                <label>Localidade</label>
                                <input
                                    type="text"
                                    name="Nif"
                                    readOnly="readOnly"
                                    placeholder={this.state.profile.locality}
                                />
                            </form>
                            <form className="right-form">
                                <label>Código Postal</label>
                                <input
                                    type="text"
                                    name="Nif"
                                    readOnly="readOnly"
                                    placeholder={this.state.profile.postalCode}
                                />
                            </form>
                        </div>
                        {/*<div className="User-Fields">*/}
                        {/*    <form>*/}
                        {/*        <label>Morada de Correspondência</label>*/}
                        {/*        <input*/}
                        {/*            type="text"*/}
                        {/*            name="Nif"*/}
                        {/*            readOnly="readOnly"*/}
                        {/*            placeholder={this.state.profile.mymAddress}*/}
                        {/*        />*/}
                        {/*    </form>*/}
                        {/*</div>*/}
                        {/*<div className="Same-Line-Fields">*/}
                        {/*    <form>*/}
                        {/*        <label>Localidade</label>*/}
                        {/*        <input*/}
                        {/*            type="text"*/}
                        {/*            name="Nif"*/}
                        {/*            readOnly="readOnly"*/}
                        {/*            placeholder={this.state.profile.mymLocality}*/}
                        {/*        />*/}
                        {/*    </form>*/}
                        {/*    <form className="right-form">*/}
                        {/*        <label>Código Postal</label>*/}
                        {/*        <input*/}
                        {/*            type="text"*/}
                        {/*            name="Nif"*/}
                        {/*            readOnly="readOnly"*/}
                        {/*            placeholder={this.state.profile.mymPostalCode}*/}
                        {/*        />*/}
                        {/*    </form>*/}
                        {/*</div>*/}
                        <div className="User-Fields">
                            <form>
                                <label>Email</label>
                                <input
                                    type="text"
                                    name="Nif"
                                    readOnly="readOnly"
                                    placeholder={this.state.profile.mymEmail}
                                />
                            </form>
                            <form>
                                <label>Telemóvel</label>
                                <input
                                    type="text"
                                    name="Nif"
                                    readOnly="readOnly"
                                    placeholder={this.state.profile.mymTelephone}
                                />
                            </form>
                        </div>
                    </div >
        );
    }
};
export default AssociadoTab;