/* COMPONENT IMPORTS*/
import { ErrorPage } from "../../components/ErrorPage/ErrorPage";
import authentication from "../../msalb2creact";
import authenticationsaml from "../../msalb2creact";
import DetailsList from "../../components/Listing/DetailsList";
import DownloadList from "../../components/Listing/DownloadList";
import { Advantages } from "../../components/CartaoAssociado/Advantages";
import { CardSection } from "../../components/CartaoAssociado/CardSection";
import { Loading } from "../../components/Loading/Loading";
import NotificationBanner from "../../components/Notifications/NotificationBanner";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { NavLink } from "react-router-dom";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { GlossaryPopup } from "../../components/Glossary/GlossaryPopup";
import { Highlight } from "../../components/Highlights/Highlight";

/* MODULE IMPORTS */
import React, { Component } from "react";

/* SAME FOLDER IMPORTS*/
import ColunaDireita from "./ColunaDireita";
import ColunaEsquerda from "./ColunaEsquerda";

/* CSS IMPORT */
import "./TabelaCandidatos.css";
import getadb2cauth from "../../getadb2cauth";
var reactPlugin = new ReactPlugin();

export class TabelaCandidatos extends Component {
  static displayName = TabelaCandidatos.name;

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="tabelasCandidatos">
        <div className="row">
                <div className="col-md-6 tipoEleicao position-table" >
            <div className="tabelaCandidatosTitulo">
                        Assembleia de Representantes, Mesa da Assembleia Geral, Conselho de Administração e Conselho Fiscal
            </div>
            <div className="tabelaCandidatos">
              {this.props.listasAssembleiaRepresentantes.map(
                (content, index) => (
                  <div key={index} className="coluna row candidato">
                    <div className="esquerda col-4">
                      <ColunaEsquerda
                        description={content.description}
                        pdfUrl={content.pdfUrl}
                        name={content.name}
                      ></ColunaEsquerda>
                    </div>
                    <div className="direita col-8">
                      <ColunaDireita
                        description={content.description}
                        pdfUrl={content.pdfUrl}
                        name={content.name}
                      ></ColunaDireita>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withAITracking(reactPlugin, TabelaCandidatos);
