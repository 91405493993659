/* COMPONENT IMPORTS*/
import authentication from "../../msalb2creact";
import ContextConstructor from "../Context/Context";

/* MODULE IMPORTS */
import { NavLink } from "react-router-dom";
import React, { Component } from "react";

/* CSS IMPORT */
import "./Sidebar.css";

class DesktopSidebar extends Component {
  static displayName = DesktopSidebar.name;

  constructor(props) {
    super(props);
    this.state = {
      //manager: [],
      //hasManager: false,
      //loading: true,
      //loadingManager: true,
      actualDate: null,
      endDate: null,
    };
  }

  componentDidMount() {
    //this.GetVoteAuthorization();
    //this.populateManagerData();
    //console.log(this.state.manager);
  }

  addDefaultSrc(ev) {
    ev.target.src = "../../images/Avatar_default.png";
  }

  static renderManagerCard(manager, sideBar) {
    return (
      <div className="manager-box">
        <div className="avatar">
          <img
            src={require("../../images/Avatar_default.png")}
            alt=""
            width="70"
            height="70"
          ></img>
        </div>
        <center
          className="manager-name-occupation"
          style={{ paddingLeft: "0px" }}
        >
          <div className="manager-name">{manager.name}</div>
          <div className="manager-occupation">Gestor Mutualista</div>
        </center>
        <button
          onClick={sideBar.openPopupGestor.bind(sideBar)}
          className="contact-popup-button"
        >
          Contacto
        </button>
      </div>
    );
  }

  static renderNoManagerCard(sideBar) {
    return (
      <div className="manager-box">
        <div className="avatar">
          <img
            src={require("../../images/Avatar_example.png")}
            alt=""
            width="70"
            height="70"
          ></img>
        </div>
        <center
          className="manager-name-occupation"
          style={{ paddingLeft: "0px" }}
        >
          <div className="manager-name" style={{ marginBottom: "15px" }}>
            Tem dúvidas?
          </div>
        </center>
        <button
          onClick={sideBar.openPopupGestor.bind(sideBar)}
          className="contact-popup-button"
        >
          Contacto
        </button>
      </div>
    );
  }

  openPopupGestor() {
    //faz triger do click para abrir o popup pretendido
    document.getElementById("popupGestorTrigger").click();
  }

    render() {

        //console.log("DesktopSideBar Context ", this.context)

    let loading = this.props.hasManager === undefined;
    let hasManager = this.props.hasManager;
    let manager = this.props.manager;

    let contents = hasManager ? DesktopSidebar.renderManagerCard(manager, this) : DesktopSidebar.renderNoManagerCard(this);
    //let contents = loading ? (
    //  <div className="main-page-loading-sidebar">
    //    <div className="loading">
    //      <img
    //        src={require("../../images/loading.png")}
    //        alt=""
    //        height="60px"
    //        width="60px"
    //      ></img>
    //    </div>
    //  </div>
    //) : hasManager ? (
    //  DesktopSidebar.renderManagerCard(manager, this)
    //) : (
    //  DesktopSidebar.renderNoManagerCard(this)
    //);

        let gestorContactTablet = (
            <div
                onClick={this.openPopupGestor.bind(this)}
                className="link link-gestor"
                href="#"
                aria-current="page"
            >
                <img
                    src={require("../../images/bullet_gestor.png")}
                    alt=""
                    height="26px"
                    width="26px"
                ></img>
                <br></br>
                <span>Gestor</span>
            </div>
        );

    //    let gestorContactTablet = loading ? (
    //  <div className="loading">
    //    <img
    //      src={require("../../images/loading.png")}
    //      alt=""
    //      height="60px"
    //      width="60px"
    //    ></img>
    //  </div>
    //) : (
    //  <div
    //    onClick={this.openPopupGestor.bind(this)}
    //    className="link link-gestor"
    //    href="#"
    //    aria-current="page"
    //  >
    //    <img
    //      src={require("../../images/bullet_gestor.png")}
    //      alt=""
    //      height="26px"
    //      width="26px"
    //    ></img>
    //    <br></br>
    //    <span>Gestor</span>
    //  </div>
    //);

    var isElectionsTime = false;

    if (this.state.actualDate && this.state.endDate) {
      var dateCheck = new Date(this.state.actualDate);
      var endDate = new Date(this.state.endDate);

        isElectionsTime = dateCheck < endDate && this.context.selectedUser.userState === "ATIVO";
    }

        /*this.context.selectedUser.userState == "CONDICIONADO" , this.context.selectedUser.hasQuotas === true*/
    return (
      <nav className="sidebar">
        <div
          className={
                    /*this.context.selectedUser.userState == "CONDICIONADO"*/
                    /*this.context.selectedUser.userState == "CONDICIONADO" || this.context.selectedUser.userState == "NATIVO"*/
                    this.context.selectedUser.associateState == "CONDICIONADO" /*|| this.context.selectedUser.associateState == "NATIVO" || this.context.selectedUser == 0*/
                    /*this.context.selectedUser != 0*/
              ? "sidebar-list disabled-link"
              : "sidebar-list"
          }
            >
          <NavLink to="/" style={{ textDecoration: "none" }}>
            <img
              src={require("../../images/Logo.png")}
              alt=""
              width="137px"
              height="45px"
              className="sidebar-logo"
            ></img>
            <img
              src={require("../../images/logoMobile.png")}
              alt=""
              height="45px"
              className="sidebar-logo-mobile"
            ></img>
          </NavLink>
          <ul className="sidebar-links" onClick={this.props.closeMenuFunction}>
            <li>
              <NavLink exact activeClassName="active" className="link" to="/">
                <img
                  src={require("../../images/bullet_home.png")}
                  alt=""
                  height="26px"
                  width="26px"
                ></img>
                <span style={{ marginLeft: "10px" }}> Início</span>
              </NavLink>
            </li>
            {isElectionsTime && (
              <li>
                <NavLink
                  exact
                  activeClassName="active"
                  className="link"
                  to="/eleicoes"
                >
                  <img
                    src={require("../../images/eleicoes.png")}
                    alt=""
                    height="26px"
                    width="26px"
                  ></img>
                  <span style={{ marginLeft: "10px" }}>
                    Eleição dos Órgãos <br></br> Associativos
                  </span>
                </NavLink>
              </li>
            )}
            <li>
              <NavLink
                exact
                activeClassName="active"
                className="link"
                to="/poupanca"
              >
                <img
                  src={require("../../images/bullet_poupanca.png")}
                  alt=""
                  height="26px"
                  width="26px"
                ></img>
                <span style={{ marginLeft: "10px" }}>Poupança</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                activeClassName="active"
                className="link"
                to="/protecao"
              >
                <img
                  src={require("../../images/bullet_protecao.png")}
                  alt=""
                  height="26px"
                  width="26px"
                ></img>
                <span style={{ marginLeft: "10px" }}>Proteção</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                activeClassName="active"
                className="link"
                to="/beneficios"
              >
                <img
                  src={require("../../images/bullet_beneficios.png")}
                  alt=""
                  height="26px"
                  width="26px"
                ></img>
                <span style={{ marginLeft: "10px" }}>Programa Benefícios</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                activeClassName="active"
                className="link"
                to="/cartaoassociado"
              >
                <img
                  src={require("../../images/bullet_cartao.png")}
                  alt=""
                  height="26px"
                  width="26px"
                ></img>
                <span style={{ marginLeft: "10px" }}>Cartão Associado</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                activeClassName="active"
                className="link"
                to="/correio"
              >
                <img
                  src={require("../../images/bullet_gestao_documental.png")}
                  alt=""
                  height="26px"
                  width="26px"
                ></img>
                <span style={{ marginLeft: "10px" }}>Correio Digital</span>
              </NavLink>
            </li>

            <li className="list-element-gestor">{gestorContactTablet}</li>
          </ul>
        </div>
        {contents}
        <div className="poweredbydiv">
          <img src={require("../../images/AM_Logo.png")} alt=""></img>
        </div>
      </nav>
    );
  }
  async populateManagerData() {
      setTimeout(async () => {
          this.setState({
          hasManager: this.props.hasManager,
          manager: this.props.manager,
          loadingManager: this.props.hasManager ? false : true,
          loading: this.props.hasManager ? false : true,
        });
      //const atoken = authentication.getAccessToken();
      //const headers = { Authorization: `Bearer ${atoken}` };
      //const response = await fetch("/Profile/GetManager", { headers });
      //if (response.status !== 200) {
      //  this.setState({
      //    hasManager: false,
      //    loadingManager: false,
      //    loading: false,
      //  });
      //} else {
      //  const data = await response.json();
      //  this.setState({
      //    hasManager: true,
      //    manager: data,
      //    loadingManager: false,
      //    loading: false,
      //  });
      //}
    }, 0);
  }
  //async GetVoteAuthorization() {
  //  setTimeout(async () => {
  //    const atoken = authentication.getAccessToken();
  //    const headers = { Authorization: `Bearer ${atoken}` };
  //    const response = await fetch("/Elections/GetVoteAuthorization", {
  //      headers,
  //    });
  //    if (response.status !== 200) {
  //    } else {
  //      const data = await response.json();
  //      this.setState({
  //        actualDate: data.actualDate,
  //        endDate: data.endDate,
  //      });
  //    }
  //  }, 0);
  //}
}

DesktopSidebar.contextType = ContextConstructor;

export default DesktopSidebar;
