/* MODULE IMPORTS */
import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class MAVOAPopup extends Component {
    static displayName = MAVOAPopup.name;

    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {
        return (
            <div className="popup-elections" style={{ height: "auto", margin: "auto" }}>
                <div>
                    <img
                        className="popup-primavera-img"
                        src={require("../../images/mavoa.png")}
                        alt="Popup_Image"
                    ></img>
                </div>
                <br />
                <div className="popup-elections-title ">
                    Quem vê poupanças, também vê corações
        </div>
                <div className="popup-elections-title-mobile d-md-none">
                    Quem vê poupanças, também vê corações
                </div>
                <br />
                <br />
                <div className="popup-elections-text">
                    <p>Às vezes o amor oferece bilhetes aos associados
                    <br />
                    do Montepio Associação Mutualista.</p>
                    {/*<p style={{ color: "#FF5800" }}>*/}
                    {/*    Vale Worten no valor de 8€ para compras na loja online.*/}
                    {/*    Exclusivo marcas Becken e Kunft.*/}
                    {/*    Válido até dezembro de 2023.*/}
                    {/*</p>*/}
                    
                    {/*<p style={{ color: "#FF5800" }}>*/}
                    {/*    Vale Zippy no valor de 10€ para compras na loja online de valor igual ou superior a 30€.*/}
                    {/*    Válido até 31 de outubro de 2022.*/}
                    {/*</p>*/}
                    <br />
                    <p>
                    Até 8 de fevereiro, subscreva uma modalidade de poupança e uma modalidade de proteção e receba um bilhete duplo para assistir a um espetáculo do Festival "Montepio às vezes o amor"
                    à sua escolha. Pode subscrever, desde já, a modalidade de poupança e contactar posteriormente o seu Gestor Mutualista para subscrever a modalidade de proteção. Apenas
                    usufruirá desta oferta mediante subscrição de ambas as modalidades.</p>
                    <br />
                    <br />
                    Consulte aqui os <a href="https://www.montepio.org/wp-content/uploads/2023/01/TC_MAVOA_2023_27jan-3.pdf" target="_blank" rel="noopener noreferrer">Termos e Condições </a> desta ação promocional.
                </div>
                <div className="popup-elections-buttons" style={{ padding: "1%", margin: "1%", marginBottom: "2%" }}>
                    <div
                        onClick={() => {
                            this.props.close();
                        }}
                        style={{ cursor: "pointer" }}
                        className="popup-elections-fechar"
                    >
                        FECHAR
          </div>
                    <NavLink
                        onClick={() => {
                            this.props.close();
                        }}
 
                        to="/poupanca"

                        style={{ color: "white" }}

                        className="popup-elections-votar">
                        QUERO
                    </NavLink>
                </div>
            </div>
        );
    
    } 
}
export default MAVOAPopup;
