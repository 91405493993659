/* MODULE IMPORTS */
import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class CampanhaMenores extends Component {
    static displayName = CampanhaMenores.name;

    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {
        return (
            <div className="popup-elections" style={{ height: "auto", margin: "auto", position: 'relative' }}>
                <div>
                    <img
                        className="popup-primavera-img"
                        src={require("../../images/campanha_menores_carolina_deslandes.png")}
                        alt="Popup_Imag"
                    ></img>
                </div>
                <div className="popup-elections-title" style={{ textAlign: "center" }}>
                    Faça como a Carolina.
                </div>

                <div className="popup-musicaolicos-title-mobile d-md-none" style={{ textAlign: "center" }}>
                  
                    <p>Faça como a Carolina.</p>
                </div>
                
                <br />
                <div className="popup-elections-text" style={{ textAlign: "center", fontWeight: 'normal' }}>
                    
                    Porque ser pai e mãe também é dar futuro, torne os seus filhos
                    associados e beneficie de vantagens hoje com mais segurança
                    amanhã. Subscreva uma modalidade de Poupança ou Proteção,<br />
                    a partir de 100€.
                    <br /><br />

                    Até 31 de dezembro, faça a admissão (Joia de Admissão de 9€)
                    em montepio.org ou num balcão do Banco Montepio e aproveite
                    a <span style={{ color: '#ff5800' }}><b>oferta da quota associativa</b></span>, no valor de 2€, pelo prazo de 24
                    meses ou até o jovem atingir os 18 anos de idade = 48€ de
                    poupança.
                    

                </div>  
                <div className="popup-elections-buttons" style={{ padding: "1%", margin: "1%", marginBottom: "2%" }}>
                    <div
                        onClick={() => {
                            this.props.close();
                        }}
                        style={{ cursor: "pointer" }}
                        className="popup-elections-fechar-center"
                    >
                        FECHAR
                    </div>
                    
                    
                </div>
            </div>
        );

    }
}
export default CampanhaMenores;
