import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";

/* MODULE IMPORTS */
import React, { Component } from "react";

var reactPlugin = new ReactPlugin();

export class ColunaEsquerda extends Component {
  static displayName = ColunaEsquerda.name;

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <div>{this.props.name}</div>
        <div style={{ display: "flex", marginTop: "15px" }}>
          <img
            style={{ marginRight: "10px" }}
            src={require("../../images/pdfIcon.png")}
            alt=""
            height="26px"
            width="26px"
          />
          <div>
                    <div className="glossary-title">Candidatos e Programa</div>
            <a
              className="glossary-download-button"
              href={this.props.pdfUrl}
              download={this.props.name + ".pdf"}
              target="_blank" rel="noopener noreferrer"
            >
              <div>Clique aqui</div>
              <img
                src={require("../../images/download.png")}
                alt="Botão de Download"
                height="7.5px"
                width="7.2px"
              />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default withAITracking(reactPlugin, ColunaEsquerda);
