/* COMPONENT IMPORTS*/
import { ErrorPage } from "../../components/ErrorPage/ErrorPage";
import authentication from "../../msalb2creact";
import authenticationsaml from "../../msalb2creact";
import DetailsList from "../../components/Listing/DetailsList";
import DownloadList from "../../components/Listing/DownloadList";
import { Advantages } from "../../components/CartaoAssociado/Advantages";
import { CardSection } from "../../components/CartaoAssociado/CardSection";
import { Loading } from "../../components/Loading/Loading";
import NotificationBanner from "../../components/Notifications/NotificationBanner";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { NavLink } from "react-router-dom";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { GlossaryPopup } from "../../components/Glossary/GlossaryPopup";
import { Highlight } from "../../components/Highlights/Highlight";

/* MODULE IMPORTS */
import React, { Component } from "react";

/* SAME FOLDER IMPORTS*/
import SavingsNewItem from "./../Poupanca/SavingNewItems";
import SavingNewItemsMobile from "./../Poupanca/SavingNewItemsMobile";
import SavingsItem from "./../Poupanca/SavingsItem";
import SavingsItemMobile from "./../Poupanca/SavingsItemMobile";
import TabelaCandidatos from "./TabelaCandidatos";

/* CSS IMPORT */
import "./Eleicoes.css";
import getadb2cauth from "../../getadb2cauth";

const browserHistory = createBrowserHistory({ basename: "Eleicoes Page" });
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: "a20b3d0d-87b9-41e6-bcde-892a425215fe",
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();

export class Eleicoes extends Component {
  static displayName = Eleicoes.name;

  constructor(props) {
    super(props);
    this.state = {
      error: false,
      loading: true,
      actualDate: null,
      startDate: null,
      endDate: null,
      votePortalUrl: null,
      listasAssembleiaRepresentantes: null,
      listasAssembleiaGeral: null,
      showVotarMobile: true,
      canVote: null,
    };
  }

  componentDidMount() {
    //this.GetVoteAuthorization();
    this.GetCandidatesList();
  }

  errorLoading() {
    return (
      <div>
        <ErrorPage />
      </div>
    );
  }

  renderEleicoesPage(state) {
    //Variavel de controlo para mostrar as listas existentes
    var isSegundaFase = true;
    var isSemanaVotacao = false;

    if (this.state.actualDate && this.state.endDate && this.state.startDate) {
      var dateCheck = new Date(this.state.actualDate);
      var startSemanaVotacao = new Date(this.state.startDate);
      var endSemanaVotacao = new Date(this.state.endDate);

      isSemanaVotacao =
        dateCheck > startSemanaVotacao && dateCheck < endSemanaVotacao;
    }

    return (
      <main className="main">
        <NotificationBanner />
        <div className="title-bar">
          <div className="title eleicoes-title">
            Eleição dos Órgãos Associativos, 2022-2025
          </div>
        </div>
        {isSemanaVotacao && (
          <div className="convocatoria-bar voto-bar">
            <div className="card-new">
              <table className="card-new-table">
                <tbody>
                  <tr>
                    <th className="card-new-title">
                      Eleições <br></br>2022-2025
                    </th>
                    <th style={{ paddingLeft: "20px" }}>
                      <div
                        className={
                          this.state.canVote === true
                            ? "card-disclaimer"
                            : "card-disclaimer full-width"
                        }
                      >
                        {this.state.canVote === true && (
                          <div>
                            Depois de clicar em "Votar" receberá um código de
                            segurança no seu telemóvel, que terá de introduzir
                            para aceder à plataforma de voto.
                          </div>
                        )}

                        {this.state.canVote === false && (
                          <div>
                            A sua condição de Associado não permite exercer
                            direito de voto (Associado há menos de dois anos ou
                            quotas em atraso).<br></br>
                            <br></br>Para esclarecimentos adicionais, contacte o
                            seu Gestor Mutualista ou a Linha de Apoio ao
                            Associado – Tel. 213 248 112 (dias úteis, das 9h às
                            0h).
                          </div>
                        )}
                      </div>
                    </th>
                    {this.state.canVote === true && (
                      <th>
                        <div className="button-vote">
                          <img
                            src={require("../../images/votarBtn.png")}
                            style={{ cursor: "pointer", width: "122px" }}
                            onClick={() =>
                              window.open(this.state.votePortalUrl, "_blank")
                            }
                          ></img>
                        </div>
                      </th>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
        {isSemanaVotacao && this.state.canVote === true && (
          <div
            id="votar-mobile"
            className={
              this.state.showVotarMobile
                ? "footer-votar d-md-none hide"
                : "remove footer-votar d-md-none hide"
            }
          >
            <div>
              <img
                className="imagem-eleicoes"
                src={require("../../images/EleicoesWhite.png")}
              ></img>
            </div>
            <div className="eleicoes-mobile-title">
              Eleições <br></br> 2022-2025
            </div>
            <div>
              <div
                className="botao-votar-mobile"
                onClick={() => window.open(this.state.votePortalUrl, "_blank")}
              >
                Votar
                <img
                  width="15px"
                  src={require("../../images/ArrowVotar.png")}
                ></img>
              </div>
            </div>
            <div className="votar-mobile-close">
              <img
                width="15px"
                style={{ cursor: "pointer" }}
                src={require("../../images/ArrowCloseWhite.png")}
                onClick={() =>
                  this.setState({
                    showVotarMobile: false,
                  })
                }
              ></img>
            </div>
          </div>
            )}
            {isSemanaVotacao &&(
        <div className="convocatoria-bar">
          <div className="card-new card-new-mobile card-convocatoria-mobile card-votar-mobile">
            <div className="card-new-table">
              <div className="card-new-title">
                <div className="tableCapitalCerto-title-inactive">
                  Eleições 2022-2025
                </div>
              </div>
              {isSemanaVotacao && this.state.canVote === true && (
                <div className="tableCapitalCerto-amount">
                  Depois de clicar em "Votar" receberá um código de segurança no seu
                  telemóvel, que terá de introduzir para aceder à plataforma de
                  voto.
                </div>
              )}
              {isSemanaVotacao && this.state.canVote === false && (
                <div className="tableCapitalCerto-amount">
                  A sua condição de Associado não permite exercer direito de
                  voto (Associado há menos de dois anos ou quotas em atraso).
                  <br></br>
                  <br></br>Para esclarecimentos adicionais, contacte o seu
                  Gestor Mutualista ou a Linha de Apoio ao Associado – Tel. 213
                  248 112 (dias úteis, das 9h às 0h).
                </div>
              )}
              {isSemanaVotacao && this.state.canVote === true && (
                <div className="button-vote">
                  <img
                    src={require("../../images/votarBtn.png")}
                    onClick={() =>
                      window.open(this.state.votePortalUrl, "_blank")
                    }
                  ></img>
                </div>
              )}
            </div>
          </div>
        </div>)}
        <div className="sub-title-bar">
          <div className="sub-title eleicoes-sub-title black">
            Vote por si e pelo futuro de todos.
          </div>
        </div>
        {isSegundaFase &&
          this.state.listasAssembleiaGeral &&
          this.state.listasAssembleiaRepresentantes && (
            <div>
              <div className="sub-title-bar">
                <div className="sub-title eleicoes-sub-title">
                  Conheça a composição e o programa das listas candidatas aos
                  Órgãos Associativos:
                </div>
              </div>
              <TabelaCandidatos
                listasAssembleiaGeral={this.state.listasAssembleiaGeral}
                listasAssembleiaRepresentantes={
                  this.state.listasAssembleiaRepresentantes
                }
              ></TabelaCandidatos>
            </div>
          )}

        <div className="convocatoria-bar">
          <div className="card-new">
            <table className="card-new-table">
              <tbody>
                <tr>
                  <th className="card-new-title">
                    Eleições <br></br>2022-2025
                  </th>
                  <th style={{ paddingLeft: "20px" }}>
                    <div className="card-disclaimer">
                      Conheça a Convocatória, Regulamento e todas as informações
                      referentes ao processo eleitoral.
                    </div>
                  </th>
                  <th>
                    <div className="">
                      <img
                        src={require("../../images/saberMaisBtn.png")}
                        style={{ cursor: "pointer", width: "160px" }}
                        onClick={() =>
                          window.open(
                            "https://www.montepio.org/eleicao-dos-orgaos-associativos-2022-2025/",
                            "_blank"
                          )
                        }
                      ></img>
                    </div>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="convocatoria-bar">
          <div className="card-new card-new-mobile card-convocatoria-mobile">
            <div className="card-new-table">
              <div className="card-new-title">
                <div className="tableCapitalCerto-title-inactive">
                  Eleições 2022-2025
                </div>
              </div>
              <div className="tableCapitalCerto-amount">
                Conheça a Convocatória, Regulamento e todas as informações
                referentes ao processo eleitoral.
              </div>
              <div className="tableCapitalCerto-amount"></div>
              <div className="tableSaberMais">
                <img
                  src={require("../../images/saberMaisBtn.png")}
                  style={{ cursor: "pointer", width: "160px" }}
                  onClick={() =>
                    window.open(
                      "https://www.montepio.org/eleicao-dos-orgaos-associativos-2022-2025/",
                      "_blank"
                    )
                  }
                ></img>
              </div>
            </div>
          </div>
        </div>
        <div className="convocatoria-bar duvidas-bar">
          <div className="card-new card-duvidas">
            <table className="card-new-table">
              <tbody>
                <tr>
                  <th className="no-padding">
                    <img
                      style={{ width: "206px" }}
                      src={require("../../images/duvidasEleicoes.png")}
                    ></img>
                  </th>
                  <th
                    className="duvidas-column no-padding"
                    style={{ paddingLeft: "20px" }}
                  >
                    <div className="duvidas">Tem dúvidas?</div>
                    <div className="contacto">
                      Linha de Apoio ao Associado - Tel. 213 248 112
                    </div>
                    <div className="horario">
                      {isSemanaVotacao
                        ? "Dias úteis das 9h às 0h"
                        : "Dias úteis das 9h às 21h"}
                    </div>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="modalidades modalidades-mobile duvidas-bar">
          <div className="">
            <div className="">
              <img
                style={{ width: "320px" }}
                src={require("../../images/duvidasEleicoesMobile.png")}
              ></img>
            </div>

            <div className="duvidas-column duvidas-column-mobile ">
              <div className="duvidas">Tem dúvidas?</div>
              <div className="contacto">
                Linha de Apoio ao Associado <br></br>- Tel. 213 248 112
              </div>
              <div className="horario">
                {isSemanaVotacao
                  ? "Dias úteis das 9h às 0h"
                  : "Dias úteis das 9h às 21h"}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }

  render() {
    //Funcao responsavel por mostrar e esconder o botao de votar fixo no rodapé dos smartphones
    var myScrollFunc = function () {
      var y = window.scrollY;
      if (document.getElementById("votar-mobile")) {
        if (y >= 415) {
          document.getElementById("votar-mobile").classList.add("show");
          document.getElementById("votar-mobile").classList.remove("hide");
        } else {
          document.getElementById("votar-mobile").classList.add("hide");
          document.getElementById("votar-mobile").classList.remove("show");
        }
      }
    };

    window.addEventListener("scroll", myScrollFunc);

    var isEpocaEleicoes = false;

    //Caso a data já tenha sido ultrapassada, vai mostrar a página de erro ao aceder diretamente ao url /eleicoes
    if (this.state.actualDate && this.state.endDate) {
      var dateCheck = new Date(this.state.actualDate);
      var endIsEpocaEleicoes = new Date(this.state.endDate);

      isEpocaEleicoes = dateCheck < endIsEpocaEleicoes;
    }

    let contents = this.state.loading ? (
      <div className="main-page-loading">
        <div className="loading">
          <img src={require("../../images/loading.svg")}></img>
        </div>
      </div>
    ) : this.state.error || !isEpocaEleicoes ? (
      this.errorLoading()
    ) : (
      this.renderEleicoesPage(this.state)
    );
    return <div>{contents}</div>;
  }

  //async GetVoteAuthorization() {
  //  setTimeout(async () => {
  //    const atoken = authentication.getAccessToken();
  //    const headers = { Authorization: `Bearer ${atoken}` };
  //    const response = await fetch("/Elections/GetVoteAuthorization", {
  //      headers,
  //    });
  //    if (response.status !== 200) {
  //    } else {
  //      const data = await response.json();

  //      console.log("Server date: ");
  //      console.log(data.actualDate);

  //      this.setState({
  //        actualDate: data.actualDate,
  //        startDate: data.startDate,
  //        endDate: data.endDate,
  //        votePortalUrl: data.votePortalUrl,
  //        loading: false,
  //        canVote: data.canVote,
  //      });
  //    }
  //  }, 0);
  //}

  async GetCandidatesList() {
    setTimeout(async () => {
      const atoken = authentication.getAccessToken();
      const headers = { Authorization: `Bearer ${atoken}` };
      const response = await fetch("/Elections/GetCandidatesList", {
        headers,
      });
      if (response.status !== 200) {
      } else {
        const data = await response.json();

        this.setState({
          listasAssembleiaGeral: data.generalMeetingList,
          listasAssembleiaRepresentantes: data.meetingRepresentativesList,
        });
      }
    }, 0);
  }
}

export default withAITracking(reactPlugin, Eleicoes);
