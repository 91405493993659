
/* MODULE IMPORTS */
import React, { Component } from 'react';

/* CSS IMPORT */
import './Sidebar.css';


class MobileSidebar extends Component {
    static displayName = MobileSidebar.name;

    constructor(props) {
        super(props);
        this.state = { 
            manager: [], 
            loading: true 
        };
    }

    componentDidMount() {
        this.populateManagerData();
    }

    render() {
        return (
            <div className="minimized-sidebar"></div>
        );
    }
    async populateManagerData() {
        const response = await fetch('/sidebar/get');
        const data = await response.json();
        this.setState({ manager: data, loading: false });
    }
}
export default MobileSidebar;