/* COMPONENT IMPORTS*/
import authentication from "../../msalb2creact";
import getadb2cauth from "../../getadb2cauth";

/* MODULE IMPORTS */
import React, { Component } from "react";

export class Logout extends Component {
  logout = () => {
    //Muda a flag que está guardada no session Storage que é responsável por mostrar o popup de eleições só 1 vez depois do login
    window.sessionStorage.setItem("showedElectionPopup", "false");
    

    getadb2cauth().then((res) => {

        let trustedUrls = 'https://www.montepio.org';

        res.postLogoutRedirectUri = trustedUrls;
        
        authentication.signOut(res);
       
    });
  };

  render() {
    return (
      <div className="header-bar" onClick={this.logout}>
        <div className="logout-name">Sair</div>
        <img
          src={require("../../images/logout.svg")}
          alt="Logout da conta"
          width="18.4px"
          height="24px"
        ></img>
      </div>
    );
  }
}
