/* MODULE IMPORTS */
import getWordPressJS from './GetWpJS';
import getWordPressCSS from './GetWpCSS';
import getWordPressJquery from './GetWPJquery';


const LoadFormsScripts = async () => {
    const responseJS = await getWordPressJS();
    //validate existing JS
    if (document.querySelector('script[src="' + responseJS + '"]'))
        return;

    const responseCSS = await getWordPressCSS();
    const responseJquery = await getWordPressJquery();

    const rescss = document.createElement("link");
    rescss.setAttribute("rel", "stylesheet");
    rescss.setAttribute("type", "text/css");
    rescss.setAttribute("href", responseCSS);

    const scriptJquery = document.createElement("script");
    scriptJquery.src = responseJquery;
    scriptJquery.async = false;

    const scriptJs = document.createElement("script");
    scriptJs.src = responseJS;
    scriptJs.async = false;

    document.getElementsByTagName("head")[0].appendChild(rescss);
    document.getElementsByTagName("head")[0].appendChild(scriptJquery);
    document.getElementsByTagName("head")[0].appendChild(scriptJs);
    //document.getElementsByTagName("head")[0].appendChild(scriptJquery);
}

export default LoadFormsScripts;

