
/* COMPONENT IMPORTS*/
import DetailsList from '../Listing/DetailsList';
import DownloadList from '../Listing/DownloadList';
import authentication from '../../msalb2creact';
import ContextConstructor from "../Context/Context";

/* MODULE IMPORTS */
import { withRouter } from 'react-router';
import Popup from "reactjs-popup";
import React, { Component } from 'react';

/* SAME FOLDER IMPORTS*/

/* CSS IMPORT */
import './ProductDetails.css'
import "../../pages/Poupanca/tablestyling.css";


class ProductDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            elementID: this.props.elementID,
            productName: this.props.productName,
            productID: this.props.productID,
            subscriptionsNumber: this.props.subscriptionsNumber,
            subscriptionSerie: this.props.subscriptionsSerie,
            subscriptionSerieID: this.props.subscriptionSerieID,
            subscriptionNumber: this.props.subscriptionNumber,
            subscriptionState: this.props.subscriptionState,
            active: this.props.subscriptionActive,
            data: [],
            type: this.props.subscriptionType,
            hasData: false,
            /**startDateBFTeaser: null,
            endDateBFTeaser: null,
            startDateBF: null,
            endDateBF: null,
            actualDate: null,**/
            //actualDateAVOA: null,
            //startDateAVOA: null,
            //endDateAVOA: null,
            //flagAVOA:false

        }
    }

    

    componentDidMount() {
        //this.GetBlackWeekDates();
        /*this.getAVOApopup();*/
        this.populateData(this.state.productID, this.state.subscriptionSerieID);
    }

    transformSubscribe = (a) => {
        if (a === 1)
            return a + ' subscrição';
        else
            return a + ' subscrições';
    }

    RenderTitle(state) {
        if (state.type == "serie" && state.subscriptionState == "ATIVA") {
            return (
                <div className="serie-name-wrapper" style={{ "position": "initial !important", "cursor": "pointer", "display": "inline-block" }}>
                    <div className="SavingsItems-value">{state.subscriptionSerie}</div>
                    <div className="SavingsItems-number">Nº Inscrição {state.subscriptionNumber}</div>
                </div>
            ) 
        }
        else if (state.type == "serie" && !state.subscriptionState == "ATIVA"){
            return (
                <div className="serie-name-wrapper" style={{ "position": "initial !important", "cursor": "pointer", "display": "inline-block" }}>
                    <div className="SavingsItems-value" style={{ "color": "#B2B2B2" }}>{state.subscriptionSerie}</div>
                    <div className="SavingsItems-number" style={{ "color": "#B2B2B2" }}>Nº Inscrição {state.subscriptionNumber}</div>
                </div>
            )
        }
        else if (!state.type == "serie" && state.active) {
             if (typeof state.subscriptionsNumber != "undefined" && state.productName == "PRAZO CERTO") {
                return (
                    <div className="serie-name-wrapper" style={{ "position": "initial !important" }}>
                        <div id={state.elementID}
                            className="tableCapitalCerto-title-inactive"
                            
                        >{state.productName}</div>
                    </div>
                );
             }
             else if (typeof state.subscriptionsNumber != "undefined") {
                return (
                    <span className="serie-name-wrapper" style={{ "position": "initial !important" }}>
                        <div className="tableCapitalCerto-title">{state.productName}</div>
                        <div className="tableCapitalCerto-titlesubtitle">{this.transformSubscribe(state.subscriptionsNumber)}</div>
                    </span>
                );
             }
        else {
                return (
                    <div className="serie-name-wrapper" style={{ "position": "initial !important" }}>
                        <div className="tableCapitalCerto-title">{state.productName}</div>
                    </div>
                );
            }
        }
        else {
            return (
                <div className="serie-name-wrapper" style={{ "position": "initial !important" }}>
                    <div id={state.elementID}
                        className="tableCapitalCerto-title-inactive"
                        
                    >{state.productName}</div>
                </div>
            );
        }
    }

    renderData(state) {
        let modalityType = this.state.type ? "série" : "modalidade";
        //if (this.state.type == "serie")
        //    modalityType = "série"
        //else
        //    modalityType = "modalidade"

        if (this.state.productName == "PRAZO CERTO") {
            return this.RenderTitle(state)
        }
        else if (!state.hasData) {
            return (
                <Popup
                    trigger={() => (
                        this.RenderTitle(state))
                    }
                    contentStyle={{ "background": "none", "border": "none", "box-shadow": "none" }}
                    position="top center"
                >
                    {close =>
                        <div>
                            <div className="warning-popup">
                                <div className="alert-icon-section">
                                    <img src={require('../../images/alerta.png')} alt="Alerta" />
                                </div>
                                <div className="content-section">
                                    Esta {modalityType} não tem mais informação disponível. Para saber mais contacte 213 248 112 (dias úteis, das 09h às 21h. Custo de chamada para a rede fixa nacional).
                                    </div>
                                <div className="close-icon-section">
                                    <img src={require('../../images/closeIcon.png')} alt="Fechar" onClick={close} style={{ "cursor": "pointer" }} />
                                </div>
                            </div>

                            <div className="warning-popup warning-popup-mobile">
                                <div className="popup-window-mobile-header">
                                    <a href="/">
                                        <img src={require('../../images/logoMobile.png')} alt="logo" height="45px" className=""></img>
                                    </a>
                                    <img className="popup-window-mobile-close" src={require('../../images/closeIcon.png')} alt="Fechar" onClick={close} style={{ "cursor": "pointer" }} />
                                </div>


                                <div className="main">
                                    <center>
                                        <img style={{ "margin-bottom": "30px" }} src={require('../../images/erro.png')} alt="" width="100px" height="100px"></img>
                                        <div className="error-title">Esta {modalityType} não tem mais informação disponível.</div>
                                        <div className="error-description">Para saber mais contacte 213 248 112 (dias úteis, das 09h às 21h. Custo de chamada para a rede fixa nacional).</div>
                                    </center>
                                </div>

                            </div>
                        </div>
                    }
                </Popup>
            );
        }
        else {
            return (
                <Popup
                    trigger={
                        this.RenderTitle(state)
                    }
                    contentStyle={{ "background": "none", "border": "none" }} modal closeOnDocumentClick>
                    {close =>
                        <div className="popup-window">
                            <div className="popup-window-mobile-header">
                                <a href="/">
                                    <img src={require('../../images/logoMobile.png')} alt="logo" height="45px" className=""></img>
                                </a>
                                <img className="popup-window-mobile-close" src={require('../../images/closeIcon.png')} alt="Fechar" onClick={close} style={{ "cursor": "pointer" }} />
                            </div>
                            <div className="popup-window-close">
                                <img src={require('../../images/close.png')} alt="Fechar" onClick={close} style={{ "cursor": "pointer" }} />
                            </div>
                            <div className="title-productdetails">
                                {state.data.head.title}
                            </div>
                            <div className="subtitle">
                                {state.data.head.subtitle}
                            </div>
                            <div className="central-container row">
                                <div className="first-div image-container" style={{ "backgroundImage": "url(" + state.data.banner.image + ")" }}></div>
                                <div className="second-div">
                                    <div className="title-productdetails">
                                        {state.data.banner.headline}
                                    </div>
                                    <div className="description">
                                        {state.data.banner.subheadline}
                                    </div>
                                </div>
                                <div className="third-div">
                                    <div className="details">
                                        <div className="text1">
                                            {state.data.banner.duration}
                                        </div>
                                        <div className="text2">
                                            {state.data.banner.rate}
                                        </div>
                                        <div className="text3">
                                            {state.data.banner.extras}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <DetailsList list={state.data.content} />
                            <DownloadList files={state.data.documents} />
                        </div>
                    }
                </Popup>
            );
        }
    }


    render() {
        //let contents = this.state.loading ? (this.RenderTitle(this.state)) : (this.renderData(this.state));
        let contents = this.renderData(this.state);
        return (
            <main>
                {contents}
            </main>
        );
    }

    async populateData(productID, serieID) {
        let serie = (serieID) ? serieID : "0";
        let requestUrl = "/Portfolio/GetProductDetails?productID=" + productID + "&subID=" + serie;
        const headers = { Authorization: `Bearer ${await authentication.getAccessToken()}` };
        const response = await fetch(requestUrl, { headers })

        if (response.status !== 200) {
            this.setState({ hasData: false });

        }
        else {
            const reponseData = await response.json();
            //console.log("console data : ", reponseData, " productId : ", productID)
            this.setState({ data: reponseData, hasData: true });
        }
        /*console.log({ response });*/
    }

    /**async GetBlackWeekDates() {

        const atoken = authentication.getAccessToken();
        const headers = { Authorization: `Bearer ${atoken}` };
        const response = await fetch("/Elections/GetBlackWeekDates", {
            headers,

        });

        if (response.status !== 200) {

        } else {

            const data = await response.json();


            this.setState({
                actualDate: data.actualDate,
                startDateBFTeaser: data.startDateBFTeaser,
                endDateBFTeaser: data.endDateBFTeaser,
                startDateBF: data.startDateBF,
                endDateBF: data.endDateBF,
            });

        }

    }**/

    //async getAVOApopup() {

    //    const atoken = authentication.getAccessToken();
    //    const headers = { Authorization: `Bearer ${atoken}` };
    //    const response = await fetch("/Elections/GetAVOADates", {
    //        headers,

    //    });

    //    if (response.status !== 200) {

    //    } else {

    //        const data = await response.json();


    //        this.setState({
    //            actualDateAVOA: data.actualDateAVOA,
    //            startDateAVOA: data.startDateAVOA,
    //            endDateAVOA: data.endDateAVOA,
    //            flagAVOA: data.flagAVOA
    //        });

    //    }

    //}
}

ProductDetails.contextType = ContextConstructor;

export default withRouter(ProductDetails);