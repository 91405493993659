
/* COMPONENT IMPORTS*/
import ProductDetails from '../../components/ProductDetails/ProductDetails';
import ContextConstructor from "../../components/Context/Context";

/* MODULE IMPORTS */
import Collapsible from 'react-collapsible';
import React, { Component } from 'react';
import Popup from "reactjs-popup";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';

/* SAME FOLDER IMPORTS*/

/* CSS IMPORT */

class TableProtSavSubscriptions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpened: [],

        }
        this.arrowIcon = []
    }

    transformActives = (a) => {
        if (a == '1')
            return a + ' subscrição ativa';
        else
            return a + ' subscrições ativas';
    }

    changeValueColor(value) {
        if (value != "0.0") {
            return (
                <React.Fragment>
                    <div className="table-applied">{new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(value)}</div>
                </React.Fragment>
            )
        }
        else {
            return (
                <React.Fragment>
                    <div className="table-applied" style={{ "color": "#B2B2B2" }}>{new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(value)}</div>
                </React.Fragment>
            )
        }
    }

    toggleCollapsible(index) {
        //Altera a variavel de estado
        let temp = this.state.isOpened;
        temp[index] = !temp[index];
        this.setState({
            isOpened: temp
        })
        //faz triger do click para abrir/fechar a modalidade
        this.arrowIcon[index].click();
    }

    transformSubscribe(a) {
        if (a == '1')
            return a + ' subscrição';
        else
            return a + ' subscrições';
    }

    getPopupLinesStructure(subscriptionID, subscription) {
        let vencimentoRow = (((subscriptionID == "7254") || (subscriptionID == "7284")) ?
            <div className="subscricao-detalhe">
                <div className="label">Vencimentos</div>
                <div className="value">
                    {new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(subscription.totalPaidAmount)}
                </div>
            </div> :
            ""
        )

        if (subscriptionID == "7284" || subscriptionID == "7254" || subscriptionID == "72A4" || subscriptionID == "protecao5em5merged") /*PROTEÇÃO 5 EM 5 e DESPESAS PERIÓDICAS*/ {

            let recAmount;

            if (subscription.receivedAmounts == null) {

                recAmount = null
            }


            if (subscription.receivedAmounts != null) {

                recAmount = subscription.receivedAmounts.map((receivedAmount, index) =>
                    <div>
                        <div style={{ "margin-bottom": "0px" }} className="subscricao-detalhe">
                            <div className="label">Vencimento Ocorrido</div>
                            <div className="value">
                                {new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(receivedAmount)}
                            </div>
                        </div>

                        <div style={{ "margin-bottom": "15px" }} className="subscricao-detalhe">
                            <div className="label">{new Intl.DateTimeFormat('pt-PT').format(new Date(subscription.receivedAmountsDate[index]))}</div>
                        </div>
                    </div>

                )

            }

            return (
                <div className="serie-mobile-wrapper">
                    <div className="serie-mobile">
                        <div className="subscricao-detalhe total-entregue">
                            <div className="label">N.º Inscrição</div>
                            <div className="value">{subscription.subscriptionNumber}</div>
                        </div>
                        <div className="subscricao-detalhe total-entregue">
                            <div className="label">Prazo</div>
                            <div className="value">{subscription.deadline} {subscription.deadline == 1 ? " Ano" : " Anos"}</div>
                        </div>
                        <div className="subscricao-detalhe total-entregue">
                            <div className="label">Data de Início</div>
                            <div className="value">{new Intl.DateTimeFormat('pt-PT').format(new Date(subscription.subscriptionDate))}</div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Estado</div>
                            <div className="value">
                                {subscription.state}
                            </div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Capital Formado</div>
                            <div className="value">
                                {new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(subscription.currentAmount)}
                            </div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Melhorias acumuladas</div>
                            <div className="value">
                                {new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(subscription.accumulatedImprovments)}
                            </div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Taxa de Crescimento</div>
                            <div className="value">
                                {new Intl.NumberFormat('pt-PT', { style: 'percent', minimumFractionDigits: 1 }).format(subscription.growthrate * 0.01)}
                            </div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Plano de Crescimento</div>
                            <div className="value">
                                {subscription.plan}
                            </div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Entrega Periódica</div>
                            <div className="value">
                                {new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(subscription.paidAmount)}
                            </div>
                        </div>
                        {recAmount}
                        <div className="subscricao-detalhe">
                            <div className="label">Data do próximo vencimento</div>
                            <div className="value">
                                {new Intl.DateTimeFormat('pt-PT').format(new Date(subscription.nextAmount))}
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else if (subscriptionID == "7181" || subscriptionID == "71A1" || subscriptionID=="protecaovidamerged") /*Proteção vida*/ {
            return (
                <div className="serie-mobile-wrapper">
                    <div className="serie-mobile">
                        <div className="subscricao-detalhe total-entregue">
                            <div className="label">N.º Inscrição</div>
                            <div className="value">{subscription.subscriptionNumber}</div>
                        </div>
                        <div className="subscricao-detalhe total-entregue">
                            <div className="label">Data de Início</div>
                            <div className="value">{new Intl.DateTimeFormat('pt-PT').format(new Date(subscription.subscriptionDate))}</div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Estado</div>
                            <div className="value">
                                {subscription.state}
                            </div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Capital Subscrito</div>
                            <div className="value">
                                {new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(subscription.currentAmount)}
                            </div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Melhorias acumuladas</div>
                            <div className="value">
                                {new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(subscription.accumulatedImprovments)}
                            </div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Taxa de Crescimento</div>
                            <div className="value">
                                {new Intl.NumberFormat('pt-PT', { style: 'percent', minimumFractionDigits: 1 }).format(subscription.growthrate * 0.01)}
                            </div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Plano de Crescimento</div>
                            <div className="value">
                                {subscription.plan}
                            </div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Entrega Periódica</div>
                            <div className="value">
                                {new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(subscription.paidAmount)}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else if (subscriptionID == "7100") /*PENSÃO SOBREVIV. / DOTES */ {
            return (
                <div className="serie-mobile-wrapper">
                    <div className="serie-mobile">
                        <div className="subscricao-detalhe total-entregue">
                            <div className="label">N.º Inscrição</div>
                            <div className="value">{subscription.subscriptionNumber}</div>
                        </div>
                        <div className="subscricao-detalhe total-entregue">
                            <div className="label">Data de Início</div>
                            <div className="value">{new Intl.DateTimeFormat('pt-PT').format(new Date(subscription.subscriptionDate))}</div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Estado</div>
                            <div className="value">
                                {subscription.state}
                            </div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Capital Subscrito</div>
                            <div className="value">
                                {new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(subscription.currentAmount)}
                            </div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Melhorias acumuladas</div>
                            <div className="value">
                                {new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(subscription.accumulatedImprovments)}
                            </div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Taxa de Crescimento</div>
                            <div className="value">
                                {new Intl.NumberFormat('pt-PT', { style: 'percent', minimumFractionDigits: 1 }).format(subscription.growthrate * 0.01)}
                            </div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Plano de Crescimento</div>
                            <div className="value">
                                {subscription.plan}
                            </div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Entrega Periódica</div>
                            <div className="value">
                                {new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(subscription.paidAmount)}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        else if (subscriptionID == "7353" || subscriptionID == "7383" || subscriptionID == "7393" || subscriptionID == "73A3" || subscriptionID == "pensoesreformamerged") /*Pensões de Reforma*/ {

            let ContrSeg;

            if (subscription.contraSeguro == 0 || subscription.contraSeguro == "0" || subscription.contraSeguro == null || subscription.contraSeguro == "") {
                ContrSeg = null;
            }
            else {
                ContrSeg = <div className="subscricao-detalhe">
                    <div className="label">Entrega periódica Contra Seguro</div>
                    <div className="value">
                        {new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(subscription.contraSeguro)}
                    </div>
                </div>
            }

            return (
                <div className="serie-mobile-wrapper">
                    <div className="serie-mobile">
                        <div className="subscricao-detalhe total-entregue">
                            <div className="label">N.º Inscrição</div>
                            <div className="value">{subscription.subscriptionNumber}</div>
                        </div>
                        <div className="subscricao-detalhe total-entregue">
                            <div className="label">Prazo</div>
                            <div className="value">{subscription.deadline} {subscription.deadline == 1 ? " Ano" : " Anos"}</div>
                        </div>
                        <div className="subscricao-detalhe total-entregue">
                            <div className="label">Data de Início</div>
                            <div className="value">{new Intl.DateTimeFormat('pt-PT').format(new Date(subscription.subscriptionDate))}</div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Estado</div>
                            <div className="value">
                                {subscription.state}
                            </div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Capital Subscrito</div>
                            <div className="value">
                                {new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(subscription.currentAmount)}
                            </div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Melhorias acumuladas</div>
                            <div className="value">
                                {new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(subscription.accumulatedImprovments)}
                            </div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Taxa de Crescimento</div>
                            <div className="value">
                                {new Intl.NumberFormat('pt-PT', { style: 'percent', minimumFractionDigits: 1 }).format(subscription.growthrate * 0.01)}
                            </div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Plano de Crescimento</div>
                            <div className="value">
                                {subscription.plan}
                            </div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Entrega Periódica</div>
                            <div className="value">
                                {new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(subscription.paidAmount)}
                            </div>
                        </div>
                        {ContrSeg}
                        <div className="subscricao-detalhe">
                            <div className="label">Idade de Recebimento</div>
                            <div className="value">
                                {subscription.receivingAge}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        else if (subscriptionID == "7151" || subscriptionID == "7152" || subscriptionID == "7157" || subscriptionID == "7164") /*capitais de previdência, subsídio por morte, Pensões para deficientes, rendas sobrevivência */ {
            return (
                <div className="serie-mobile-wrapper">
                    <div className="serie-mobile">
                        <div className="subscricao-detalhe total-entregue">
                            <div className="label">N.º Inscrição</div>
                            <div className="value">{subscription.subscriptionNumber}</div>
                        </div>
                        <div className="subscricao-detalhe total-entregue">
                            <div className="label">Data de Início</div>
                            <div className="value">{new Intl.DateTimeFormat('pt-PT').format(new Date(subscription.subscriptionDate))}</div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Estado</div>
                            <div className="value">
                                {subscription.state}
                            </div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Capital Subscrito</div>
                            <div className="value">
                                {new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(subscription.currentAmount)}
                            </div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Melhorias acumuladas</div>
                            <div className="value">
                                {new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(subscription.accumulatedImprovments)}
                            </div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Taxa de Crescimento</div>
                            <div className="value">
                                {new Intl.NumberFormat('pt-PT', { style: 'percent', minimumFractionDigits: 1 }).format(subscription.growthrate * 0.01)}
                            </div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Plano de Crescimento</div>
                            <div className="value">
                                {subscription.plan}
                            </div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Entrega Periódica</div>
                            <div className="value">
                                {new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(subscription.paidAmount)}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="serie-mobile-wrapper">
                    <div className="serie-mobile">
                        <div className="subscricao-detalhe total-entregue">
                            <div className="label">N.º Inscrição</div>
                            <div className="value">{subscription.subscriptionNumber}</div>
                        </div>
                        <div className="subscricao-detalhe total-entregue">
                            <div className="label">Prazo</div>
                            <div className="value">{subscription.deadline} {subscription.deadline == 1 ? " Ano" : " Anos"}</div>
                        </div>
                        <div className="subscricao-detalhe total-entregue">
                            <div className="label">Data de Início</div>
                            <div className="value">{new Intl.DateTimeFormat('pt-PT').format(new Date(subscription.subscriptionDate))}</div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Estado</div>
                            <div className="value">
                                {subscription.state}
                            </div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Capital Subscrito</div>
                            <div className="value">
                                {new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(subscription.currentAmount)}
                            </div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Melhorias acumuladas</div>
                            <div className="value">
                                {new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(subscription.accumulatedImprovments)}
                            </div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Taxa de Crescimento</div>
                            <div className="value">
                                {new Intl.NumberFormat('pt-PT', { style: 'percent', minimumFractionDigits: 1 }).format(subscription.growthrate * 0.01)}
                            </div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Plano de Crescimento</div>
                            <div className="value">
                                {subscription.plan}
                            </div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Entrega Periódica</div>
                            <div className="value">
                                {new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(subscription.paidAmount)}
                            </div>
                        </div>
                        <div className="subscricao-detalhe">
                            <div className="label">Data fim</div>
                            <div className="value">
                                {new Intl.DateTimeFormat('pt-PT').format(new Date(subscription.subscriptionEndDate))}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderDetailsPopup(index, item) {
        //item.subscriptions.map((subscription, indexSubscription) =>
        //    console.log(subscription)
        //)

        return (
            <Popup
                trigger={<div style={{ 'display': 'none' }} id={'popupProtecaoPoupancaOpennerTrigger' + index}></div>}
                contentStyle={{ "background": "none", "border": "none" }} modal closeOnDocumentClick>
                {close =>
                    <div className="modalidades-mobile-detalhes">
                        <div className="title-section">
                            <div className="title-main">
                                {item.productName}
                                <img src={require('../../images/close.png')} alt="Fechar" onClick={close} style={{ "cursor": "pointer" }} />
                            </div>
                            <div className="title-subtitle">{this.transformSubscribe(item.subscriptionsNumber)}</div>
                        </div>
                        <div className="montantes-section">
                            <div className="tableCapitalCerto-amount">
                                <div className="tableCapitalCerto-givenvalue">{new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(item.totalAmounts.totalCurrentAmount)}</div>
                                <div className="tableCapitalCerto-subtitle">{(item.productID == "7284" || item.productID == "7254" ||
                                    item.productID == "72A4" || item.productID == "protecao5em5merged"/*PROTEÇÃO 5 EM 5*/) ? "Capital Formado" : "Capital Subscrito"}</div>
                            </div>
                            <div className="tableCapitalCerto-amount">
                                <div className="tableCapitalCerto-boldvalue-active">{new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(item.totalAmounts.totalPaidAmount)}</div>
                                <div className="tableCapitalCerto-subtitle">Entrega periódica</div>
                            </div>
                        </div>
                        {item.subscriptions.map((subscription, indexSubscription) =>
                            this.getPopupLinesStructure(item.productID, subscription)
                        )}
                        <div className="close-detalhes-protecao-wrapper">
                            <div className="close-detalhes-protecao">
                                <div onClick={close}>Fechar</div>
                            </div>
                        </div>
                    </div>
                }
            </Popup>
        );
    }

    openPopup(index) {
        //faz triger do click para abrir os detalhes especificos
        document.getElementById('popupProtecaoPoupancaOpennerTrigger' + index).click();
    }

    openDetalhesPopup(index) {
        //faz triger do click para abrir os detalhes especificos
        document.getElementById('popupDetalhesProtecaoMaisPoupancaTrigger' + index).click();
    }

    renderMobileProtectionList() {

        let itemsToRender = this.props.protectionsaveList.productTypes;

        let nItemsToRender = itemsToRender.length;

        let content = nItemsToRender == 0 ? "" : (
            <React.Fragment>
                {itemsToRender.map((item, index) =>
                    this.renderDetailsPopup(index, item)
                )}

                <div className="consulta-detalhes-clicaveis-poupanca">
                    {itemsToRender.map((item, index) =>
                        <ProductDetails elementID={'popupDetalhesProtecaoMaisPoupancaTrigger' + index} productID={item.productID} subscriptionType="product" productName={item.productName} subscriptionActive={false} />
                    )}
                </div>

                <div className="carousel-wrapper">
                    <CarouselProvider
                        naturalSlideWidth={400}
                        naturalSlideHeight={500}
                        totalSlides={nItemsToRender}
                        visibleSlides={1}
                    >
                        <Slider>
                            {itemsToRender.map((item, index) =>
                                <Slide index={index}>
                                    <div className="modalidades modalidades-mobile">
                                        <div className="tableCapitalCerto">
                                            <div style={{ "cursor": "pointer" }} onClick={this.openDetalhesPopup.bind(this, index)} className="tableCapitalCerto-orangeback">
                                                <div className="tableCapitalCerto-title">{item.productName}</div>
                                                <div className="tableCapitalCerto-titlesubtitle">{this.transformSubscribe(item.subscriptionsNumber)}</div>
                                            </div>
                                            <div className="tableCapitalCerto-amount">
                                                <div className="tableCapitalCerto-givenvalue">{new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(item.totalAmounts.totalCurrentAmount)}</div>
                                                <div className="tableCapitalCerto-subtitle">{(item.productID == "7284" || item.productID == "72A4" || item.productID == "protecao5em5merged"  /*PROTEÇÃO 5 EM 5*/) ? "Capital Formado" : "Capital Subscrito"}</div>
                                            </div>
                                            <div className="tableCapitalCerto-amount">
                                                <div className="tableCapitalCerto-boldvalue-active">{new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(item.totalAmounts.totalPaidAmount)}</div>
                                                <div className="tableCapitalCerto-subtitle">Entrega periódica</div>
                                            </div>
                                            <div onClick={this.openPopup.bind(this, index)} className="consultar-detalhes">Ver detalhe</div>
                                        </div>
                                    </div>
                                </Slide>
                            )}
                        </Slider>
                        <DotGroup />
                    </CarouselProvider>
                </div>
            </React.Fragment >
        );

        return content;
    }

    render() {
        function tableContent(subscriptionID, currentAmount, paidAmount, totalPaidAmount, subscriptionNumber, subscriptionDate, deadline, initialAmount, growthrate, initialPaidAmount, receivingAge, subscriptionEndDate, receivedAmounts, receivedAmountsDate, nextAmount, contraSeguro, state, plan, accumulatedImprovments) {
            if (subscriptionID == "7284" || subscriptionID == "7254" || subscriptionID == "72A4" || subscriptionID == "protecao5em5merged") /*PROTEÇÃO 5 EM 5 e DESPESAS PERIÓDICAS*/ {

                let recAmount;

                if (receivedAmounts == null) {
                    recAmount = null
                }

                if (receivedAmounts != null) {
                    recAmount = receivedAmounts.map((receivedAmount, index) =>
                        <tr>
                            <td><div className="detalhe-outros-label">Vencimento Ocorrido</div></td>
                            <td><div className="detalhe-outros-label">{new Intl.DateTimeFormat('pt-PT').format(new Date(receivedAmountsDate[index]))}</div></td>
                            <td><div className="detalhe-outros-valor">{new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(receivedAmount)}</div></td>
                        </tr>
                    )
                }

                return (
                    <div className="table">
                        <table className="detalhe-totalentregue-table">
                            <tbody>
                                <tr className="detalhe-totalentregue-tr">
                                    <td><div className="detalhe-totalentregue-label">N.º Inscrição: {subscriptionNumber}</div></td>
                                    <td><div className="detalhe-totalentregue-prazo">Prazo: {deadline} {deadline == 1 ? " Ano" : " Anos"}</div></td>
                                    <td style={{ "width": "33%" }}><div className="detalhe-totalentregue-valor">Data de Início: {new Intl.DateTimeFormat('pt-PT').format(new Date(subscriptionDate))}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Estado</div></td>
                                    <td colSpan="2"><div className="detalhe-outros-valor">{state}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Capital Formado</div></td>
                                    <td><div className="detalhe-outros-label"></div></td>
                                    <td><div className="detalhe-outros-valor">{new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(currentAmount)}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Melhorias acumuladas</div></td>
                                    <td><div className="detalhe-outros-label"></div></td>
                                    <td><div className="detalhe-outros-valor">{new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(accumulatedImprovments)}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Taxa de Crescimento</div></td>
                                    <td><div className="detalhe-outros-label">{plan}</div></td>
                                    <td><div className="detalhe-outros-valor">{new Intl.NumberFormat('pt-PT', { style: 'percent', minimumFractionDigits: 1 }).format(growthrate * 0.01)}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Entrega Periódica</div></td>
                                    <td><div className="detalhe-outros-label"></div></td>
                                    <td><div className="detalhe-outros-valor">{new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(paidAmount)}</div></td>
                                </tr>
                                {recAmount}
                                {<tr>
                                    <td colSpan="2"><div className="detalhe-outros-label">Data do próximo vencimento</div></td>
                                    <td><div className="detalhe-outros-valor">{new Intl.DateTimeFormat('pt-PT').format(new Date(nextAmount))}</div></td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>
                )
            }

            else if (subscriptionID == "7181" || subscriptionID == "71A1" || subscriptionID == "protecaovidamerged") /*Proteção vida*/ {
                return (
                    <div className="table">
                        <table className="detalhe-totalentregue-table">
                            <tbody>
                                <tr className="detalhe-totalentregue-tr">
                                    <td><div className="detalhe-totalentregue-label">N.º Inscrição: {subscriptionNumber}</div></td>
                                    <td><div className="detalhe-totalentregue-prazo"></div></td>
                                    <td style={{ "width": "33%" }}><div className="detalhe-totalentregue-valor">Data de Início: {new Intl.DateTimeFormat('pt-PT').format(new Date(subscriptionDate))}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Estado</div></td>
                                    <td colSpan="2"><div className="detalhe-outros-valor">{state}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Capital Subscrito</div></td>
                                    <td><div className="detalhe-outros-label"></div></td>
                                    <td><div className="detalhe-outros-valor">{new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(currentAmount)}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Melhorias acumuladas</div></td>
                                    <td><div className="detalhe-outros-label"></div></td>
                                    <td><div className="detalhe-outros-valor">{new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(accumulatedImprovments)}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Taxa de Crescimento</div></td>
                                    <td><div className="detalhe-outros-label">{plan}</div></td>
                                    <td><div className="detalhe-outros-valor">{new Intl.NumberFormat('pt-PT', { style: 'percent', minimumFractionDigits: 1 }).format(growthrate * 0.01)}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Entrega Periódica</div></td>
                                    <td><div className="detalhe-outros-label"></div></td>
                                    <td><div className="detalhe-outros-valor">{new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(paidAmount)}</div></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )
            }
            else if (subscriptionID == "7100") /*PENSÃO SOBREVIV. / DOTES*/ {
                return (
                    <div className="table">
                        <table className="detalhe-totalentregue-table">
                            <tbody>
                                <tr className="detalhe-totalentregue-tr">
                                    <td><div className="detalhe-totalentregue-label">N.º Inscrição: {subscriptionNumber}</div></td>
                                    <td><div className="detalhe-totalentregue-prazo"></div></td>
                                    <td style={{ "width": "33%" }}><div className="detalhe-totalentregue-valor">Data de Início: {new Intl.DateTimeFormat('pt-PT').format(new Date(subscriptionDate))}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Estado</div></td>
                                    <td colSpan="2"><div className="detalhe-outros-valor">{state}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Capital Subscrito</div></td>
                                    <td><div className="detalhe-outros-label"></div></td>
                                    <td><div className="detalhe-outros-valor">{new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(currentAmount)}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Melhorias acumuladas</div></td>
                                    <td><div className="detalhe-outros-label"></div></td>
                                    <td><div className="detalhe-outros-valor">{new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(accumulatedImprovments)}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Taxa de Crescimento</div></td>
                                    <td><div className="detalhe-outros-label">{plan}</div></td>
                                    <td><div className="detalhe-outros-valor">{new Intl.NumberFormat('pt-PT', { style: 'percent', minimumFractionDigits: 1 }).format(growthrate * 0.01)}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Entrega Periódica</div></td>
                                    <td><div className="detalhe-outros-label"></div></td>
                                    <td><div className="detalhe-outros-valor">{new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(paidAmount)}</div></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )
            }

            else if (subscriptionID == "7353" || subscriptionID == "7383" || subscriptionID == "7393" || subscriptionID == "73A3" || subscriptionID == "pensoesreformamerged") /*Pensões de Reforma*/ {

                let ContrSeg;

                if (contraSeguro == 0 || contraSeguro == "0" || contraSeguro == null || contraSeguro == "") {
                    ContrSeg = null;
                }
                else {
                    ContrSeg = <tr>
                        <td colSpan="2"><div className="detalhe-outros-label">Entrega periódica Contra Seguro</div></td>
                        <td><div className="detalhe-outros-valor">{new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(contraSeguro)}</div></td>
                    </tr>
                }

                return (
                    <div className="table">
                        <table className="detalhe-totalentregue-table">
                            <tbody>
                                <tr className="detalhe-totalentregue-tr">
                                    <td><div className="detalhe-totalentregue-label">N.º Inscrição: {subscriptionNumber}</div></td>
                                    <td><div className="detalhe-totalentregue-prazo">Prazo: {deadline} {deadline == 1 ? " Ano" : " Anos"}</div></td>
                                    <td style={{ "width": "33%" }}><div className="detalhe-totalentregue-valor">Data de Início: {new Intl.DateTimeFormat('pt-PT').format(new Date(subscriptionDate))}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Estado</div></td>
                                    <td colSpan="2"><div className="detalhe-outros-valor">{state}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Capital Subscrito</div></td>
                                    <td><div className="detalhe-outros-label"></div></td>
                                    <td><div className="detalhe-outros-valor">{new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(currentAmount)}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Melhorias acumuladas</div></td>
                                    <td><div className="detalhe-outros-label"></div></td>
                                    <td><div className="detalhe-outros-valor">{new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(accumulatedImprovments)}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Taxa de Crescimento</div></td>
                                    <td><div className="detalhe-outros-label">{plan}</div></td>
                                    <td><div className="detalhe-outros-valor">{new Intl.NumberFormat('pt-PT', { style: 'percent', minimumFractionDigits: 1 }).format(growthrate * 0.01)}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Entrega Periódica</div></td>
                                    <td><div className="detalhe-outros-label"></div></td>
                                    <td><div className="detalhe-outros-valor">{new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(paidAmount)}</div></td>
                                </tr>
                                {ContrSeg}
                                <tr>
                                    <td colSpan="2"><div className="detalhe-outros-label">Idade de recebimento</div></td>
                                    <td><div className="detalhe-outros-valor">{receivingAge} anos</div></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )
            }

            else if (subscriptionID == "7151" || subscriptionID == "7152" || subscriptionID == "7157" || subscriptionID == "7164") /*capitais de previdência, subsídio por morte, Pensões para deficientes, rendas sobrevivência */ {
                return (
                    <div className="table">
                        <table className="detalhe-totalentregue-table">
                            <tbody>
                                <tr className="detalhe-totalentregue-tr">
                                    <td><div className="detalhe-totalentregue-label">N.º Inscrição: {subscriptionNumber}</div></td>
                                    <td><div className="detalhe-totalentregue-prazo"></div></td>
                                    <td style={{ "width": "33%" }}><div className="detalhe-totalentregue-valor">Data de Início: {new Intl.DateTimeFormat('pt-PT').format(new Date(subscriptionDate))}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Estado</div></td>
                                    <td colSpan="2"><div className="detalhe-outros-valor">{state}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Capital Subscrito</div></td>
                                    <td><div className="detalhe-outros-label"></div></td>
                                    <td><div className="detalhe-outros-valor">{new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(currentAmount)}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Melhorias acumuladas</div></td>
                                    <td><div className="detalhe-outros-label"></div></td>
                                    <td><div className="detalhe-outros-valor">{new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(accumulatedImprovments)}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Taxa de Crescimento</div></td>
                                    <td><div className="detalhe-outros-label">{plan}</div></td>
                                    <td><div className="detalhe-outros-valor">{new Intl.NumberFormat('pt-PT', { style: 'percent', minimumFractionDigits: 1 }).format(growthrate * 0.01)}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Entrega Periódica</div></td>
                                    <td><div className="detalhe-outros-label"></div></td>
                                    <td><div className="detalhe-outros-valor">{new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(paidAmount)}</div></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )
            }

            else {
                return (
                    <div className="table">
                        <table className="detalhe-totalentregue-table">
                            <tbody>
                                <tr className="detalhe-totalentregue-tr">
                                    <td><div className="detalhe-totalentregue-label">N.º Inscrição: {subscriptionNumber}</div></td>
                                    <td><div className="detalhe-totalentregue-prazo">Prazo: {deadline} {deadline == 1 ? " Ano" : " Anos"}</div></td>
                                    <td style={{ "width": "33%" }}><div className="detalhe-totalentregue-valor">Data de Início: {new Intl.DateTimeFormat('pt-PT').format(new Date(subscriptionDate))}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Estado</div></td>
                                    <td colSpan="2"><div className="detalhe-outros-valor">{state}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Capital Subscrito</div></td>
                                    <td><div className="detalhe-outros-label"></div></td>
                                    <td><div className="detalhe-outros-valor">{new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(currentAmount)}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Melhorias acumuladas</div></td>
                                    <td><div className="detalhe-outros-label"></div></td>
                                    <td><div className="detalhe-outros-valor">{new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(accumulatedImprovments)}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Taxa de Crescimento</div></td>
                                    <td><div className="detalhe-outros-label">{plan}</div></td>
                                    <td><div className="detalhe-outros-valor">{new Intl.NumberFormat('pt-PT', { style: 'percent', minimumFractionDigits: 1 }).format(growthrate * 0.01)}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Entrega Periódica</div></td>
                                    <td><div className="detalhe-outros-label"></div></td>
                                    <td><div className="detalhe-outros-valor">{new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(paidAmount)}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="detalhe-outros-label">Data Fim</div></td>
                                    <td><div className="detalhe-outros-label"></div></td>
                                    <td><div className="detalhe-outros-valor">{new Intl.DateTimeFormat('pt-DE').format(new Date(subscriptionEndDate))}</div></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )
            }
        }
        return (
            <React.Fragment>
                <div className="modalidades-bar modalidades-bar-protecao ">
                    <div className="resumo-poupanca">
                        <div className="first-div">
                            <div className="table-modalidades">Proteção+Poupança</div>
                            <div className="table-subscricoes">{this.transformActives(this.props.protectionsaveList.subscriptionsNumber)}</div>
                        </div>
                        <div className="second-div">
                            <div style={{ "paddingRight": "10px", "paddingBottom": "5px" }}><img src={require('../../images/protecaopoupanca.png')} height="48px" width="48px"></img></div>
                            <div>
                                <div className="table-appliedtitle">Capital Subscrito</div>
                                <div>{this.changeValueColor(this.props.protectionsaveList.totalAmounts.totalCurrentAmount)}</div>
                            </div>
                        </div>
                        <div className="third-div">
                            <div className="table-modalidades">Proteção+Poupança</div>
                            <div className="table-subscricoes">{this.transformActives(this.props.protectionsaveList.subscriptionsNumber)}</div>
                        </div>
                    </div>
                </div>
                <div className="card-box">
                    {this.props.protectionsaveList.productTypes.map((item, headerkey) =>
                        <div className="protecao-card">
                            <table className="tg">
                                <tbody>
                                    <tr key={headerkey}>
                                        <td className="tableProtection-orangeback">
                                            <div className="table-value-col1"><ProductDetails productID={item.productID} productName={item.productName} subscriptionsNumber={item.subscriptionsNumber} subscriptionActive={true} /></div>
                                        </td>
                                        <td className="capital-subscrito-valor">
                                            <div className="table-value-col2">{new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(item.totalAmounts.totalCurrentAmount)}</div>
                                            <div className="table-value2-col2">{item.productID == "7284" || item.productID == "7254" ||
                                                item.productID == "72A4" || item.productID == "protecao5em5merged"? "Capital Formado" : "Capital Subscrito"}</div>
                                        </td>
                                        <td className="entrega-periodica-valor">
                                            <div className="table-value-col3">{new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(item.totalAmounts.totalPaidAmount)}</div>
                                            <div className="table-value2-col2">Entrega periódica</div>
                                        </td>
                                        <td style={{ "paddingBottom": "0px" }}>
                                            <div className={this.state.isOpened[headerkey] ? "tableCapitalCerto-img opened" : "tableCapitalCerto-img closed"} ><img onClick={this.toggleCollapsible.bind(this, headerkey)} src={require('../../images/expand.png')} width="14px" height="14px"></img></div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <Collapsible trigger={<div ref={(input) => { this.arrowIcon[headerkey] = input }} style={{ 'display': 'none' }} ></div>}>
                                {item.subscriptions.map((subscription, subscriptionkey) =>
                                    <div key={subscriptionkey}>
                                        {tableContent(item.productID, subscription.currentAmount, subscription.paidAmount, subscription.totalPaidAmount, subscription.subscriptionNumber, subscription.subscriptionDate, subscription.deadline, subscription.initialAmount, subscription.growthrate, subscription.initialPaidAmount, subscription.receivingAge, subscription.subscriptionEndDate, subscription.receivedAmounts, subscription.receivedAmountsDate, subscription.nextAmount, subscription.contraSeguro, subscription.state, subscription.plan, subscription.accumulatedImprovments)} 
                                    </div>
                                )}
                            </Collapsible>
                        </div>
                    )}
                </div>
                {this.renderMobileProtectionList()}
            </React.Fragment>
        );
    }
}

TableProtSavSubscriptions.contextType = ContextConstructor;

export default TableProtSavSubscriptions;