
/* COMPONENT IMPORTS*/
import { ErrorPage } from '../../components/ErrorPage/ErrorPage';
import authentication from '../../msalb2creact';
import DetailsList from '../../components/Listing/DetailsList';
import DownloadList from '../../components/Listing/DownloadList';
import { Advantages } from '../../components/CartaoAssociado/Advantages'
import { CardSection } from '../../components/CartaoAssociado/CardSection'
import { Loading } from '../../components/Loading/Loading';
import NotificationBanner from '../../components/Notifications/NotificationBanner'
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
import ContextConstructor from "../../components/Context/Context";

/* MODULE IMPORTS */
import React, { Component } from "react";

/* CSS IMPORT */
import "./CartaoAssociado.css";

const browserHistory = createBrowserHistory({ basename: 'Associate Card Page' });
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: 'a20b3d0d-87b9-41e6-bcde-892a425215fe',
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();

export class CartaoAssociado extends Component {
    static displayName = CartaoAssociado.name;

    constructor(props) {
        super(props);
        this.state = {
            savedValue: [],
            error: [],
            associateCard: [],
            loading: true,
        };
    }

    async componentDidMount() {
        //console.log("CA Context ", this.context);

        if (this.context.selectedUserID !== '' && this.context.selectedUserID !== undefined && this.context.selectedUserID !== null) {
            this.getData();
        }
        
    }

    renderCard(savedValue, associateCard) {
        return (
            <div className="main">
                <NotificationBanner/>
                {/*Title*/}
                <div className="title-bar">
                    <div className="title mobile-associate-title">Cartão de Associado</div>
                </div>
                {/*Main card*/}
                <div className="associate-div">
                    <CardSection benefitsValue={savedValue.benefitValue}/>
                    <Advantages data={associateCard}/>
                    <section className="information-section">
                        <DetailsList list={associateCard.benefits} />
                    </section>
                    {/*Downloads Section*/}
                    <section className="downloads-section">
                        <div className="row">
                            <div className="col-sm-12 download-title">
                                Downloads
                        </div>
                            <DownloadList files={associateCard.documents} />
                        </div>
                    </section>
                </div>
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <Loading/>
            : this.state.error 
            ? <ErrorPage />
            : this.renderCard(this.state.savedValue, this.state.associateCard);
        return (
            <React.Fragment>
                {contents}
            </React.Fragment>
        );
    }

    async getData() {
        var clientId = this.context.selectedUserID;
        //console.log("AssociateCard clientid ", clientId)

        const headers = {
            Authorization: `Bearer ${authentication.getAccessToken()}`,
            clientId: clientId,
            'Accept': 'application/json, text/plain',
            'Content-Type': 'application/json;charset=UTF-8'
        };

        const responsebenefits = await fetch("/benefits/getsavedvalue", {
            headers,
            method: 'GET',
        });

        const responseassociate = await fetch("/benefits/GetAssociateCard", {
            headers
        });

        if ((responsebenefits.status !== 200) || (responseassociate.status !== 200)) {

            this.setState({
                error: true,
                loading: false
            })

            if (responseassociate.status === 204)
                appInsights.trackException({
                    exception: "AssociateCard no content",
                    severityLevel: 3
                });
        }
        else {
            
            const benefits = await responsebenefits.json();
            //console.log("Benefits getvalue ", benefits);

            const associateData = await responseassociate.json();
            //console.log("associateData getvalue ", benefits);
            
            this.setState({
                savedValue: benefits,
                associateCard: associateData,
                error: false,
                loading: false
            });
        }

        ///*const responsebenefits = await fetch("/benefits/getsavedvalue", { headers });*/
    //    const responsebenefits = await fetch("/benefits/getsavedvalue", {
    //        headers,
    //        method: 'GET',
    //    });

    //    console.log("Benefits ", responsebenefits.json())

    //    const responseassociate = await fetch("/benefits/GetAssociateCard", { headers });

    //    if ((responsebenefits.status !== 200) || (responseassociate.status !== 200)) {
    //        this.setState({ error: true, loading: false })
    //        if(responseassociate.status === 204) appInsights.trackException({exception: "AssociateCard no content", severityLevel: 3});
    //    }
    //    else {
    //        const associateData = await responseassociate.json();
    //        const benefits = await responsebenefits.json();

    //        console.lo("Benefits getvalue ", benefits)

    //        this.setState({ savedValue: benefits, associateCard: associateData, error: false, loading: false });
    //    }
    }
}

CartaoAssociado.contextType = ContextConstructor;

export default withAITracking(reactPlugin, CartaoAssociado);