import React, { Component } from 'react';

export class AdicionaMenorFeedbackPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inValidNifs: '',
            message: '',
        };
    }

    handleMessage = (event) => {
        let inValidNifs = event.data;
        if (inValidNifs >=1) {
            this.setState({
                inValidNifs: 'Dados inválidos. Por favor contacte o seu Gestor Mutualista ou a Linha de Apoio através do Tel. 213 248 112 (atendimento personalizado, dias úteis, das 09h às 21h. Custo de chamada para a rede fixa nacional).' });
        } else {
            this.setState({
                inValidNifs: 'Registo efetuado com sucesso. Já pode consultar a posição das modalidades subscritas pela criança ou jovem.' });
        }
    }

    redirectPage() {
          //window.location.reload(false);
          window.location.href = "/";
      }

  render() {
    const { infoList, message } = this.props;

      //function refreshPage() {
      //    window.location.reload(false);
      //}

      return (          
      //<ul>
      //  {infoList.map(info => (
      //    <li key={info.id}>
      //      <span>{info.text}</span>
      //      <span>{info.number}</span>
      //      <img src={info.source} alt="" width="30px" height="30px"></img>
      //    </li>
      //  ))}
      //  </ul>
          //window.location.reload(),
          //{ this.handleMessage }

        <div className="main">
              <div className="Title">Adicionar Criança ou Jovem Associado</div>
            <div className="Associate-Fields">
                  <label>{message}</label>
              </div><br/>
              <ul style={{ listStyleType: "none", textAlign: "center" }}>
                {infoList.map(info => (
                    <li key={info.id}>
                        <span>{info.text}</span> &nbsp;
                        <span>{info.number}</span> &nbsp;
                        <img src={info.source} alt="" width="15px" height="15px"></img>
                    </li>
                ))}
              </ul>
              <div className="Associate-Fields">
                  <p>
                      <button type="button" onClick={this.redirectPage}>Continuar</button>
                  </p>
              </div>
        </div>
    );
  }
}

export default AdicionaMenorFeedbackPopup; 
