
/* COMPONENT IMPORTS*/
import authentication from '../../msalb2creact';
import LoadFormsScripts from '../../components/FormsDataLoader/FormsDataLoader';
import getWordPressURL from '../../components/FormsDataLoader/GetWpURL';
import { Loading } from '../../components/Loading/Loading';
import ProductDetailsCallback from '../../components/ProductDetails/ProductDetailsCallback';
import { ErrorPage } from '../../components/ErrorPage/ErrorPage';
import ContextConstructor from "../Context/Context";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";

/* MODULE IMPORTS */
import React, { Component } from "react";
import { renderToString } from 'react-dom/server';
import Popup from "reactjs-popup";

/* SAME FOLDER IMPORTS*/

/* CSS IMPORT */
import "./Subscricao.css";
import { Redirect } from 'react-router';
import Home from '../../pages/Home/Home';


const browserHistory = createBrowserHistory({ basename: 'Pagina Subscrição' });
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: 'a20b3d0d-87b9-41e6-bcde-892a425215fe',
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();



export class Subscricao extends Component {

    constructor(props) {
        super(props);
        this.state = {
            profile: [],
            loading: true,
            manager: [],
            popupData: [],
            popupHasData: false,
            loadingPopup: false
        };
    }

    async errorLoadPage () {
        return (
            <div>
                <ErrorPage />
            </div>
            );
    }

    componentDidMount() {
        //console.log("subscrição Context ", this.context, " profile state ", this.profile);

        if (this.context.selectedUserID && this.context.selectedUserID !== '') {
            this.loadManager();
            LoadFormsScripts().then(res => {
                this.loadMyMontepioSubscriptionForms().then(res => {
                    setTimeout(function () { //Just to guarantee that the component had time to load
                        this.setState({ loading: false });
                    }.bind(this), 2000)
                })
                    .catch(error => {
                        this.setState({ loading: false });
                        this.errorLoadPage();
                    })
            }, 3000);
        }

       
        //if (this.context.selectedUserID && this.context.selectedUserID !== '')
        //{//we have context.selectedUserID
        //    this.loadManager();
        //    LoadFormsScripts().then(res => {
        //        this.loadMyMontepioSubscriptionForms().then(res => {
        //            setTimeout(function () { //Just to guarantee that the component had time to load
        //                this.setState({ loading: false });
        //            }.bind(this)) //, 2000
        //        })
        //            .catch(error => {
        //                this.setState({ loading: false });
        //                this.errorLoadPage();
        //            })
        //    }); //, 3000
        //}
       


        //if (this.context.selectedUserID !== '' && this.context.selectedUserID !== undefined && this.context.selectedUserID !== null) {
        //    //if (this.context.selectedUserID === this.context.tokenUser) {
        //    //    this.loadManager();
        //    //    LoadFormsScripts().then(res => {
        //    //        this.loadMyMontepioSubscriptionForms().then(res => {
        //    //            setTimeout(function () { //Just to guarantee that the component had time to load
        //    //                this.setState({ loading: false });
        //    //            }.bind(this), 2000)
        //    //        })
        //    //            .catch(error => {
        //    //                this.setState({ loading: false });
        //    //                this.errorLoadPage();
        //    //            })
        //    //    }, 3000);
        //    //}
        //    //else {
        //    //    LoadFormsScripts().then(res => {
        //    //        this.loadMyMontepioSubscriptionForms().then(res => {
        //    //            setTimeout(function () { //Just to guarantee that the component had time to load
        //    //                this.setState({ loading: false });
        //    //            }.bind(this), 2000)
        //    //        })
        //    //            .catch(error => {
        //    //                this.setState({ loading: false });
        //    //                this.errorLoadPage();
        //    //            })
        //    //    }, 3000);
        //    //}

        //    //    this.loadManager();
        //        LoadFormsScripts().then(res => {
        //            this.loadMyMontepioSubscriptionForms().then(res => {
        //                setTimeout(function () { //Just to guarantee that the component had time to load
        //                    this.setState({ loading: false });
        //                }.bind(this), 2000)
        //            })
        //                .catch(error => {
        //                    this.setState({ loading: false });
        //                    this.errorLoadPage();
        //                })
        //        }, 3000);
            
        //}
        
    }

    render() {
        let loading = this.state.loading ? <Loading /> : (<div />);

        let popup = <ProductDetailsCallback data={this.state.popupData} hasData={this.state.popupHasData} />;

        let content = (
            <div className="main">
                <div id="form-root" className="mymontepio-forms form-create-saving"></div>
            </div>
        );

        return <main>{content}{popup}{loading}</main>;
    }



    async loadManager() {
        setTimeout(async () => {
            const atoken = authentication.getAccessToken();

            var clientId = this.context.selectedUserID;
            console.log("Subs Manager CLID: ", clientId)

            const headers = {
                Authorization: `Bearer ${atoken}`,
                clientId: clientId,
            };

            const response = await fetch("/Profile/GetManager?ClientId=" + clientId, { headers });
            const data = await response.json();
            //console.log(data);
            this.setState({ manager: data });
        }, 0);
        
    }

    async loadMyMontepioSubscriptionForms() {
        //Prepare the data
        const headers = { Authorization: `Bearer ${await authentication.getAccessToken()}` };
        const response = await fetch("/Profile/GetProfile", { headers });
        const data = await response.json();

        //console.log("Subscrição dataProfile", data )
        this.setState({
            profile: this.context.selectedUser
        });

        //Subcrition are made by the selectedUser(From the context)
        //console.log("subscrição selectedUser ", this.context.selectedUser, " profile antes ", this.state.profile)
        
        //this.setState({
        //    profile: this.context.selectedUser
        //});

        //console.log("subscricao profile depois ", this.state.profile)

        if (this.props.location.state === null)
            return;

        const employeeemail = this.state.manager.email;
        const employeenumber = this.state.manager.employeeNumber;
        var params = this.props.location.state.params;

        //console.log("Params subs ", this.props.location.state.params, " Props ", this.props);

        var modalidade = this.props.location.state.modalidade;
        if (params.subscriptionSerieID === null || params.subscriptionSerieID === '')
            params.subscriptionSerieID = "0";

        var subscription_ID = params.subscriptionID;

        var productID = `${modalidade.productID}${params.subscriptionSerieID}`;

        var subscriptionsHistory = [];
        if (modalidade.subscriptions !== null && modalidade.subscriptions.length > 0) {
            modalidade.subscriptions.forEach(function (subscription) {
                var subs = {
                    text: 'Subscrição ' + subscription.subscriptionNumber,
                    amount: subscription.investedAmount,
                    subscription_ID: subscription.subscriptionID,
                }
                subscriptionsHistory.push(subs);
            });
        }

        var flow = 'subscricao';
        if (params.canReapply) {
            flow = 'reaplicacao';
        }
        if (params.canReinforce) {
            flow = 'reforco';
        }

        var clientId = this.context.selectedUserID;

        //Obtain intention ID
        var intentionID = 0;
        try {
            var headersI = {
                Authorization: `Bearer ${await authentication.getAccessToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            };
            const responseI = await fetch("/Intentions/CreateIntention", {
                headers: headersI,
                method: "POST",
                body: JSON.stringify({
                    IntentionType: flow,
                    ProductCode: productID,
                    ClientID: clientId
                })
            });
            intentionID = await responseI.json();
        } catch (err) {
            console.error(err);
        }

        //var profileId = this.context.mymUsers.filter((user) => user.clientId === this.context.tokenUser).map().id;

        /*console.log("profileId ", profileId);*/

        //Expression for verify if the Associate_Card_Number come as a GUID
        const GuidExpression = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
        //GuidExpression.test();
        
        var associateNumber = GuidExpression.test(this.context.selectedUser.associateID) === true ? this.context.selectedUser.associate_Card_Number : this.context.selectedUser.associateID;
        //console.log("ass be ", associateNumber);

        //var associateNumber1 = associateNumber.replace('E', '');
        //console.log("ass E ", associateNumber1);

        //var associateNumber2 = associateNumber1.replace(/^0+/, '');
        //console.log("ass zeros ", associateNumber2);

        ////var associateNumber3 = Math.floor(associateNumber2 / 10);
        //var associateNumber3 = associateNumber2 / 10;
        //console.log("ass associateNumber3 ", associateNumber3);

        //var associateNumber4 = associateNumber3.toString().replace('.', '-');
        //console.log("ass associateNumber3 ", associateNumber4);

        var selectedAssociado = {
            associado_ID: (this.context.selectedUser.profileId) ? this.context.selectedUser.profileId : this.context.selectedUser.associateID,
            name: this.context.selectedUser.name,
            dateOfBirth: this.context.selectedUser.birthDate,
            nif: this.context.selectedUser.nif,
            //email: (this.context.selectedUser.mymEmail) ? this.context.selectedUser.mymEmail : '',
            email: this.context.parentUser.mymEmail,
            //associate_number: GuidExpression.test(this.context.selectedUser.associateID) === true ? this.context.selectedUser.associate_Number : this.context.selectedUser.associateID,
            associate_number: associateNumber,
            //phone: (this.context.selectedUser.mymTelephone) ? this.context.selectedUser.mymTelephone : ''
            phone: this.context.parentUser.mymTelephone
        }

        //console.log("selectedAssociado ", selectedAssociado);

        var userData = {
            subscriptionsHistory: subscriptionsHistory,
            flow: flow,
            subscription_ID: intentionID.toString(),
            subs_ID: params.subscriptionID,
            productCode: productID,
            subscriptionNumber: params.subscriptionNumber,
            //associado: {
            //    associado_ID: profileId, //this.state.profile.id,
            //    name: this.state.profile.name,
            //    dateOfBirth: this.state.profile.birthDate,
            //    nif: this.state.profile.nif,
            //    email: this.state.profile.mymEmail,
            //    associate_number: this.state.profile.associateID,
            //    phone: this.state.profile.mymTelephone
            //}
            associado: selectedAssociado
        };

        var funcCallback = {
            close: function (Subscription_ID, Associado_ID) {
                ////redirect to poupanca
                //window.location.replace('/poupanca');

                //redirect to HomePage
                window.location.replace('/');


                /*this.props.history.push('/');*/
                /*this.redirect();*/
                //document.location.href('/');

            },
            stage: function (Subscription_ID, Associado_ID, stage) {
            },
            finish: async function (Subscription_ID, Associado_ID, Amount, ProductoComSerie_ID) {
                var productCodeAndSerie = ProductoComSerie_ID;

                //console.log("productCodeAndSerie ", productCodeAndSerie);
                
                try {
                    //console.log("Subscrição Plot ", Subscription_ID, " ", Associado_ID, " ", Amount, " ", ProductoComSerie_ID);
                    var AmountValue = isNaN(Amount) ? 0 : Amount;
                    const headers = { Authorization: `Bearer ${await authentication.getAccessToken()}`, 'Accept': 'application/json', 'Content-Type': 'application/json' };
                    const response = await fetch("/Intentions/FinishIntention", {
                        headers,
                        method: "POST",
                        body: JSON.stringify({
                            IntentionID: intentionID,
                            FinishedState: 'submetido',
                            IntentionType: flow,
                            /*ProductCode: modalidade.productID,*/
                            ProductCode: productCodeAndSerie,
                            subscriptionNumber: params.subscriptionNumber,
                            Amount: AmountValue,
                            EmployeeNumber: employeenumber,
                            EmployeeEmail: employeeemail
                        })
                    });
                } catch (err) {
                    console.error(err);
                }
            },
            productContent: async function (wp_ID, amm_ID, amm_sub_id) {

                try {
                    //get the product information
                    let requestUrl = "/Portfolio/GetProductDetails?productID=" + amm_ID + "&subID=" + amm_sub_id;
                    const headers = { Authorization: `Bearer ${await authentication.getAccessToken()}` };
                    const response = await fetch(requestUrl, { headers });

                    if (response.status !== 200) {
                        this.setState({ popupData: [], popupHasData: false });
                    }
                    else if (response.status == 204) {
                        this.setState({ popupData: [], popupHasData: false });
                    }
                    else {
                        const responseData = await response.json();
                        //define the state to update the popup content
                        this.setState({ popupData: responseData, popupHasData: true });
                        //Trigger the popup
                        document.getElementById('subscricao-popup-trigger').click();
                    }
                } catch (err) {
                    console.error(err);
                }
            }.bind(this)
        }

        //    const res = async function getWordPressURL(){ () => {
        //            let controller = new AbortController()
        //            setTimeout(() => controller.abort(), 3000); //Abort fucntion after 3 seconds (3000)

        //            //Load Print Form
        //            const resp = await fetch("Auth/GetWordpressURL", { signal: controller.signal }); /*window.MyMontepioSubscriptionForms.printForm('#form-root', userData, funcCallback, res, { signal: controller.signal });*/
        //            const responseJson = await resp.json();

        //            if (!responseJson.ok) {

        //            }
        //            return window.MyMontepioSubscriptionForms.printForm('#form-root', userData, funcCallback, res);
        //        }
        //    }
        //    ////Load Form
        //    //   await window.MyMontepioSubscriptionForms.printForm('#form-root', userData, funcCallback, res);

        //}


        try {

            appInsights.trackTrace({ message: 'Formulário Plot', properties: { associateNumber: userData.associado.associate_number, status: 'IN_PROGRESS'}});

            const res = await getWordPressURL();
            //Load Form
            window.MyMontepioSubscriptionForms.printForm('#form-root', userData, funcCallback, res);
            appInsights.trackTrace({ message: 'Formulário Plot', properties: { associateNumber: userData.associado.associate_number, status: 'COMPLETED'} });
        
        }
        catch (err) {

            appInsights.trackTrace({ message: 'Formulário Plot', properties: { associateNumber: userData.associado.associate_number, status: 'FAILED'} });
        }

    }
}

Subscricao.contextType = ContextConstructor;