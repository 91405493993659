import React from 'react';

const ContextConstructor = React.createContext();

export default ContextConstructor;

export class ContextProvider extends React.Component{

    state = {
        mymUsers: [],
        selectedUser: [],
        selectedUserID: '',
        tokenUser: '',
        userName: '',
        parentUser: {}
    }

    contextInit = (usersProfile, selectedUserIDClientID, isActualUser, selectedUserProfile) => {

        console.log("selectedUserProfile ", selectedUserProfile)

        //Atribute parameter to the state variables
        this.setState({
            mymUsers: usersProfile,
            selectedUser: selectedUserProfile,
            selectedUserID: selectedUserIDClientID,
            tokenUser: isActualUser,
            parentUser: selectedUserProfile
        });
    }

    changeselectedUserID = (associate) => {
        //Change selectedMymUser state variable

        this.setState({
            selectedUserID: associate.clientID,
            selectedUser: associate
        });
    }

    render() {

        console.log("main context ", this.state)

        //Defining Context variables
        const mymUsers = this.state.mymUsers;
        const selectedUser = this.state.selectedUser
        const selectedUserID = this.state.selectedUserID;
        const tokenUser = this.state.tokenUser;
        const parentUser = this.state.parentUser;

        //Defining Context functions
        const { contextInit } = this;
        const { changeselectedUserID } = this;

        return (
            <ContextConstructor.Provider
                value={{
                    mymUsers,
                    selectedUser,
                    selectedUserID,
                    tokenUser,
                    contextInit,
                    changeselectedUserID,
                    parentUser
                }}
            >
                {this.props.children}
            </ContextConstructor.Provider>
        )
    }
}
