
/* COMPONENT IMPORTS*/
import DetailsList from '../Listing/DetailsList';
import DownloadList from '../Listing/DownloadList';
import ContextConstructor from "../Context/Context";

/* MODULE IMPORTS */
import { withRouter } from 'react-router';
import Popup from "reactjs-popup";
import React, { Component } from 'react';

/* SAME FOLDER IMPORTS*/

/* CSS IMPORT */
import './ProductDetails.css'
import "../../pages/Poupanca/tablestyling.css";


class ProductDetailsCallback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            hasData: this.props.hasData
        }
    }

    componentDidMount() {
        
    }

    renderData(props) {
        let modalityType = "subscrição";


        if (!props.hasData) {
            return (
                <Popup
                    trigger={<div style={{ "display": "none" }} id="subscricao-popup-trigger"></div>}
                    contentStyle={{ "background": "none", "border": "none", "box-shadow": "none" }}
                    position="top center"
                >
                    {close =>
                        <div>
                            <div className="warning-popup">
                                <div className="alert-icon-section">
                                    <img src={require('../../images/alerta.png')} alt="Alerta" />
                                </div>
                                <div className="content-section">
                                    Esta {modalityType} não tem mais informação disponível. Para saber mais contacte 213 248 112 (dias úteis, das 09h às 21h. Custo de chamada para a rede fixa nacional).
                                    </div>
                                <div className="close-icon-section">
                                    <img src={require('../../images/closeIcon.png')} alt="Fechar" onClick={close} style={{ "cursor": "pointer" }} />
                                </div>
                            </div>

                            <div className="warning-popup warning-popup-mobile">
                                <div className="popup-window-mobile-header">
                                    <a href="/">
                                        <img src={require('../../images/logoMobile.png')} alt="logo" height="45px" className=""></img>
                                    </a>
                                    <img className="popup-window-mobile-close" src={require('../../images/closeIcon.png')} alt="Fechar" onClick={close} style={{ "cursor": "pointer" }} />
                                </div>


                                <div className="main">
                                    <center>
                                        <img style={{ "margin-bottom": "30px" }} src={require('../../images/erro.png')} alt="" width="100px" height="100px"></img>
                                        <div className="error-title">Esta {modalityType} não tem mais informação disponível.</div>
                                        <div className="error-description">Para saber mais contacte 213 248 112 (dias úteis, das 09h às 21h. Custo de chamada para a rede fixa nacional).</div>
                                    </center>
                                </div>

                            </div>
                        </div>
                    }
                </Popup>
            );
        }
        else {
            return (
                <Popup
                    trigger={<div style={{ "display": "none" }} id="subscricao-popup-trigger"></div>}
                    contentStyle={{ "background": "none", "border": "none" }} modal closeOnDocumentClick>
                    {close =>
                        <div className="popup-window">
                            <div className="popup-window-mobile-header">
                                <a href="/">
                                    <img src={require('../../images/logoMobile.png')} alt="logo" height="45px" className=""></img>
                                </a>
                                <img className="popup-window-mobile-close" src={require('../../images/closeIcon.png')} alt="Fechar" onClick={close} style={{ "cursor": "pointer" }} />
                            </div>
                            <div className="popup-window-close">
                                <img src={require('../../images/close.png')} alt="Fechar" onClick={close} style={{ "cursor": "pointer" }} />
                            </div>
                            <div className="title-productdetails">
                                {props.data.head.title}
                            </div>
                            <div className="subtitle">
                                {props.data.head.subtitle}
                            </div>
                            <div className="central-container row">
                                <div className="first-div image-container" style={{ "backgroundImage": "url(" + props.data.banner.image + ")" }}></div>
                                <div className="second-div">
                                    <div className="title-productdetails">
                                        {props.data.banner.headline}
                                    </div>
                                    <div className="description">
                                        {props.data.banner.subheadline}
                                    </div>
                                </div>
                                <div className="third-div">
                                    <div className="details">
                                        <div className="text1">
                                            {props.data.banner.duration}
                                        </div>
                                        <div className="text2">
                                            {props.data.banner.rate}
                                        </div>
                                        <div className="text3">
                                            {props.data.banner.extras}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <DetailsList list={props.data.content} />
                            <DownloadList files={props.data.documents} />
                        </div>
                    }
                </Popup>
            );
        }
    }

    render() {        
        let contents = this.renderData(this.props);
        return <main>{contents}</main>;
    }
}

ProductDetailsCallback.contextType = ContextConstructor;

export default withRouter(ProductDetailsCallback);