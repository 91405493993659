/* MODULE IMPORTS */
import React, { Component } from 'react';
import { ErrorPage } from '../ResponsePages/ErrorPage';
import { ErroSms } from '../ResponsePages/ErroSms';
import { SuccessPwReset } from '../ResponsePages/SuccessPwReset';
import { NewAccountSuccess } from '../ResponsePages/NewAccountSuccess';
import { PageMaintenance } from '../ResponsePages/PageMaintenance';


export class Response extends Component {
    static displayName = Response.name;
   
    render() {
        const code = this.props.match.params.code;

        if (code == "AADB2C90151" || code == "AADB2C90157") {
            return (<ErroSms />);
        }
        else if (code == "resetpwsuccess") {
            return (<SuccessPwReset />);
        }
        else if (code == "accountsuccess") {
            return (<NewAccountSuccess />);
        }
        else if (code == "maintenance") {
            return (<PageMaintenance />);
        }
        else {
            return (<ErrorPage />);
        }

    }
}