/* COMPONENT IMPORTS*/
import { ErrorPage } from "../../components/ErrorPage/ErrorPage";
import authentication from "../../msalb2creact";
import authenticationsaml from "../../msalb2creact";
import DetailsList from "../../components/Listing/DetailsList";
import DownloadList from "../../components/Listing/DownloadList";
import { Advantages } from "../../components/CartaoAssociado/Advantages";
import { CardSection } from "../../components/CartaoAssociado/CardSection";
import { Loading } from "../../components/Loading/Loading";
import NotificationBanner from "../../components/Notifications/NotificationBanner";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { NavLink } from "react-router-dom";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { GlossaryPopup } from "../../components/Glossary/GlossaryPopup";
import { Highlight } from "../../components/Highlights/Highlight";

/* MODULE IMPORTS */
import React, { Component } from "react";

/* SAME FOLDER IMPORTS*/
import SavingsNewItem from "../Poupanca/SavingNewItems";
import SavingNewItemsMobile from "../Poupanca/SavingNewItemsMobile";
import SavingsItem from "../Poupanca/SavingsItem";
import SavingsItemMobile from "../Poupanca/SavingsItemMobile";

/* CSS IMPORT */

var reactPlugin = new ReactPlugin();

export class ColunaDireita extends Component {
  static displayName = ColunaDireita.name;

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <div className="document-descricao"> {this.props.description}</div>
      </div>
    );
  }
}

export default withAITracking(reactPlugin, ColunaDireita);
