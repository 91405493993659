
/* COMPONENT IMPORTS*/

/* MODULE IMPORTS */
import React, { Component } from "react";
import Popup from "reactjs-popup";
import authentication from '../../msalb2creact';

/* SAME FOLDER IMPORTS*/

/* CSS IMPORT */
import "./Glossary.css";
import "../Loading/loading.css";
const createDOMPurify = require('dompurify');
const DOMPurify = createDOMPurify(window);


export class GlossaryPopup extends Component {
    static displayName = GlossaryPopup.name;

    constructor(props) {
        super(props);
        this.state = {
            glossarydownload: [],
            glossarycontent:[],
            hasGlossary: false,
            loading: true
        };
    }

    componentDidMount() {
        this.getGlossaryLink();
        this.populateGlossaryContent();
    }

    render() {
        let glossaryContent = this.state.hasGlossary ? 
        ( <div className="ContentFromWordPress" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.glossarycontent.post_Content)}}/>) : (
        <div>
            <div className="help-subtitle">
                Associado Efetivo
            </div>
            <div className="help-text">
                Pessoa individual admitida no Montepio Geral – Associação Mutualista que pague a Jóia, a Quota Associativa e subscreva e mantenha pelo menos uma Modalidade Individual. No caso dos Associados admitidos até 30 de abril de 1988, que não tenham optado pelo pagamento da Quota Associativa, a condição de associado efetivo continua a ser assegurada exclusivamente pela manutenção de uma Subscrição realizada até àquela data, numa das Modalidades então em vigor, que conferem aquela condição.
            </div>
            <div className="help-subtitle">
                Benefício Solidariedade Associativa
            </div>
            <div className="help-text">
                Capital a receber, pelos Beneficiários, em caso de Acidente de que resulte morte ou Invalidez Total e Permanente de um Associado Efetivo cujo Vínculo Associativo seja realizado através da Quota Associativa.
            </div>
            <div className="help-subtitle">
                Capital Protegido
            </div>
            <div className="help-text">
                Corresponde ao Capital Subscrito/Contratado: Capital coberto que o Montepio Geral – Associação Mutualista paga aos beneficiários de cada Subscrição de uma Modalidade individual de Proteção (Grupos II e III), nos termos em que o Regulamento da Modalidade o prevê.
            </div>
            <div className="help-subtitle">
                Declaração de Beneficiários
            </div>
            <div className="help-text">
                Documento disponibilizado pelo Montepio Geral – Associação Mutualista, para efeitos da Subscrição das suas Modalidades, no qual o Subscritor deverá designar e identificar claramente os Beneficiários, bem como a percentagem a receber por cada um e a forma como pretende que seja efectuado o pagamento do Benefício.
            </div>
            <div className="help-subtitle">
                Modalidades Grupo I
            </div>
            <div className="help-text">
                Modalidades Individuais de Poupança, nas quais se incluem: a) Montepio - Poupança Complementar; b) Montepio Poupança Reforma; e c) Montepio Capital Certo
            </div>
            <div className="help-subtitle">
                Modalidades Grupo II
            </div>
            <div className="help-text">
                Modalidades Individuais de Proteção Vida para garantia exclusiva do pagamento de encargos emergentes de Contratos de Crédito, ou que preveem também essa possibilidade, nas quais se incluem: a) Montepio Proteção – Crédito à Habitação; b) Montepio Proteção – Crédito Individual; e c) Montepio Proteção – Outros Encargos.
            </div>
            <div className="help-subtitle">
                Modalidades Grupo III
            </div>
            <div className="help-text">
                Modalidades Individuais de Proteção Vida, Mistas e de Proteção Longevidade, nas quais se incluem: a) Montepio Proteção Vida; b) Montepio Proteção Invalidez; c) Montepio
            </div>
            <div className="help-subtitle">
                Modalidades Individuais de Poupança
            </div>
            <div className="help-text">
                Modalidades destinadas a valorizar poupanças por um dado prazo, sem limite de prazo, ou para constituição de um complemento de reforma.
            </div>
            <div className="help-subtitle">
                Quota Associativa
            </div>
            <div className="help-text">
                Valor da contribuição a efetuar pelo Associado para a obtenção e manutenção do Vínculo Associativo, cujo pagamento é condição necessária para garantia dos direitos associativos.
            </div>
            <div className="help-subtitle">
                Rendimento Complementar
            </div>
            <div className="help-text">
                Rendimento adicional bruto que poderá acrescer ao Rendimento Mínimo Garantido, quando previsto, em Modalidades de Poupança (Grupo I).
            </div>
            <div className="help-subtitle">
                Rendimento Global
            </div>
            <div className="help-text">
                Rendimento bruto resultante do somatório do Rendimento Mínimo Garantido, quando previsto, de eventuais Bonificações e do Rendimento Complementar, quando devido, de Modalidades de Poupança (Grupo I).
            </div>
            <div className="help-subtitle">
                Rendimento Mínimo Garantido
            </div>
            <div className="help-text">
                Rendimento bruto mínimo a que cada Subscrição poderá ter direito, quando previsto em Modalidades de Poupança (Grupo I).
            </div>
            <div className="help-subtitle">
                Rendimento Global Acumulado
            </div>
            <div className="help-text">
                Rendimento Global atribuído e capitalizado desde o início de uma Subscrição de Modalidades de Poupança (Grupo I).
            </div>
            <div className="help-subtitle">
                Série
            </div>
            <div className="help-text">
                Emissão autónoma, a efetuar ao abrigo de determinadas Modalidades Individuais de Poupança (Grupo I), por um determinado prazo e dentro de um conjunto de condições específicas, enquadradas pela Modalidade a que dizem respeito e consubstanciadas na respetiva Ficha Técnica.
            </div>
        </div>
        );
        let content = this.state.loading ? (""):(
            <Popup trigger={<img id="glossaryTrigger" src={require('../../images/help.svg')} alt="Botão de Ajuda" className="popup"></img>} contentStyle={{ "background": "none", "border": "none" }} modal closeOnDocumentClick>
            {close => <div className="help-window">
                <img src={require('../../images/close.png')} alt="Botão de fechar" height="50px" width="50px" className="glossary-close" onClick={close}></img>
                <div className="glossary-content">
                    <div className="help-title">
                        Informação de Apoio
                    </div>
                    <div style={{ "display": "flex", "marginBottom": "64px" }}>
                        <img style={{ "marginRight": "18px" }} src={require('../../images/pdfIcon.png')} alt="Icone de PDF" height="26px" width="26px" />
                        <div>
                            <div className="glossary-title">Glossário completo</div>
                                <a className="glossary-download-button" href={this.state.glossarydownload} download="glossario.pdf" target="_blank" rel="noopener noreferrer">
                                <div>Clique aqui</div>
                                <img src={require('../../images/download.png')} alt="Botão de Download" height="7.5px" width="7.2px" />
                            </a>
                        </div>
                    </div>
                    {glossaryContent}
                </div>
            </div>
            }
        </Popup>
        )
        return (
            <div>{content}</div>
        );
    }
    async getGlossaryLink() {
        const headers = { Authorization: `Bearer ${authentication.getAccessToken()}` };
        const Url = await fetch("/Auth/GetGlossaryLink", { headers });
        var glossaryLink = await Url.text();
        this.setState({ glossarydownload: glossaryLink });
    }
    async populateGlossaryContent() {
        await new Promise(r => setTimeout(r, 1));
        const headers = { Authorization: `Bearer ${authentication.getAccessToken()}`};

        const Url = await fetch("/Wordpress/GetGlossary?page=" + this.props.page, { headers });
        if( Url.status !== "200") 
            this.setState({hasGlossary: false, loading: false});
        else {
            var glossary = await Url.json();
            this.setState({ glossarycontent: glossary, hasGlossary: true, loading: false});
        }
    }
}
