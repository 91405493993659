
/* COMPONENT IMPORTS*/

/* MODULE IMPORTS */
import React, { Component } from 'react';
import '../../components/ProductDetails/ProductDetails.css';

/* SAME FOLDER IMPORTS*/

/* CSS IMPORT */
const createDOMPurify = require('dompurify');
const { JSDOM } = require('jsdom');
const window = (new JSDOM('')).window;
const DOMPurify = createDOMPurify(window);

class DetailsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: this.props.list
        };
    }
    render() {
        return (
            <React.Fragment>
                {this.state.listData.map((content, index) =>
                    <div key={index} className="additional-info">
                        <div className="image-productdetails">
                            <img src={content.image} alt="img" />
                        </div>
                        <div className="text-productdetails">
                            <div className="title-productdetails">
                                {content.title}
                            </div>
                            <div className="description-productdetails" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content.body) }} />
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default DetailsList;