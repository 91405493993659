
/* COMPONENT IMPORTS*/
import { GlossaryPopup } from "../../components/Glossary/GlossaryPopup";
import { ErrorPage } from '../../components/ErrorPage/ErrorPage';
import authentication from '../../msalb2creact';
import NotificationBanner from '../../components/Notifications/NotificationBanner'
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
import { Highlight } from '../../components/Highlights/Highlight';
import LoadFormsScripts from '../../components/FormsDataLoader/FormsDataLoader';
import ContextConstructor from "../../components/Context/Context";

/* MODULE IMPORTS */
import React, { Component } from "react";

/* SAME FOLDER IMPORTS*/
import SavingsNewItem from './SavingNewItems';
import SavingNewItemsMobile from './SavingNewItemsMobile';
import SavingsItem from './SavingsItem';
import SavingsItemMobile from './SavingsItemMobile'

/* CSS IMPORT */
import "../../components/Loading/loading.css";
import "./Poupanca.css";

/*const pageid = "14596";*/ /*RETIRAR*/
const browserHistory = createBrowserHistory({ basename: 'Savings Page' });
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: 'a20b3d0d-87b9-41e6-bcde-892a425215fe',
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();

class Poupanca extends Component {
    static displayName = Poupanca.name;

    constructor(props) {
        super(props);
        this.state = {
            savings: [],
            error: [],
            loading: true,
            canCreateNew: false,
        };
    }

    componentDidMount() {

        //console.log("Poupanca Context ", this.context)

        if (this.context.selectedUserID !== '' && this.context.selectedUserID !== undefined && this.context.selectedUserID !== null) {
            this.populatePortfolioData();
        }
        
    }

    errorLoadingPortfolio() {
        return (
            <div>
                <ErrorPage />
            </div>
        );
    }

    changeValueColor(value) {
        if (value != "0.0") {
            return (
                <React.Fragment>
                    <div className="table-applied">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value)}</div>
                </React.Fragment>
            )
        }
        else {
            return (
                <React.Fragment>
                    <td className="table-applied" style={{ "color": "#B2B2B2" }}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value)}</td>
                </React.Fragment>
            )
        }
    }


    renderPortfolioData(state) {

        function transformActives(a) {
            if (a == '1')
                return a + ' subscrição ativa';
            else
                return a + ' subscrições ativas';
        }

        function getCreationArea(state) {
            if (state.canCreateNew) {
                return (
                    <div>
                        <div className="subscribe-text">Subscrever nova modalidade</div>
                        <SavingsNewItem savingsNewList={state.savings.productTypes} />
                        <SavingNewItemsMobile savingsNewList={state.savings.productTypes} />
                    </div>
                );
            }
        }

        function getModalidadesArea(state) {
            if (state.savings.subscriptionsNumber > 0) {
                return (
                    <div>
                        <SavingsItem savingsList={state.savings.productTypes} />
                        <SavingsItemMobile savingsList={state.savings.productTypes} nSavingsWithSubscriptions={state.nSavingsWithSubscriptions} />
                    </div>
                );
            }
        }

        return (
            <main className="main">
                <NotificationBanner/>
                <div className="title-bar">
                    <div className="title">Poupança</div>
                    <div className="title-glossary"><GlossaryPopup page="poupanca"/></div>
                </div>
                <div className="modalidades-bar">
                    <div className="resumo-poupanca">
                        <div className="first-div">
                            <div className="table-modalidades">Modalidades</div>
                            <div className="table-subscricoes">{transformActives(state.savings.subscriptionsNumber)}</div>
                        </div>                        
                        <div className="second-div">
                            <div style={{ "paddingRight": "10px", "paddingBottom": "5px" }}><img src={require('../../images/poupanca.png')} height="48px" width="48px"></img></div>
                            <div>
                                <div className="table-appliedtitle">Aplicados</div>
                                <div>{this.changeValueColor(state.savings.totalAmounts.totalInvestedAmount)}</div>
                            </div>
                            <div style={{ "paddingRight": "20px", "paddingLeft": "20px" }} ><img src={require('../../images/separator.svg')} ></img></div>
                            <div>
                                <div className="table-appliedtitle">Rendimento Bruto</div>
                                {this.changeValueColor(state.savings.totalAmounts.totalReceivedAmount)}
                            </div>
                        </div>
                        <div className="third-div">
                            <div className="table-modalidades">Modalidades</div>
                            <div className="table-subscricoes">{transformActives(state.savings.subscriptionsNumber)}</div>
                        </div> 
                    </div>                     
                </div>
                <div className="modalidades-bar modalidades-bar-mobile">
                    <div className="resumo-poupanca">
                        <div className="second-div" style={{ "alignItems":"center"}}>
                            <div style={{ "paddingRight": "10px" }}><img src={require('../../images/poupanca.png')} width="95px"></img></div>
                            <div style={{}}>
                                <div style={{ "paddingBottom": "10px" }}>
                                    <div className="table-appliedtitle">Aplicados</div>
                                    <div>{this.changeValueColor(state.savings.totalAmounts.totalInvestedAmount)}</div>
                                </div>
                                <div>
                                    <div className="table-appliedtitle">Rendimento Bruto</div>
                                    {this.changeValueColor(state.savings.totalAmounts.totalReceivedAmount)}
                                </div>
                            </div>                            
                        </div>
                        <div className="third-div">
                            <div className="table-modalidades">Modalidades</div>
                            <div className="table-subscricoes">{transformActives(state.savings.subscriptionsNumber)}</div>
                        </div>
                    </div>
                </div>
                {getModalidadesArea(state)}
                {getCreationArea(state)}
                <Highlight page="poupanca"/>
            </main>
        );
    }


    render() {
        let contents = this.state.loading
            ? <div className="main-page-loading">
                <div className="loading">
                    <img src={require('../../images/loading.svg')}></img>
                </div>
            </div>
            : this.state.error ? this.errorLoadingPortfolio() : this.renderPortfolioData(this.state);
        return (
            <div>
                {contents}
            </div>
        );
    }

    async populatePortfolioData() {

        var clientId = this.context.selectedUserID;
        //console.log("poupanca client ", clientId)

        const headers = {
            Authorization: `Bearer ${authentication.getAccessToken()}`,
            clientId: clientId,
            'Accept': 'application/json, text/plain',
            'Content-Type': 'application/json;charset=UTF-8'
        };

        await fetch(`/Portfolio/GetPortfolio`, {
            headers,
            method: 'GET',
        })
            .then((res) => {
                //console.log("response portfolio (1) ", res);
                return res.json();
            })
            .then((response) => {

                //console.log("response portfolio (2) ", response);

                let canCreateNew = false;
                let nSavingsWithSubscriptions = 0;

                response.savings.productTypes.forEach(product => {
                    if (product.subscriptionsNumber == 0) {
                        canCreateNew = true;
                    } else {
                        nSavingsWithSubscriptions += 1;
                    }
                });

                this.setState({
                    savings: response.savings,
                    error: false,
                    loading: false,
                    canCreateNew: canCreateNew,
                    nSavingsWithSubscriptions: nSavingsWithSubscriptions
                });
            })
            .catch((error) => {
                //console.log("error portfolio", error);

                this.setState({ error: true, loadingP: false });
            });
    }
}

Poupanca.contextType = ContextConstructor;

export default withAITracking(reactPlugin, Poupanca);
