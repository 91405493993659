import React, { Component } from "react";

export class CardSection extends Component {
    render() {
        return (
            <section className="card-section">
                <img src={require('../../images/cartao-exemplo.png')} alt="Imagem exemplo de um cartão de associado" width="322px" height="228px"></img>
                <div className="table-benefits">
                    <div className="down-group">
                        <img src={require('../../images/cartaoassociado.png')} alt="" style={{ "marginRight": "40px" }} width="75px" height="75px"></img>
                        <div>
                            <div className="table-gain">
                                <div>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(this.props.benefitsValue)}</div>
                            </div>
                            <div className="table-description">Valor que já poupou por utilizar o Programa de Benefícios</div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
