import React, { Component } from 'react';
import ReactMinimalPieChart from 'react-minimal-pie-chart';
import { dataPropType } from 'react-minimal-pie-chart/src/propTypes';
import './BenefitsPie.css'

const NORMAL_WIDTH = 28;
const FULL_WIDTH = 31;

class BenefitsPie extends Component {
    constructor(props) {
        super(props)
        this.state = {
            montantePoupado: this.props.valorPoupado,
            data: props.data.map(entry => ({
                ...entry, ...{
                    style: {
                        strokeWidth: NORMAL_WIDTH,
                        width: "20px",
                        height: "20px",
                        fontSizeTitle: "2px",
                        fontSizeValue: "4px"
                    }
                },
            })),
            selected: undefined,
        };
        this.onMouseOut = this.onMouseOut.bind(this);
        this.onMouseOver = this.onMouseOver.bind(this);
    }

    //On hover of pie
    onMouseOver(e, d, i) {
        const data = d.map((entry, index) => {
            return index === i ? {
                ...entry, style: {
                    ...entry.style,
                    strokeWidth: i === index ? FULL_WIDTH : NORMAL_WIDTH,
                    width: "24px",
                    height: "24px",
                    fontSizeTitle: "2.5px",
                    fontSizeValue: "4.5px"
                },
            } : entry;
        });

        this.setState({
            data,
        });
    }

    //On exit hover of pie
    onMouseOut(event, propsData, index) {
        const data = propsData.map((entry, i) => {
            return i === index
                ? {
                    ...entry,
                    style: {
                        ...entry.style,
                        strokeWidth: i === index ? NORMAL_WIDTH : FULL_WIDTH,
                        width: "20px",
                        height: "20px",
                        fontSizeTitle: "2px",
                        fontSizeValue: "4px"
                    },
                }
                : entry;
        });

        this.setState({
            data,
        });
    }

    render() {
        function customLabel(labelProps, state) {
            return (
                <React.Fragment key={labelProps.dataIndex}>
                    <foreignObject x={labelProps.dx + 41}
                        y={labelProps.dy + 40}
                        width="12"
                        height="20"
                        style={{ overflow: 'inherit' }}>
                        <div style={{
                            background: '#FFFFFF',
                            boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.1)',
                            borderRadius: '70px',
                            height: labelProps.data[labelProps.dataIndex].style.height,
                            width: labelProps.data[labelProps.dataIndex].style.width,
                            textAlign: 'center'
                        }}>
                            <div>
                                <div style={{
                                    fontFamily: 'rawline',
                                    fontSize: '18%',/*labelProps.data[labelProps.dataIndex].style.fontSizeTitle,*/
                                    fontWeight: '800',
                                    textAlign: 'center',
                                    color: '#6D6E71',
                                    paddingTop: '3.5px',
                                    marginLeft: '1px',
                                    marginRight: '1px'
                                }}>
                                    {labelProps.data[labelProps.dataIndex].title}
                                </div>
                                <div style={{
                                    fontFamily: 'rawline',
                                    fontSize: '25%', /*labelProps.data[labelProps.dataIndex].style.fontSizeValue,*/
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    marginTop: '-1px',
                                    color: labelProps.color
                                }}>
                                    {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(labelProps.data[labelProps.dataIndex].value)}
                                </div>
                            </div>
                        </div>
                    </foreignObject>
                </React.Fragment >
            )
        }

        return (
            <React.Fragment>
                <div className="center-pie-information">
                    <img className="benefits-pie-image-center" src={require('../../images/poupado.png')} width="61px" height="60px" alt="beneficios"></img>
                    <div className="center-pie-value">
                        {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(this.state.montantePoupado)}
                        </div>
                    <div className="center-pie-subtext">
                        O que já poupou e ganhou por ser Associado
                        </div>
                </div>
                <div className="pie-information" style={{ padding: "70px" }}>
                    <ReactMinimalPieChart
                        animate={false}
                        animationDuration={0}
                        animationEasing="ease-out"
                        cx={50}
                        cy={50}
                        data={this.state.data}
                        label={(labelProps) => customLabel(labelProps, this.state)}
                        labelPosition={130}
                        lengthAngle={360}
                        lineWidth={25}
                        onClick={undefined}
                        onMouseOut={this.onMouseOut}
                        onMouseOver={this.onMouseOver}
                        paddingAngle={0}
                        radius={42}
                        rounded={false}
                        startAngle={0}
                        viewBoxSize={[
                            100,
                            100
                        ]}
                    />
                </div>
            </React.Fragment>
        )
    }
}

BenefitsPie.propTypes = {
    data: dataPropType,
};

export default BenefitsPie;