/* MODULE IMPORTS */
import React, { Component } from 'react';

/* CSS IMPORT */
import './maintenance.css';

export class PageMaintenance extends Component {
    static displayName = PageMaintenance.name;

    render() {
        return (
            <div>
                <div className="header">
                    <header class="toolbar">
                        <nav class="toolbar_navigation">
                            <div class='column'>
                                <a href="/">
                                    <div class="toolbar_logo">
                                        <img src={require('./imgs/mymontepio-registo.svg')} alt="MyMontepio" width="164" height="48"></img>
                                    </div>
                                </a>
                            </div>
                            <div class='column-direita'>
                                <div class="toolbar_logo"><img src={require('./imgs/Vector.png')} alt="Montepio" width="164" height="48"></img>
                                </div>
                            </div>
                        </nav>
                    </header>
                </div >
                <div class="with-background-image">
                    <div id="MainContainer">
                        <div id="MyDivsuccess">
                            <img id="maintenance" src={require('./imgs/maintenance.png')} alt="erro"/>
                            <p id="titulo-maintenance">Estamos a melhorar a sua área pessoal. <br /> Em breve poderá aceder novamente ao My Montepio</p>
                            <div class="contact">
                                <div class="contact-name">Linha My Montepio</div>
                                <div class="contact-number">213 248 112</div>
                                <div class="contact-availability">Custo de chamada para a rede fixa nacional</div>
                            </div>
                        </div>
                    </div>
                </div >                
            </div>
            
                );
    };
};