
/* MODULE IMPORTS */
import React, { Component } from 'react';

/* CSS IMPORT */
import './ErrorPage.css';

export class ErrorPage extends Component {
    static displayName = ErrorPage.name;

    render() {
        return (
            <div className="main">
                <center>
                    <img style={{ "marginBottom": "30px" }} src={require('../../images/erro.png')} alt="" width="100px" height="100px"></img>
                    <div className="error-title">Ocorreu um erro.</div>
                    <div className="error-description">Neste momento não conseguimos concluir a ação que pretende efetuar. </div>
                </center>
            </div>
        );
    }
}