
/* COMPONENT IMPORTS*/
import authentication from '../../msalb2creact';

/* MODULE IMPORTS */
import React, { Component } from "react";
import { NavLink } from "react-router-dom";

/* CSS IMPORT */
import "./QuotasStatus.css";

export class QuotasStatus extends Component {
    static displayName = QuotasStatus.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    closeMenuFunction() {
        //To close the sidedrawer
        this.props.closeMenuFunction();
    }

    render() {

        //Props for this component are passed by the context on DesktopNavBar
        //Verify and display according to the user state
        //this.props.selectedUserProfile.associateState == "CONDICIONADO", this.props.selectedUserProfile.hasQuotas === true
        let content = this.props.selectedUserProfile.associateState == "CONDICIONADO" ? (
            <div className="quota-atraso-wrapper">
                <div className="quota-atraso-img">
                    <img
                        src={require("../../images/alertaCircle.png")}
                        alt="Alerta"
                        className="status"
                    />
                </div>
                <div className="quota-atraso-texto">
                    Tem Quotas Associativas em atraso. &nbsp; {/*&nbsp; &nbsp; &nbsp; &nbsp;*/}
                    <NavLink to="/fees" style={{ "textDecoration": "none" }}><span onClick={this.closeMenuFunction.bind(this)} className="quota-atraso-popup-trigger">Saiba mais</span></NavLink>
                </div>
            </div>
        ) : this.props.selectedUserProfile.associateState !== "CONDICIONADO" &&
                this.props.selectedUserProfile.associateState !== "ATIVO" ? (
            <div>
                {/*Display nothing for No Associates*/}
            </div>
        ) :
        (
            <div>
                <span className="quotaassociativa">
                    Quota associativa:
                </span>
                <img
                    src={require("../../images/CheckCircle.png")}
                    alt=""
                    style={{ "marginLeft": "5px", "marginRight": "2px" }}
                    width="12px"
                    height="12px"
                    className="status"
                />
                <span className="status"> EM DIA</span>
            </div>
            );
        
        return content;
    }

     sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
     }

    //async populateData() {
    //    //Required in order to be able to get the token
    //    setTimeout(async () => {
    //        const headers = { Authorization: `Bearer ${authentication.getAccessToken()}` };
    //        const response = await fetch("/Quotas/GetFees", { headers });
            
    //        if (response.status !== 200) {
    //            this.setState({ error: true, loading: false });
    //        }
    //        else {
    //            const data = await response.json();
    //            data.totalValue == "0" ? this.setState({ error: false, hasUnpaidQuota: false, loading: false  }) : this.setState({ error: false, hasUnpaidQuota: true,loading: false });
    //        }
    //    }, 0);
    //}
}


