
/* MODULE IMPORTS */
import React, { Component } from 'react';

/* CSS IMPORT */
import './ErrorPageQuotes.css';

export class ErrorPageQuotes extends Component {
    static displayName = ErrorPageQuotes.name;

    render() {
        return (
            <div className="">
                <center>
                    <img style={{ "marginBottom": "30px", "marginTop": "30px" }} src={require('../../images/erro.png')} alt="" width="100px" height="100px"></img>
                    <div id="duvidas3">Para mais informações contacte o seu Gestor Mutualista ou a Linha de Apoio</div>
                    <div id="duvidas2">213 248 112</div>
                    <div id="duvidas1">(atendimento personalizado, dias úteis das 09h às 21h. Custo de chamada para a rede fixa nacional)</div>
                    <div id="duvidas3"><a href="mailto:Suporte_MyMontepio@montepio.org" target="_top">Suporte_MyMontepio@montepio.org</a>
                    </div>
                </center>
            </div>
        );
    }
}