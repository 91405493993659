import React from "react";
import ReactDOM from "react-dom";
import registerServiceWorker from "./registerServiceWorker";
import "bootstrap/dist/css/bootstrap.css";
import authentication from "./msalb2creact";
import getadb2cauth from "./getadb2cauth";
import { Response } from "./pages/Response/Response";
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";
import Home from "./pages/Home/Home";
import Profile from "./pages/Profile/Profile";
import Poupanca from "./pages/Poupanca/Poupanca";
import Protecao from "./pages/Protecao/Protecao";
import Beneficios from "./pages/Beneficios/Beneficios";
import AdicionaMenor from "./pages/AdicionaMenor/AdicionaMenor";
import Correio from "./pages/Correio/Correio";
import Eleicoes from "./pages/Eleicoes/Eleicoes";
import { BenefitsConfirmation } from "./components/BenefitsConfirmation/BenefitsConfirmation";
import { Subscricao } from "./components/Subscricao/Subscricao";
import { CartaoAssociado } from "./pages/CartaoAssociado/CartaoAssociado";
import { Notifications } from "./pages/Notifications/Notifications";
import { Fees } from "./pages/Fees/Fees";

import Layout from "./components/Layout";
import { ContextProvider } from "./components/Context/Context";
import RouteRuler from "./components/RouteRuler";

/* CSS IMPORT */
import "./custom.css";


const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

getadb2cauth().then((res) => {

    authentication.initialize(res);


    window.userState = authentication.getUserStatus();

    ////se for condicionado ou n�o tem menores associados a ele
    //if (window.userState == "CONDICIONADO") {
    //    ReactDOM.render(
    //        <BrowserRouter basename={baseUrl}>
    //            <Switch>
    //                <Route path="/response/:code" component={Response} />
    //                <ContextProvider>
    //                    <Layout auth={authentication}>
    //                        <Route path="/fees" component={authentication.required(Fees)} />
    //                        <Redirect to="/fees" />
    //                        <Route
    //                            exact
    //                            path="/profile"
    //                            component={authentication.required(Profile)}
    //                        />
    //                        <Route
    //                            exact
    //                            path="/AdicionaMenor"
    //                            component={authentication.required(AdicionaMenor)}
    //                        />
    //                        <Route
    //                            exact
    //                            path="/notificacoes"
    //                            component={authentication.required(Fees)} />
    //                        <Redirect to="/fees" />
    //                    </Layout>
    //                </ContextProvider>
    //            </Switch>
    //        </BrowserRouter>,
    //        rootElement
    //    );
    //} else {
    //    ReactDOM.render(
    //        <BrowserRouter basename={baseUrl}>
    //            <Switch>
    //                <Route path="/response/:code" component={Response} />
    //                <ContextProvider>
    //                    <Layout auth={authentication}>
    //                        <Route exact path="/" component={authentication.required(Home)} />
    //                        <Route
    //                            exact
    //                            path="/profile"
    //                            component={authentication.required(Profile)}
    //                        />
    //                        <Route
    //                            exact
    //                            path="/poupanca"
    //                            component={authentication.required(Poupanca)}
    //                        />
    //                        <Route
    //                            exact
    //                            path="/protecao"
    //                            component={authentication.required(Protecao)}
    //                        />
    //                        <Route
    //                            exact
    //                            path="/beneficios"
    //                            component={authentication.required(Beneficios)}
    //                        />
    //                        <Route
    //                            exact
    //                            path="/benefitsconfirmation"
    //                            component={authentication.required(BenefitsConfirmation)}
    //                        />
    //                        <Route
    //                            exact
    //                            path="/subscricao"
    //                            component={authentication.required(Subscricao)}
    //                        />
    //                        <Route
    //                            exact
    //                            path="/cartaoassociado"
    //                            component={authentication.required(CartaoAssociado)}
    //                        />
    //                        <Route
    //                            exact
    //                            path="/fees"
    //                            component={authentication.required(Fees)}
    //                        />
    //                        <Route
    //                            exact
    //                            path="/notificacoes"
    //                            component={authentication.required(Notifications)}
    //                        />
    //                        <Route
    //                            exact
    //                            path="/correio"
    //                            component={authentication.required(Correio)}
    //                        />
    //                        <Route
    //                            exact
    //                            path="/AdicionaMenor"
    //                            component={authentication.required(AdicionaMenor)}
    //                        />
    //                        <Route
    //                            exact
    //                            path="/eleicoes"
    //                            component={authentication.required(Eleicoes)}
    //                        />
    //                    </Layout>
    //                </ContextProvider>
    //            </Switch>
    //        </BrowserRouter>,
    //        rootElement
    //    );
    //}
    ReactDOM.render(
        <BrowserRouter basename={baseUrl}>
            <Switch>
                <Route path="/response/:code" component={Response} />
                <ContextProvider>
                    <Layout auth={authentication}>
                        <RouteRuler />
                    </Layout>
                </ContextProvider>
            </Switch>
        </BrowserRouter>,
        rootElement
    );
    registerServiceWorker();
    //serviceWorker.unregister();
}).catch((error) => {
    console.log("error ", error)
});
