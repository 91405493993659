/* COMPONENT IMPORTS*/
import authentication from "../../msalb2creact";

/* MODULE IMPORTS */
import { createBrowserHistory } from "history";
import { withRouter } from 'react-router';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import React, { Component } from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';

/* CSS IMPORT */
import "./ProtectionSubs.css";

/* VARIABLES */
const browserHistory = createBrowserHistory({ basename: 'Savings Page' });
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: 'a20b3d0d-87b9-41e6-bcde-892a425215fe',
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();

class ProtectionSubscription extends Component {
    constructor(props) {
        super(props);
    }

    createSubscription(item) {
        var data = {
            subscriptionID: null,
            subscriptionSerieID: null,
            canReapply: false,
            canReinforce: false,
            /**startDateBFTeaser: null,
            endDateBFTeaser: null,
            startDateBF: null,
            endDateBF: null,
            actualDate: null**/
        };
        this.props.history.push({
            pathname: '/subscricao',
            state: { params: data, modalidade: item }
        })
    }

    ProtectionCards() {
        let products = this.props.protectionList.productTypes; 
        let isCoincidence = false;

        products.forEach((productElement) => {

            if (productElement['productID'] == '7170') {
                isCoincidence = true;
            }

        });

        let content = isCoincidence === true ? "" : (
            <div className="carousel-wrapper">
                <CarouselProvider
                    naturalSlideWidth={400}
                    naturalSlideHeight={500}
                    totalSlides={1}
                    visibleSlides={1}
                >
                    <Slider>
                            <Slide>
                                <div className="card-new card-new-mobile">
                                    <div className="card-new-table">
                                        <div className="card-new-title">
                                            <div className="serie-name-wrapper" style={{ "position": "initial !important" }}>
                                            <div className="tableCapitalCerto-title-inactive">PROTEÇÃO OUTROS ENCARGOS</div>
                                            </div>
                                        </div>
                                        <div className="tableCapitalCerto-amount">
                                            <div className="card-new-table-value">0 €</div>
                                            <div className="tableCapitalCerto-subtitle-unactive">Montante entregue</div>
                                        </div>
                                        <div className="tableCapitalCerto-amount">
                                            <div className="tableCapitalCerto-boldvalue">-</div>
                                            <div className="tableCapitalCerto-subtitle-unactive">Rendimento acumulado Bruto</div>
                                        </div>
                                            <div id="protection-create-subscription-button"
                                                onClick={this.createSubscription.bind(this, {
                                                /*"totalAmounts": {
                                                    "totalCurrentAmount": "20000.00",
                                                    "totalPaidAmount": "4.90"
                                                },*/
                                                "subscriptions": [
                                                    {
                                                        /*"currentAmount": "20000.00",
                                                        "paidAmount": "4.90",
                                                        "period": "M01",
                                                        "totalAmount": 4.9,
                                                        "contractNumber": "",
                                                        "periodDescription": "Mensal",*/
                                                        "associateID": null,
                                                        "associateNumber": null,
                                                        /*"subscriptionID": "717770254809",
                                                        "subscriptionNumber": "4",
                                                        "subscriptionDate": "2015-01-01T00:00:00",
                                                        "subscriptionEndDate": "2030-01-01T00:00:00",
                                                        "deadline": 15,*/
                                                        "state": "Pendente de inscrição"
                                                    }
                                                ],
                                                "productID": "7170", /*provavelmente somente isso aqui*/
                                                "productName": "PROTEÇÃO OUTROS ENCARGOS",
                                                "productGroup": "000002",
                                                "subscriptionsNumber": "1"
                                            })}>
                                        {/*<img src={require('../../images/newseries.png')} style={{ "cursor": "pointer" }} height="50px" width="50px"></img>Teste*/}
                                        {/*<div id="protectionSubscriptionMobile">*/}
                                            <img src={require('../../images/newseries.png')}></img>
                                        <div className="protection-create-subscription-button-text">Subscrever</div>
                                       {/* </div>*/}
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                    </Slider>
                </CarouselProvider>
            </div>
        )
        return content;
    }

    render() {
        return (
            <React.Fragment>
                {this.ProtectionCards()}
            </React.Fragment>
        );
    }
}
export default withRouter(ProtectionSubscription);