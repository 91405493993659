
/* COMPONENT IMPORTS*/

/* MODULE IMPORTS */
import React, { Component } from 'react';

/* SAME FOLDER IMPORTS*/
import MobileSidebar from './MobileSidebar';
import DesktopSidebar from './DesktopSidebar';

/* CSS IMPORT */
import './Sidebar.css';

export class Sidebar extends Component {
    static displayName = Sidebar.name;
    constructor(props) {
        super(props);
        this.state = {
            minimized: false
        };
    }

    render() {
        let closeMenuFunction = this.props.closeMenuFunction;
        let manager = this.props.manager;
        let hasManager = this.props.hasManager;
        let content = this.state.minimized ? <MobileSidebar /> : <DesktopSidebar closeMenuFunction={closeMenuFunction} manager={manager} hasManager={hasManager} />;
        let classes = this.props.className + " fixed-sidebar";        

        return (
            <div className={classes}>
                {content}
            </div>
        );
    }
}