/* MODULE IMPORTS */
import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class Musicaolicos extends Component {
    static displayName = Musicaolicos.name;

    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {
        return (
            <div className="popup-elections" style={{ height: "auto", margin: "auto", position:'relative' }}>
                <div>
                    <img
                        className="popup-primavera-img"
                        src={require("../../images/popup-image-musicaolicos.png")}
                        alt="Popup_Imag"
                    ></img>
                </div>
                <div className="popup-musicaolicos-title" style={{ textAlign: "center" }}>
                    Vamos juntos ao Vodafone <br/> Paredes de Coura?
        </div>
                
                <div className="popup-musicaolicos-title-mobile d-md-none" style={{ textAlign: "center" }}>
                    Vamos juntos ao Vodafone <br/> Paredes de Coura?
        </div>
                <br />
                <div className="popup-elections-text" style={{ textAlign: "center", fontWeight:'normal'}}>
                    Para receber 1 passe geral basta subscrever duas modalidades:<br />
                    uma de Poupança Complementar ou Poupança Reforma +<br />
                    uma modalidade à sua escolha. Caso opte por uma modalidade <br />
                    de Proteção, ainda não disponível em My Montepio, subscreva <br />
                    a modalidade de poupança e contacte o seu Gestor Mutualista,<br />
                    que garantirá a subscrição da segunda modalidade pretendida. <br />
                    Só poderá usufruir da oferta após subscrição das duas<br />
                    modalidades.<br />
                     
                    <br /><br />
                    Oferta limitada ao <em>stock</em> existente e à disponibilidade <br />
                    a cada momento.

                    </div>
                <div className="popup-elections-buttons" style={{ padding: "1%", margin: "1%", marginBottom: "2%" }}>
                    <div
                        onClick={() => {
                            this.props.close();
                        }}
                        style={{ cursor: "pointer" }}
                        className="popup-elections-fechar"
                    >
                        FECHAR
                    </div>
                    <NavLink
                        onClick={() => {
                            this.props.close();
                        }}

                        to="/poupanca"

                        style={{ color: "white" }}

                        className="popup-elections-votar">
                        QUERO
                    </NavLink>
                </div>
            </div>
        );
    
    } 
}
export default Musicaolicos;
