/* MODULE IMPORTS */
import React, { Component } from "react";
import authentication from "../../msalb2creact";

/* CSS IMPORT */
import "./erropg.css";

export class ErrorPage extends Component {
  static displayName = ErrorPage.name;

  constructor(props) {
    super(props);
    this.state = {
      endDate: "12/17/2021 18:05:00",
      startDate: "12/12/2021 23:55:00",
    };
  }

  render() {
    //Variavel de controlo para mostrar as listas existentes

    var today = new Date();

    var hr = today.getUTCHours();
    if (hr < 10) {
      hr = "0" + hr;
    }

    var min = today.getUTCMinutes();
    if (min < 10) {
      min = "0" + min;
    }

    var sec = today.getUTCSeconds();
    if (sec < 10) {
      sec = "0" + sec;
    }

    var time = hr + ":" + min + ":" + sec;

    var month = today.getUTCMonth() + 1;

    if (month < 10) {
      month = "0" + month;
    }
    var date = today.getUTCDate();
    if (date < 10) {
      date = "0" + date;
    }
    var year = today.getUTCFullYear();

    var date = month + "/" + date + "/" + year;

    var actualDate = date + " " + time;
    //console.log("dateTime");
    //console.log(actualDate);

    var isSemanaVotacao = false;

    if (actualDate && this.state.endDate && this.state.startDate) {
      var dateCheck = new Date(actualDate);
      var startSemanaVotacao = new Date(this.state.startDate);
      var endSemanaVotacao = new Date(this.state.endDate);

      isSemanaVotacao =
        dateCheck > startSemanaVotacao && dateCheck < endSemanaVotacao;
    }

    return (
      <div className="header">
        <header className="toolbar">
          <nav className="toolbar_navigation">
            <div className="column">
              <a href="/">
                <div className="toolbar_logo">
                  <img
                    src={require("./imgs/mymontepio-registo.svg")}
                    alt="MyMontepio"
                    width="164"
                    height="48"
                  ></img>
                </div>
              </a>
            </div>
            <div className="column-direita">
              <div className="toolbar_logo">
                <img
                  src={require("./imgs/Vector.png")}
                  alt="Montepio"
                  width="164"
                  height="48"
                ></img>
              </div>
            </div>
          </nav>
        </header>
        <div id="MainContainer" className="erro-geral">
          <div id="MyDivsuccess">
            <img id="imgerro" src={require("./imgs/erro.svg")} alt="erro"></img>
            <span>
              <div id="tituloerro" className="erro-geral"></div>
              <div id="conteudoerro" className="erro-geral">
                Dados inválidos. Por favor verifique se os dados introduzidos estão corretos.
                Para mais informações contacte o seu Gestor
                Mutualista ou a Linha de Apoio.
              </div>
              {/*<div id="duvidas1">Linha de apoio My Montepio</div>*/}
                        <div id="duvidas2">213 248 112</div>
                        <div id="duvidasG">(atendimento personalizado, dias úteis das 09h às 21h. Custo de chamada para a rede fixa nacional)</div>
              {/*<div id="duvidas3">*/}
              {/*  {isSemanaVotacao*/}
              {/*    ? "Dias úteis, das 9h00 às 00h00"*/}
              {/*    : "Dias úteis, das 9h00 às 21h00"}*/}
              {/*</div>*/}
              <div id="duvidas3">
                <a href="mailto:Suporte_MyMontepio@montepio.org" target="_top">
                  Suporte_MyMontepio@montepio.org
                </a>
              </div>
              <a href="/">
                <button id="btVoltar">Voltar à página inicial</button>
              </a>
            </span>
          </div>
        </div>
      </div>
    );
  }
}
