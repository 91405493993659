
/* COMPONENT IMPORTS*/
import authentication from "../msalb2creact";
import Home from "../pages/Home/Home";
import Profile from "../pages/Profile/Profile";
import Poupanca from "../pages/Poupanca/Poupanca";
import Protecao from "../pages/Protecao/Protecao";
import Beneficios from "../pages/Beneficios/Beneficios";
import AdicionaMenor from "../pages/AdicionaMenor/AdicionaMenor";
import Correio from "../pages/Correio/Correio";
import Eleicoes from "../pages/Eleicoes/Eleicoes";
import { BenefitsConfirmation } from "../components/BenefitsConfirmation/BenefitsConfirmation";
import { Subscricao } from "../components/Subscricao/Subscricao";
import { CartaoAssociado } from "../pages/CartaoAssociado/CartaoAssociado";
import { Notifications } from "../pages/Notifications/Notifications";
import { Fees } from "../pages/Fees/Fees";
import { MaioridadePage } from "./RepresentativeResponse/MaioridadePage";

/*import PopupContainer from './Popups/PopupContainer';*/

import ContextConstructor from "./Context/Context";

/* MODULE IMPORTS */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Route, Redirect } from "react-router-dom";

/* CSS IMPORT */
import "../custom.css";
import { Layout } from "../components/Layout"

class RouteRuler extends Component {
    static displayName = RouteRuler.name;

    render() {
        //console.log("RouteRuler context ", this.context)

        let Maioridade = this.context.selectedUser.age >= 18 ? true : false;
        //console.log("Maioridade ", Maioridade, " age ", this.context.selectedUser.age)


        let isRepresentado = this.context.selectedUser.associateId ? true : false;
        //console.log("isRepresentado ", isRepresentado, " associateId ", this.context.selectedUser.associateId)

        if (Maioridade === true && isRepresentado === true) {
            return (
                <React.Fragment>
                    <Route path="/MaioridadePage" component={authentication.required(MaioridadePage)} />
                    <Redirect to="/MaioridadePage" />
                    <Route
                        exact
                        path="/profile"
                        component={authentication.required(Profile)}
                    />
                    <Route
                        exact
                        path="/notificacoes"
                        component={authentication.required(Fees)} />
                    <Redirect to="/MaioridadePage" />
                </React.Fragment>
            );
        }
        /*this.context.selectedUser.userState == "CONDICIONADO" , this.context.selectedUser.hasQuotas === true*/
        else if (this.context.selectedUser.userState == "CONDICIONADO") {
            return (
                <React.Fragment>
                    <Route path="/fees" component={authentication.required(Fees)} />
                    <Redirect to="/fees" />
                    <Route
                        exact
                        path="/profile"
                        component={authentication.required(Profile)}
                    />
                    <Route
                        exact
                        path="/AdicionaMenor"
                        component={authentication.required(AdicionaMenor)}
                    />
                    <Route
                        exact
                        path="/notificacoes"
                        component={authentication.required(Fees)} />
                </React.Fragment>
            );
        }
        else if (this.context.selectedUser.userState == "NOTASSOCIATE" || this.context.selectedUser.status ==  "NATIVO") {
            return (
                <React.Fragment>
                    {/* <Route path="/AdicionaMenor" component={authentication.required(AdicionaMenor)} /> */}
                    <Route exact path="/" component={authentication.required(Home)} />
                    <Route
                        exact
                        path="/profile"
                        component={authentication.required(Profile)}
                    />
                    <Route
                        exact
                        path="/AdicionaMenor"
                        component={authentication.required(AdicionaMenor)}
                    />
                    <Route
                        exact
                        path="/notificacoes"
                        component={authentication.required(Notifications)} />
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    <Route exact path="/" component={authentication.required(Home)} />
                    <Route
                        exact
                        path="/profile"
                        component={authentication.required(Profile)}
                    />
                    <Route
                        exact
                        path="/poupanca"
                        component={authentication.required(Poupanca)}
                    />
                    <Route
                        exact
                        path="/protecao"
                        component={authentication.required(Protecao)}
                    />
                    <Route
                        exact
                        path="/beneficios"
                        component={authentication.required(Beneficios)}
                    />
                    <Route
                        exact
                        path="/benefitsconfirmation"
                        component={authentication.required(BenefitsConfirmation)}
                    />
                    <Route
                        exact
                        path="/subscricao"
                        component={authentication.required(Subscricao)}
                    />
                    <Route
                        exact
                        path="/cartaoassociado"
                        component={authentication.required(CartaoAssociado)}
                    />
                    <Route
                        exact
                        path="/fees"
                        component={authentication.required(Fees)}
                    />
                    <Route
                        exact
                        path="/notificacoes"
                        component={authentication.required(Notifications)}
                    />
                    <Route
                        exact
                        path="/correio"
                        component={authentication.required(Correio)}
                    />
                    <Route
                        exact
                        path="/AdicionaMenor"
                        component={authentication.required(AdicionaMenor)}
                    />
                    <Route
                        exact
                        path="/eleicoes"
                        component={authentication.required(Eleicoes)}
                    />
                </React.Fragment>
            );
        }

    }
}

//Add the context to the Component
RouteRuler.contextType = ContextConstructor;

export default withRouter(RouteRuler);
