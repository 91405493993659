
/* COMPONENT IMPORTS*/
import authentication from '../../msalb2creact';
/*import { Layout } from '../../components/Layout';*/  /*RETIRAR*/

/* MODULE IMPORTS */
import React, { Component } from 'react';

/* SAME FOLDER IMPORTS*/

/* CSS IMPORT */
import './BenefitsConfirmation.css'

export class BenefitsConfirmation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            benefitsURL: '/beneficios',
            checked: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.acceptBenefits = this.acceptBenefits.bind(this);
    }

    componentDidMount() {
        this.getAcceptedBenefits();
    }

    handleChange({ target }) {
        this.setState({ checked: target.checked });
    }

    render() {
        return (
            <div className="main">
                <div className="benefits-error container">
                    <div className="row">
                        <div className="col-sm-12 title">Programa Benefícios</div>
                    </div>
                    <div className="row">
                        <div className="col-sm-1 col-xs-12">
                            <label className="checkbox-container" onClick={this.handleCheck} >
                                <input type="checkbox"
                                    onClick={this.handleChange}
                                    checked={this.state.checked}
                                />
                                <span className="checkmark" ></span>
                            </label>
                        </div>

                        <div className="col-sm-11 col-xs-12 description">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna risus non cursus sodales viverra sed congue vitae. Quisque vitae a blandit elit pretium mollis pretium. Rhoncus tortor facilisi sit nam turpis. Habitant lorem pellentesque pulvinar pharetra vitae amet bibendum.
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 offset-sm-1 col-xs-12">
                            {this.state.checked ? <button type="button" disabled={!this.state.checked} onClick={this.acceptBenefits} >Confirmar</button> : null}
                        </div>
                    </div>
                </div>
                </div>
        );
    }

    async getAcceptedBenefits() {

        const headers = { Authorization: `Bearer ${authentication.getAccessToken()}` };
        //const response = await fetch("/Profile/CheckIfUserAcceptedBenefits", { headers });
        //const data = await response.json();
        //this.setState({ checked: data });
        //if (data)
        //    this.props.history.push(this.state.benefitsURL);

        await fetch("/Profile/CheckIfUserAcceptedBenefits", {
            headers
        })
            .then((response) => {
                //console.log("response CheckIfUserAcceptedBenefits 1 ", response);

                return response.json();
            })
            .then((response) => {
                //console.log("response.json CheckIfUserAcceptedBenefits ", response);

                this.setState({ checked: response });

                if (response)
                    this.props.history.push(this.state.benefitsURL);
            })
            .catch((error) => {
                //console.log("error CheckIfUserAcceptedBenefits ", error);
            });
    }

    async acceptBenefits() {

        const headers = { Authorization: `Bearer ${authentication.getAccessToken()}` };
        //const response = await fetch("/Profile/SetUserAcceptedBenefits", { headers });
        //const data = await response.json();
        //this.props.history.push(this.state.benefitsURL);

        await fetch("/Profile/SetUserAcceptedBenefits", {
            headers
        })
            .then((response) => {
                //console.log("response SetUserAcceptedBenefits ", response);

                return response.json();
            })
            .then((response) => {
                //console.log("response.json SetUserAcceptedBenefits ", response);

                this.props.history.push(this.state.benefitsURL);
            })
            .catch((error) => {
                console.log("error SetUserAcceptedBenefits ", error);
            });
    }

}