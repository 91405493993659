/* COMPONENT IMPORTS*/

/* MODULE IMPORTS */
import React, { Component } from "react";

/* CSS IMPORT */
import "./AdicionaMenor.css";

export default class NIFInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            associates: [],
            error: [],
            loading: true,
            associate: "",
            setassociateId: ""
        };
    }


    nifsimplevalidation = (event) => {
        //const keyCode = event.keyCode || event.which;
        //const keyValue = String.fromCharCode(keyCode);
        //if (!(new RegExp("^[0-9].{9}*$").test(keyValue)))
        //    event.preventDefault();

        /*return;*/
        //const inputValue = event.target.value.replace(/\D/g, ''); 
        //this.props.onChange(inputValue);
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        if (!(/^\d{0,9}$/.test(keyValue))) {
            document.getElementById('menor-nif').innerHTML = "NIF incorreto";
            alert('NIF incorreto');
        return;
    }


    }

    render() {
        return (
            <div>
                <label classname="Associate-Field" style={{color: '#FF5800'}}><b>NIF</b></label>
                <div classname="Associate-Same-Line-Fields">

                    <input type="text" class="menor-nif" classname="Associate-Fields" onKeyPress={this.nifsimplevalidation} minlength="9" maxlength="9" pattern="[0-9]{9}" placeholder="000000000"  />
                    {/* <input type="image" onClick={() => this.props.validaNif(this.props.id)} src={require('../../images/ArrowVotar.png')} style={{ background: 'none', border: 'none', marginBottom: '-5px', marginLeft: '5px', marginTop: 'auto' }} alt="valida" width="25" height="25" classname="Associate-Same-Line-Fields" /> */}
                    <input type="image" onClick={() => this.props.removeNif(this.props.id)} src={require('../../images/Bin.svg')} style={{ background: 'none', border: 'none', marginBottom: '-5px', marginLeft: '5px', marginTop: 'auto' }} alt="remove" width="25" height="25" classname="Associate-Same-Line-Fields" />
                </div>
            </div>
        );
    }
    
}{/*minlength="4" maxlength="9" size="10" pattern="[0-9]" */}