
/* COMPONENT IMPORTS*/
import { ErrorPage } from '../../components/ErrorPage/ErrorPage';
import authentication from '../../msalb2creact';
import NotificationBanner from '../../components/Notifications/NotificationBanner'
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
import Tabs from "../Profile/TabComponent/Tabs";

/* MODULE IMPORTS */
import React, { Component } from "react";

/* CSS IMPORT */
import "./Profile.css";

const browserHistory = createBrowserHistory({ basename: 'Profile Page' });
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: 'a20b3d0d-87b9-41e6-bcde-892a425215fe',
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();

    class Profile extends Component {
        static displayName = Profile.name;

        constructor(props) {
            super(props);
            this.state = {
                profile: [],
                error: [],
                loading: true
            };
        }

        componentDidMount() {
            this.populateProfileData();
        }

        static errorLoadingPortfolio() {
            return (
                <div>
                    <ErrorPage />
                </div>
            );
        }

        static renderProfileData(profile) {
            return (
                <div className="main">
                    <NotificationBanner page="profile" />
                    {/*descomentar para mostrar dados familiares, tema relacionado com RL <div className="Title">Dados pessoais e familiares</div>*/}
                    <div className="Title">Dados pessoais</div>
                    <div className="User-Fields">
                        <Tabs profile={profile} />
                    </div>
                </div>
            );
        }

        render() {
            let contents = this.state.loading ? (
                <div className="main-page-loading">
                    <div className="loading">
                        <img src={require('../../images/loading.svg')} alt="carregamento"></img>
                    </div>
                </div>
            ) : (
                    this.state.error ? Profile.errorLoadingPortfolio() : Profile.renderProfileData(this.state.profile)
            );

            return <div>{contents}</div>;
        }

        async populateProfileData() {
            const headers = { Authorization: `Bearer ${authentication.getAccessToken()}` };
            const response = await fetch("/Profile/GetProfile", { headers });
            if (response.status != "200") {
                this.setState({ error: true, loading: false });
                if (response.status === 204) appInsights.trackException({ exception: "Profile no content", severityLevel: 3 });
            }
            else {
                const data = await response.json();
                this.setState({ error: false, profile: data, loading: false });
            }
        }
    }
export default withAITracking(reactPlugin, Profile);
