/* MODULE IMPORTS */
import React, { Component } from "react";
import Tabs from "../../pages/Beneficios/Tabs";
import authentication from "../../msalb2creact";
import ContextConstructor from "../Context/Context";

/* CSS IMPORT */
import "./Sidebar.css";

class GestorPopup extends Component {
    static displayName = GestorPopup.name;

    constructor(props) {
        super(props);
        this.state = {
            active: "aTab",
            manhaSelected: true,
            tardeSelected: false,
            messageSuccess: false,
            contactSucess: false,
            contactError: false,
            messageContent: "",
            mobile: "",
            actualDate: null,
            startDate: null,
            endDate: null,
            isButtonDisabled: false

        };
    }

    componentDidMount() {
        //console.log("Contact Context: ", this.context);
        //this.GetVoteAuthorization();
    }

    customCheckBoxStructure() {
        var manhaBox = this.state.manhaSelected ? (
            <div className="radiobutton-montepio-selected radiobutton-font">
                <img
                    src={require("../../images/selected.png")}
                    height="26px"
                    width="26px"
                    alt="selecao"
                ></img>
                Manhã
            </div>
        ) : (
            <div
                className="radiobutton-montepio radiobutton-font"
                onClick={() => {
                    this.handleOptionChange();
                }}
            >
                <img
                    src={require("../../images/notselected.png")}
                    height="26px"
                    width="26px"
                    alt="não selecionado"
                ></img>
                Manhã
            </div>
        );
        var tardeBox = this.state.tardeSelected ? (
            <div
                className="radiobutton-montepio-selected radiobutton-font"
                style={{ marginLeft: "34px" }}
            >
                <img
                    src={require("../../images/selected.png")}
                    height="26px"
                    width="26px"
                    alt="selecionado"
                ></img>
                Tarde
            </div>
        ) : (
            <div
                className="radiobutton-montepio radiobutton-font"
                onClick={() => {
                    this.handleOptionChange();
                }}
                style={{ marginLeft: "34px" }}
            >
                <img
                    src={require("../../images/notselected.png")}
                    height="26px"
                    width="26px"
                    alt="não selecionado"
                ></img>
                Tarde
            </div>
        );

        return (
            <div
                className="contacto-select-boxes-wrapper"
                style={{ display: "inline-flex", marginBottom: "25px" }}
            >
                {manhaBox}
                {tardeBox}
            </div>
        );
    }

    handleOptionChange() {
        this.setState({
            manhaSelected: !this.state.manhaSelected,
            tardeSelected: !this.state.tardeSelected,
        });
    }

    sendContact(manager) {
        const email = this.props.hasManager ? manager.email : "";
        const number = this.props.hasManager ? manager.employeeNumber : "";
        const channel = this.state.active == "aTab" ? "Mensagem" : "Contacto";
        const content =
            this.state.active == "aTab"
                ? this.state.messageContent
                : this.state.manhaSelected
                    ? "manha"
                    : "tarde";
        this.sendContactRequest(number, channel, content, email);
        this.setState({ isButtonDisabled: true });
        setTimeout(async () => {
            this.setState({ isButtonDisabled: false });
        }, 10000);
    }

    handleChange(event) {
        this.setState({ messageContent: event.target.value });
    }

    tabContentsA() {
        return (
            <div className="contact-tab-div">
                <p>Para o seu Gestor Mutualista</p>
                <textarea
                    value={this.state.messageContent}
                    onChange={(event) => {
                        this.handleChange(event);
                    }}
                    placeholder="Escreva uma mensagem"
                />
                {this.state.messageContent.length > 0 && !this.state.isButtonDisabled ? (
                    <div
                        className={"button-orange-filled contact-orange-button"}
                        onClick={() => {
                            this.sendContact(this.props.manager);
                        }}
                        style={{ float: "right", marginTop: "40px" }}
                    >
                        Enviar
                    </div>
                ) : (
                    <div
                        className={"disabled-orange-filled contact-orange-button"}
                        style={{ float: "right", marginTop: "40px" }}
                    >
                        Enviar
                    </div>
                )}
            </div>
        );
    }

    tabContentsB() {
        return (
            <div className="contact-tab-div">
                <center>
                    <div
                        className="contact-window-maintext"
                        style={{ marginBottom: "10px" }}
                    >
                        Escolha o período em que deseja ser contactado pelo seu gestor.
                    </div>
                    <div className="contact-window-subtext">
                        Iremos entrar em contacto consigo dentro de 24 horas.
                    </div>
                    {this.customCheckBoxStructure()}
                    <center>
                        <div
                            className="button-orange-filled contact-confirm-button"
                            onClick={() => {
                                this.sendContact(this.props.manager);
                            }}
                        >
                            Confirmar
                        </div>
                    </center>
                </center>
            </div>
        );
    }

    messageSuccess() {
        return (
            <div className="success-message-div">
                <img
                    src={require("../../images/enviado.png")}
                    alt="enviado"
                    width="100px"
                    height="100px"
                ></img>
                <p>A sua mensagem foi enviada com sucesso</p>
            </div>
        );
    }

    contactSuccess(number) {
        return (
            <div className="success-message-div">
                <img
                    src={require("../../images/enviado.png")}
                    alt="enviado"
                    width="100px"
                    height="100px"
                ></img>
                <p>O seu pedido foi enviado</p>
                <p style={{ fontSize: "18px", fontWeight: "normal" }}>
                    Será contactado pelo seu gestor nas próximas 24h para o nº de telefone{" "}
                    {number}
                </p>
            </div>
        );
    }

    contactError() {
        //Variavel de controlo para mostrar as listas existentes

        var isSemanaVotacao = false;

        if (this.state.actualDate && this.state.endDate && this.state.startDate) {
            var dateCheck = new Date(this.state.actualDate);
            var startSemanaVotacao = new Date(this.state.startDate);
            var endSemanaVotacao = new Date(this.state.endDate);

            isSemanaVotacao =
                dateCheck > startSemanaVotacao && dateCheck < endSemanaVotacao;
        }

        return (
            <div className="success-message-div">
                <img
                    src={require("../../images/erro.png")}
                    alt="erro"
                    width="100px"
                    height="100px"
                ></img>
                <p style={{ padding: "0 100px" }}>
                    {isSemanaVotacao
                        ? "Queira contactar-nos para a Linha de Apoio através do Tel. 213 248 112 (atendimento personalizado, dias úteis das 09h às 21h. Custo de chamada para a rede fixa nacional)."
                        : "Queira contactar-nos para a Linha de Apoio através do Tel. 213 248 112 (atendimento personalizado, dias úteis das 09h às 21h. Custo de chamada para a rede fixa nacional)."}
                </p>
            </div>
        );
    }

    renderManagerCard(manager) {
        return (
            <section className="manager-popup-main">
                <img
                    src={require("../../images/Avatar_default.png")}
                    alt=""
                    width="120px"
                    height="120px"
                ></img>
                <div className="contact-popup-information">
                    <div className="contact-popup-name">{manager.name}</div>
                    <div className="contact-popup-phone">Gestor Mutualista</div>
                    <div className="contact-popup-phone">Tel: {manager.telephone}</div>
                    <div className="contact-popup-email">Email: {manager.email}</div>
                </div>
            </section>
        );
    }

    renderNoManagerCard() {
        var isSemanaVotacao = false;

        if (this.state.actualDate && this.state.endDate && this.state.startDate) {
            var dateCheck = new Date(this.state.actualDate);
            var startSemanaVotacao = new Date(this.state.startDate);
            var endSemanaVotacao = new Date(this.state.endDate);

            isSemanaVotacao =
                dateCheck > startSemanaVotacao && dateCheck < endSemanaVotacao;
        }

        return (
            <section className="manager-popup-main">
                <img
                    src={require("../../images/Avatar_default.png")}
                    alt=""
                    width="120px"
                    height="120px"
                ></img>
                <div className="contact-popup-information">
                    <div className="contactnomanager-popup-phone">
                        Linha de apoio ao Associado
                    </div>
                    <div className="contactnomanager-popup-phone">213 248 112</div>
                    <div className="contactnomanager-popup-phone">
                        {isSemanaVotacao
                            ? "Dias úteis, das 9h00 às 00h00"
                            : "Dias úteis, das 9h00 às 21h00"}
                    </div>
                    <div className="contactnomanager-popup-phone">
                        <a href="mailto:Suporte_MyMontepio@montepio.org" target="_top" style={{ "font-size": "16px" }}>
                            Suporte_MyMontepio@montepio.org
                        </a>
                    </div>
                </div>
            </section>
        );
    }

    contactCardContent(manager) {
        const content = {
            aTab: this.tabContentsA(manager),
            bTab: this.tabContentsB(manager),
        };
        return (
            <section>
                {this.props.hasManager
                    ? this.renderManagerCard(this.props.manager)
                    : this.renderNoManagerCard()}
                <div className="contact-tab">
                    <Tabs
                        active={this.state.active}
                        onChange={(active) => this.setState({ active })}
                    >
                        <div key="aTab">MENSAGEM</div>
                        <div key="bTab">CONTACTO</div>
                    </Tabs>
                </div>
                {content[this.state.active]}
            </section>
        );
    }

    renderContactCard() {
        const telephone = authentication.getUserTelephone();

        let totalContent = this.state.messageSuccess
            ? this.messageSuccess()
            : this.state.contactSuccess
                ? this.contactSuccess(telephone)
                : this.state.contactError
                    ? this.contactError()
                    : this.contactCardContent(this.props.manager);
        return (
            <div className="help-window help-window-contact">
                <div
                    className="popup-window-mobile-header"
                    style={{ margin: "20px 50px" }}
                >
                    <a style={{ float: "left" }} href="/">
                        <img
                            src={require("../../images/logoMobile.png")}
                            alt="logo"
                            height="45px"
                            className=""
                        ></img>
                    </a>
                    <img
                        className="popup-window-mobile-close"
                        src={require("../../images/closeIcon.png")}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            this.props.close();
                        }}
                        alt="Fechar"
                    />
                </div>
                <img
                    src={require("../../images/close.png")}
                    onClick={() => {
                        this.props.close();
                    }}
                    alt="Botão de fechar"
                    height="50px"
                    width="50px"
                    className="glossary-close contact-close"
                ></img>
                {totalContent}
            </div>
        );
    }

    render() {
        let popupContent = this.renderContactCard(this.props.manager);

        return <div className="contact-popup">{popupContent}</div>;
    }

    async sendContactRequest(number, channel, content, email) {
        try {
            //const userID = authentication.getUserID();
            const userID = (this.context.selectedUser.id) ? this.context.selectedUser.id : this.context.selectedUser.associateId;
            //console.log("userID: ", userID);

            const headersI = {
                Authorization: `Bearer ${authentication.getAccessToken()}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            };
            const response = await fetch("/Profile/CreateManagerContactRequest", {
                headers: headersI,
                method: "POST",
                body: JSON.stringify({
                    employeeNumber: number,
                    objectID: userID,
                    channel: channel,
                    date: new Date(),
                    content: content,
                    employeeEmail: email,
                }),
            });

            if (response.status === 200) {
                if (channel == "Mensagem") this.setState({ messageSuccess: true });
                else this.setState({ contactSuccess: true });
            } else this.setState({ contactError: true });
        } catch (err) {
            console.error(err);
        }
    }

    //async GetVoteAuthorization() {
    //  setTimeout(async () => {
    //    const atoken = authentication.getAccessToken();
    //    const headers = { Authorization: `Bearer ${atoken}` };
    //    const response = await fetch("/Elections/GetVoteAuthorization", {
    //      headers,
    //    });
    //    if (response.status !== 200) {
    //    } else {
    //      const data = await response.json();
    //      console.log("GetVoteAuthorization");
    //      console.log(data);

    //      this.setState({
    //        actualDate: data.actualDate,
    //        startDate: data.startDate,
    //        endDate: data.endDate,
    //      });
    //    }
    //  }, 0);
    //}
}
GestorPopup.contextType = ContextConstructor;

export default GestorPopup;
