import React, { Component } from "react";

const createDOMPurify = require('dompurify');
const { JSDOM } = require('jsdom');
const window = (new JSDOM('')).window;
const DOMPurify = createDOMPurify(window);

export class Advantages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      associateCard: this.props.data
    };
  }

  render() {
    return (
      <section className="advantages-section">
        <div className="advantages-div-2">
          <a className="div-2-title">{this.state.associateCard.header.title}</a>
          <div className="div-2-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.associateCard.header.description)}} />
        </div>
        <div className="advantages-div-3">
          <div className="advantages-image">
            <img src={this.state.associateCard.advantages[0].image} alt="" width="60px" height="60px"></img>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.associateCard.advantages[0].body)}}/>
          </div>
          <div className="advantages-image">
            <img src={this.state.associateCard.advantages[1].image} alt="" width="60px" height="60px"></img>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.associateCard.advantages[1].body)}}/>
          </div>
          <div className="advantages-image">
            <img src={this.state.associateCard.advantages[2].image} alt="" width="60px" height="60px"></img>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.associateCard.advantages[2].body)}}/>
          </div>
        </div>
      </section>
    );
  }
}
