
/* COMPONENT IMPORTS*/

/* MODULE IMPORTS */
import React, { Component } from "react";
import Popup from "reactjs-popup";
import authentication from '../../msalb2creact';
import { withRouter } from 'react-router';

/* SAME FOLDER IMPORTS*/

/* CSS IMPORT */
const createDOMPurify = require('dompurify');
const DOMPurify = createDOMPurify(window);


 class CurrentAccountPopUp extends Component {
    static displayName = CurrentAccountPopUp.name;

    constructor(props) {
        super(props);
        this.state = {
            glossarydownload: [],
            glossarycontent: [],
            hasGlossary: false,
            loading: true
        };
    }

     


    render() {
        let glossaryContent = this.props.CurrentAccountList;

        let CurrAcnt;

        CurrAcnt = glossaryContent.map((receivedAmount, index) =>

            index % 2 == 0 ?

            <tr>
                <td><div className="detalhe-conta-corrente">{new Intl.DateTimeFormat('pt-PT').format(new Date(receivedAmount.opDate))}</div></td>
                <td><div className="detalhe-conta-corrente">{receivedAmount.operation}</div></td>
                    <td><div className="detalhe-conta-corrente-valor">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(receivedAmount.opValue)}</div></td>
                </tr> :

                <tr className="conta-corrente-grey" >
                    <td><div className="detalhe-conta-corrente">{new Intl.DateTimeFormat('pt-PT').format(new Date(receivedAmount.opDate))}</div></td>
                    <td><div className="detalhe-conta-corrente">{receivedAmount.operation}</div></td>
                    <td><div className="detalhe-conta-corrente-valor">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(receivedAmount.opValue)}</div></td>
                </tr>
        )

        let content = 
            <Popup trigger={<img width="30px" height="30px" src={require('../../images/currentAccount.png')} alt="Botão de Ajuda" className="teste popup"></img>} contentStyle={{ "background": "none", "border": "none" }} modal closeOnDocumentClick>
                {close => <div className="help-window">
                    <img src={require('../../images/close.png')} alt="Botão de fechar" height="50px" width="50px" className="glossary-close" onClick={close}></img>
                    <div className="glossary-content">
                        <div className="sub-title">
                            Últimos movimentos
                    </div>
                        <div className="note-title">
                            Para aceder a movimentos mais antigos, consulte o seu Gestor Mutualista.
                    </div>
                        <table width="100%">
                            <tr className="conta-corrente-header" >
                                <td><div className="detalhe-conta-corrente-header">{"Data"}</div></td>
                                <td><div className="detalhe-conta-corrente-header">{"Operação"}</div></td>
                                <td><div className="detalhe-conta-corrente-valor">{"Valor (€)"}</div></td>
                            </tr>
                            {CurrAcnt}
                            </table>

                    </div>
                </div>
                }
            </Popup>
        
        return (
            <div>{content}</div>
        );
    }
}

export default withRouter(CurrentAccountPopUp);

