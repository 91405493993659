/* MODULE IMPORTS */
import React, { Component } from 'react';

/* CSS IMPORT */
import './erropg.css';

export class SuccessPwReset extends Component {
    static displayName = SuccessPwReset.name;

    render() {
        return (
            <div className="header">
                <header class="toolbar">
                    <nav class="toolbar_navigation">
                        <div class='column'>
                            <a href="/">
                                <div class="toolbar_logo">
                                    <img src={require('./imgs/mymontepio-registo.svg')} alt="MyMontepio" width="164" height="48"></img>
                                </div>
                            </a>
                        </div>
                        <div class='column-direita'>
                                <div class="toolbar_logo"><img src={require('./imgs/Vector.png')} alt="Montepio" width="164" height="48"></img>
                            </div>
                        </div>
                    </nav>
                </header>
                <div id="MainContainer">
                    <div id="MyDivsuccess">
                        <img id="imgerro" src={require('./imgs/success.png')} alt="success" />
                        <p id="tituloerro">A sua nova palavra-passe<br /> foi criada com sucesso</p>
                        <a href="/">
                            <button id="btVoltar" className="no-text-before">Aceda ao My Montepio</button>
                        </a>
                    </div>
                </div>                
            </div>
                );
    };
};