
/* COMPONENT IMPORTS*/
import { ErrorPage } from '../../components/ErrorPage/ErrorPage';
import authentication from '../../msalb2creact';
import ReactTable from 'react-table-v6';
import Pagination from "../Beneficios/Pagination.js";
import Collapsible from 'react-collapsible';
import ContextConstructor from "../../components/Context/Context";

/* MODULE IMPORTS */
import React, { Component } from "react";

/* CSS IMPORT */
import "./Notifications.css";
import { Loading } from '../../components/Loading/Loading';

export class Notifications extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            notificationsList: [],
            height: 0,
            width: 0,
            expanded: 0,
            open: false,
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    async componentDidMount() {        
        if (this.context.selectedUserID !== '' && this.context.selectedUserID !== undefined && this.context.selectedUserID !== null) {
            this.populateData();
            this.updateWindowDimensions();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
      }
      
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    expandRow(id){
        this.setState({
            expanded: id == this.state.expanded ? 0 : id
        })
    }

    renderMailListTable(notificationsList) {
        const columns = this.state.width <= 880 ? [
            {
                Header: '',
                accessor: 'isRead',
                minWidth: 30,
                maxWidth: 60,
                Cell: row => (
                        <img alt="" src={require("../../images/" + (row.value ? "notificationread" : "notificationnotread") +".svg")} />
                    )
            },
            {
                id: 'date',
                Header: '',
                Cell: ({original})  => (
                    <div>
                        <div className={original.isRead ? "mail-date read-notification" : "mail-date"}>
                            {new Intl.DateTimeFormat('pt-PT').format(new Date(original.date))}
                        </div>
                        <div className="mail-name"> 
                                <div className="notification-topic">{original.topic}{/*<span className="notification-description">{" - " + original.description}</span>*/}</div>
                        </div>
                        <div className={this.state.expanded === original.id ? "notification-description-under notification-expanded" : "notification-description-under"}>
                                {original.description}
                        </div>
                    </div>
                )
            },
            {
                Header: '',
                accessor: 'id',
                minWidth: 30,
                maxWidth: 60,     
                Cell: ({original}) =>     
                <div>    
                    <img style={{ "width": "14px", "cursor":"pointer" }} onClick={()=>{this.expandRow(original.id)}} src={require("../../images/" + (this.state.expanded == original.id ? "expand180" : "expand") + ".png")} alt="expandir" />                 
                </div> 
            },
        ] : [
            {
                id: 'isRead',
                Header: '',
                accessor: 'isRead',
                minWidth: 30,
                maxWidth: 60,
                Cell: row => (
                        <img alt="" src={require("../../images/" + (row.value ? "notificationread" : "notificationnotread") +".svg")} />
                    )
            },
            {
                id: 'date',
                Header: '',
                minWidth: 60,
                maxWidth: 90,
                className: 'mail-date-wrapper',
                Cell: ({original}) => (
                    <div className="mail-date">
                        {new Intl.DateTimeFormat('pt-PT').format(new Date(original.date))}
                    </div>
                )
            },
            {
                Header: '',
                Cell: ({original}) => (
                    <div>
                        <div className="mail-name"> 
                            <div className="notification-topic">{original.topic}{/*<span className="notification-description">{" - " + original.description}</span>*/}</div>
                        </div>
                        <div className={this.state.expanded === original.id ? "notification-description-under notification-expanded" : "notification-description-under"}>
                            {original.description}
                        </div>
                    </div>
                )
            },
            {
                Header: '',
                accessor: 'id',
                minWidth: 30,
                maxWidth: 60,
                Cell: ({original}) =>     
                    <div>    
                        <img style={{ "width": "14px", "cursor":"pointer" }} onClick={()=>{this.expandRow(original.id)}} src={require("../../images/" + (this.state.expanded == original.id ? "expand180" : "expand") + ".png")} alt="expandir" />                 
                    </div> 
            },
        ];

        return (
            <ReactTable
                data={notificationsList}
                columns={columns}
                resizable={false}
                nextText=">"
                previousText="<"
                sortable={false}
                minRows="0"
                PaginationComponent={Pagination}
                defaultPageSize={notificationsList.length <= 6 ? notificationsList.length : 6}
                getTrProps={(state, rowInfo) => 
            {
                return {
                    onClick: (e) => {
                        if (rowInfo.original.isRead !== true) {
                            //a resquest to the server is required to mark this mail as read
                            this.markNotificationAsRead(rowInfo, state);                 
                        }                            
                    },
                    style: {
                        padding: '12px 27px 12px 31px',
                        background: rowInfo ? (rowInfo.row.isRead ? 'rgba(255,255,255,0.3)' : 'white') : 'white',
                        color: rowInfo ? (rowInfo.row.isRead ? '#6D6E71' : '#373737') : '#373737',
                        cursor: 'pointer',
                        alignItems: this.state.expanded == rowInfo.row.id ? 'end' : 'center'
                    }
                }  
            }}
        />
        )
    }

    updateTableReadState(rowInfo, state) {
        //the current value will be updated without refresh
        state.data[rowInfo.index].isRead = true;

        this.setState({
            data: state.data,
            loading: false
        })
    }

    renderListagemCorreio(notificationsList) {
        return (
            <main className="main">
                <div className="title-bar title-bar-mail">
                <div className="title">Notificações</div>
                </div>
                <div className="notifications-list-wrapper">
                    <div className="mail-list">
                        {this.renderMailListTable(notificationsList)}
                    </div>
                </div>
            </main>
        );
    }

    renderListagemCorreioVazia() {
        return (
            <main className="main">
                <div className="no-mail-div">
                    <img className="no-mail-img" alt="" src={require('../../images/email.png')}></img>
                    <div className="no-mail-text">Não recebeu nenhuma notificação.</div>
                </div>
            </main>
        );
    }

    render() {
        let contents = "";

        if (this.state.loading) {
            contents = <Loading/>
        } else if (this.state.error) {
            contents = <ErrorPage/>
        } else if (this.state.notificationsList.length) {
            contents = this.renderListagemCorreio(this.state.notificationsList);
        } else {
            contents = this.renderListagemCorreioVazia();
        }

         return (
            <div>
                {contents}
            </div>
        );
    }

    async populateData() {
        try {
            var clientId = this.context.selectedUserID;
            //console.log("notifications c: ", clientId);

            const headers = {
                Authorization: `Bearer ${authentication.getAccessToken()}`,
                clientId: clientId,
            };

            const response = await fetch("/Notifications/GetNotifications?ClientId=" + clientId, { headers });

            if (response.status !== 200) {
                this.setState({ error: true, loading: false });
            } else {
                const data = await response.json();

                function objSort() {
                    var args = arguments,
                        array = args[0],
                        case_sensitive, keys_length, key, desc, a, b, i;
                
                    if (typeof arguments[arguments.length - 1] === 'boolean') {
                        case_sensitive = arguments[arguments.length - 1];
                        keys_length = arguments.length - 1;
                    } else {
                        case_sensitive = false;
                        keys_length = arguments.length;
                    }
                
                    return array.sort(function (obj1, obj2) {
                        for (i = 1; i < keys_length; i++) {
                            key = args[i];
                            if (typeof key !== 'string') {
                                desc = key[1];
                                key = key[0];
                                a = obj1[args[i][0]];
                                b = obj2[args[i][0]];
                            } else {
                                desc = false;
                                a = obj1[args[i]];
                                b = obj2[args[i]];
                            }
                
                            if (case_sensitive === false && typeof a === 'string') {
                                a = a.toLowerCase();
                                b = b.toLowerCase();
                            }
                
                            if (! desc) {
                                if (a < b) return -1;
                                if (a > b) return 1;
                            } else {
                                if (a > b) return -1;
                                if (a < b) return 1;
                            }
                        }
                        return 0;
                    });
                }

                this.setState({
                    notificationsList: objSort(data,'isRead',['date',true]),
                    error: false,
                    loading: false
                });
            }
        } catch(err) {
            console.error(err);
        }
    }

    async markNotificationAsRead(rowInfo, state) {
        //try {
        //    const headers = { Authorization: `Bearer ${authentication.getAccessToken()}`, 'Accept': 'application/json', 'Content-Type': 'application/json' };
        //    const response = await fetch("/Notifications/MarkNotificationAsRead", {
        //        headers,
        //        method: "POST",
        //        body: JSON.stringify({
        //            id: rowInfo.original.id
        //        })
        //    });
        //    await fetch("/Notifications/DismissTopNotification", {
        //        headers,
        //        method: "POST",
        //        body: JSON.stringify({
        //            id: rowInfo.original.id
        //        })
        //    });
        //    const data = await response.json();
        //    if (data === true) {
        //        this.updateTableReadState(rowInfo, state);
        //    }
        //} catch (err) {
        //    console.error(err);
        //}

        try {
            const headers = {
                Authorization: `Bearer ${authentication.getAccessToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };

            //const response = await fetch("/Notifications/MarkNotificationAsRead", {
            const response = await fetch("/Notifications/MarkNotificationAsRead?Id=" + rowInfo.original.id, {
                headers,
                method: "POST",
                //body: JSON.stringify({
                //    id: rowInfo.original.id,
                //})
            });

            //await fetch("/Notifications/DismissTopNotification", {
            await fetch("/Notifications/DismissTopNotification?Id=" + rowInfo.original.id, {
                headers,
                method: "POST",
                //body: JSON.stringify({
                //    id: rowInfo.original.id,
                //})
            });
            const data = await response.json();
            if (data === true) {
                this.updateTableReadState(rowInfo, state);
            }
        } catch (err) {
            console.error(err);
        }
    }
}

Notifications.contextType = ContextConstructor;

export default Notifications;
