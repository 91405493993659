
/* COMPONENT IMPORTS*/

/* MODULE IMPORTS */
import React, { Component } from "react";
import Popup from "reactjs-popup";
import authentication from '../../msalb2creact';
import { withRouter } from 'react-router';


/* SAME FOLDER IMPORTS*/

/* CSS IMPORT */
const createDOMPurify = require('dompurify');
const DOMPurify = createDOMPurify(window);


 class CurrentAccountPopUpMobile extends Component {
    static displayName = CurrentAccountPopUpMobile.name;

    constructor(props) {
        super(props);
        this.state = {
            glossarydownload: [],
            glossarycontent: [],
            hasGlossary: false,
            loading: true
        };
    }

     


    render() {
        let glossaryContent = this.props.CurrentAccountList;

        let CurrAcnt;

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        var ddy = String(yesterday.getDate()).padStart(2, '0');
        var mmy = String(yesterday.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyyy = yesterday.getFullYear();

        yesterday = ddy + '/' + mmy + '/' + yyyyy;


        CurrAcnt = glossaryContent.map((receivedAmount, index) =>

            new Intl.DateTimeFormat('pt-PT').format(new Date(receivedAmount.opDate)) == today ? 

            <table width="100%" >
                <tr border="0" cellpadding="0" cellspacing="0" class="border" className="date-current-account-mobile-bar">
                    <td> <div className="data-conta-corrente-mobile">{"Hoje"}</div></td>
                    <td><div className="detalhe-conta-corrente-valor">{}</div></td>
            </tr>

                <tr className="operations-current-account-mobile-bar">
                <td><div className="detalhe-conta-corrente">{receivedAmount.operation}</div></td>
           
                <td><div className="detalhe-conta-corrente-valor">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(receivedAmount.opValue)}</div></td>
             </tr> 
                </table> :

                new Intl.DateTimeFormat('pt-PT').format(new Date(receivedAmount.opDate)) == yesterday ?

                    <table width="100%" >
                        <tr border="0" cellpadding="0" cellspacing="0" class="border" className="date-current-account-mobile-bar">
                            <td> <div className="data-conta-corrente-mobile">{"Ontem"}</div></td>
                            <td><div className="detalhe-conta-corrente-valor">{}</div></td>
                        </tr>

                        <tr className="operations-current-account-mobile-bar">
                            <td><div className="detalhe-conta-corrente">{receivedAmount.operation}</div></td>

                            <td><div className="detalhe-conta-corrente-valor">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(receivedAmount.opValue)}</div></td>
                        </tr>
                    </table> :

                    <table width="100%" >
                        <tr border="0" cellpadding="0" cellspacing="0" class="border" className="date-current-account-mobile-bar">
                            <td> <div className="data-conta-corrente-mobile">{new Date(receivedAmount.opDate).getMonth() == 0 ? new Date(receivedAmount.opDate).getDate() + " de janeiro " + new Date(receivedAmount.opDate).getFullYear() :
                                                                                 new Date(receivedAmount.opDate).getMonth() == 1 ? new Date(receivedAmount.opDate).getDate() + " de fevereiro " + new Date(receivedAmount.opDate).getFullYear() :
                                                                                    new Date(receivedAmount.opDate).getMonth() == 2 ? new Date(receivedAmount.opDate).getDate() + " de março " + new Date(receivedAmount.opDate).getFullYear() :
                                                                                        new Date(receivedAmount.opDate).getMonth() == 3 ? new Date(receivedAmount.opDate).getDate() + " de abril " + new Date(receivedAmount.opDate).getFullYear() :
                                                                                            new Date(receivedAmount.opDate).getMonth() == 4 ? new Date(receivedAmount.opDate).getDate() + " de maio " + new Date(receivedAmount.opDate).getFullYear() :
                                                                                                new Date(receivedAmount.opDate).getMonth() == 5 ? new Date(receivedAmount.opDate).getDate() + " de junho " + new Date(receivedAmount.opDate).getFullYear() :
                                                                                                    new Date(receivedAmount.opDate).getMonth() == 6 ? new Date(receivedAmount.opDate).getDate() + " de julho " + new Date(receivedAmount.opDate).getFullYear() :
                                                                                                        new Date(receivedAmount.opDate).getMonth() == 7 ? new Date(receivedAmount.opDate).getDate() + " de agosto " + new Date(receivedAmount.opDate).getFullYear() :
                                                                                                            new Date(receivedAmount.opDate).getMonth() == 8 ? new Date(receivedAmount.opDate).getDate() + " de setembro " + new Date(receivedAmount.opDate).getFullYear() :
                                                                                                                new Date(receivedAmount.opDate).getMonth() == 9 ? new Date(receivedAmount.opDate).getDate() + " de outubro " + new Date(receivedAmount.opDate).getFullYear() :
                                                                                                                    new Date(receivedAmount.opDate).getMonth() == 10 ? new Date(receivedAmount.opDate).getDate() + " de novembro " + new Date(receivedAmount.opDate).getFullYear() :
                                                                                                                        new Date(receivedAmount.opDate).getMonth() == 11 ? new Date(receivedAmount.opDate).getDate() + " de dezembro " + new Date(receivedAmount.opDate).getFullYear(): null}</div></td>
                            <td><div className="detalhe-conta-corrente-valor">{}</div></td>
                        </tr>

                        <tr className="operations-current-account-mobile-bar">
                            <td><div className="detalhe-conta-corrente">{receivedAmount.operation}</div></td>

                            <td><div className="detalhe-conta-corrente-valor">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(receivedAmount.opValue)}</div></td>
                        </tr>
                    </table>
        )

        let content =
            <Popup trigger={<img width="30px" height="30px" src={require('../../images/currentAccount.png')} alt="Botão de Ajuda" style={{marginLeft:"15px"}} className="popup"></img>} contentStyle={{ "background": "none", "border": "none" }} modal closeOnDocumentClick>
                {close => <div className="help-window">
                    <img src={require('../../images/close.png')} alt="Botão de fechar" height="50px" width="50px" className="glossary-close" onClick={close}></img>
                    <div className="glossary-content">
                        <div className="sub-title_mobile">
                            Últimos movimentos
                    </div>
                        <div className="note-title_mobile">
                            Para aceder a movimentos mais antigos, consulte o seu Gestor Mutualista.
                    </div>
                       
                            {CurrAcnt}
                            

                    </div>
                </div>
                }
            </Popup>
        
        return (
            <div>{content}</div>
        );
    }
}

export default withRouter(CurrentAccountPopUpMobile);

