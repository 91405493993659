
/* COMPONENT IMPORTS*/
import DesktopNavbar from './Navbar/DesktopNavbar';
import { Sidebar } from './Sidebar/Sidebar';

import ContextConstructor from "./Context/Context";

/* MODULE IMPORTS */
import { Container } from 'reactstrap';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

/*import PopupContainer from './Popups/PopupContainer';*/

class Layout extends Component {
    static displayName = Layout.name;

    //static contextType = ContextConstructor;

    constructor(props) {
        super(props);
    }

    state = {
        isCallingApi: true,
    }

    async componentWillMount() {
        await this.populateAssociateData();
    }

    render() {

        //console.log("window ",window.location.pathname)

        //console.log("isAuthenticated ", this.props.auth.getStateVariables())
        //console.log("Layout props ", this.props);
        //console.log("contextType ", this.context)

        if (this.props.auth.isAuthenticated()) {
            //Verifica se é o menu "home"
            let classeDiv = this.props.location.pathname === "/" ? "with-background-image" : "";
            let classBeneficiosDiv = this.props.location.pathname === "/beneficios" ? "beneficios-container" : this.props.location.pathname === "/cartaoassociado" ? "beneficios-container" : "";

            return (
                <div className={classeDiv}>
                    <DesktopNavbar />
                    <Sidebar className="fixed-sidebar" />
                    <Container className={classBeneficiosDiv}>
                        {this.props.children}
                    </Container>
                </div>
            );
        }
        else {
            return (
                <div>
                    <Container>
                        {this.props.children}
                    </Container>
                </div>
            );
        }
    }

    //Get Menor Associate information from MYM_CORE
    async populateAssociateData() {
        await new Promise((r) => { setTimeout(r, 1) })

        if (this.props.auth.getAccessToken()) {
            const headers = {
                Authorization: `Bearer ${this.props.auth.getAccessToken()}`,
            };
            const response = await fetch("/Profile/GetMymAssociates", { headers });

            if (response.status != 200) {
                this.setState({ loading: false });
            }
            else {
                const data = await response.json();
                // Adicionar User do token dentro do Array de Associados!!!!!!

                fetch("/Profile/GetProfile", {
                    method: 'GET',
                    headers
                }).then((response) => {

                    //console.log("response json ", response);

                    // if response was 200 we convert the response to json
                    // else we throw the response to the catch
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw response;
                    };

                }).then((userProfileResponse) => {
                    //console.log("response profile", userProfileResponse);

                    data.mymAssociates.push(userProfileResponse);

                    var selectedUserClientID = '';

                    data.mymAssociates.map((associated) => {

                        if (associated.clientID === userProfileResponse.clientID) {
                            associated.isTokenUser = true;
                            selectedUserClientID = userProfileResponse.clientID;
                        } else {
                            associated.isTokenUser = false;
                        };
                    });

                    //Update the Context based on selected User to display
                    this.context.contextInit(data.mymAssociates, selectedUserClientID, userProfileResponse.clientID, userProfileResponse);
                })
            }
        }

    }
}

//Add the context to the Component
Layout.contextType = ContextConstructor;

export default withRouter(Layout);
