
import React from 'react'
import "./loading.css";

export const Loading = () => {
    return (
        <div className="main-page-loading">
            <div className="loading">
                <img src={require('../../images/loading.svg')} alt="Imagem de carregamento da página"></img>
            </div>
        </div>
    )
};

