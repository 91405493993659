import React, { Component } from "react";

export class BenefitsSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            benefitsValue: this.props.benefitValue
        };
    }

    render() {
        return (
            <div className="beneficios">
                <div className="benificios-card" >
                    <img src={require('../../images/cartao-exemplo.png')} alt="Imagem exemplo de um cartão de associado" className="benificios-card-img"></img>
                </div>
                <div className="table-benefits">
                    <div className="table-title">Benefícios</div>
                    <div className="down-group">
                        <div className="table-img">
                            <img src={require('../../images/poupado.png')} alt=""></img>
                        </div>
                        <div>
                            <div className="table-gain">
                                <div>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(this.state.benefitsValue)}</div>
                            </div>
                            <div className="table-description">Valor que já poupou por utilizar o Programa de Benefícios</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
