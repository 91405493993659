
/* COMPONENT IMPORTS*/

/* MODULE IMPORTS */
import React, { Component } from 'react';

/* SAME FOLDER IMPORTS*/

/* CSS IMPORT */


class DownloadList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listFiles: this.props.files
        };
    }
    render() {
        return (
            <div className="row">
                <div className="col-sm-12 download-title">
                    Downloads
                </div>          
                <React.Fragment>
                    {this.state.listFiles.length > 0 ? (
                        this.state.listFiles.map((document, index) =>
                            <div key={index} className="row download-container">
                                <div className="imgcenter" >
                                    <a href={document.file} target="_blank" rel="noopener noreferrer">
                                        <div className="documentIcon" />
                                    </a>
                                </div>
                                <div className="download-container-text" >
                                    <div className="title-productdetails">
                                        {document.text}
                                    </div>
                                </div>
                            </div>
                        )
                    ) : (
                            <p>Nenhum documento a apresentar</p>
                        )
                    }
                </React.Fragment>
            </div>
        );
    }
}

export default DownloadList;