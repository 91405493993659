/* COMPONENT IMPORTS*/
import "moment/locale/en-gb";
import "moment/locale/zh-cn";
import "rc-calendar/assets/index.css";
import pt_PT from "rc-calendar/lib/locale/pt_PT";
import RangeCalendar from "rc-calendar/lib/RangeCalendar";
import "rc-time-picker/assets/index.css";
import TimePickerPanel from "rc-time-picker/lib/Panel";
import React, { Component } from "react";
/*import Collapsible from "react-collapsible";*/ /*RETIRAR*/
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import BenefitsPie from "../../components/BenefitsPie/BenefitsPie";
import NotificationBanner from "../../components/Notifications/NotificationBanner";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import ContextConstructor from "../../components/Context/Context";

/* MODULE IMPORTS */
import ReactTable from "react-table-v6";
import Popup from "reactjs-popup";
import { ErrorPage } from "../../components/ErrorPage/ErrorPage";
import authentication from "../../msalb2creact";
/* CSS IMPORT */
import "./Beneficios.css";
import "./CustomReactTable.css";
import "./DateFilter.css";
import "./Pagination.css";
import "moment/locale/pt";
/* SAME FOLDER IMPORTS*/
import Pagination from "./Pagination.js";
import Tabs from "./Tabs.js";
var moment = require("moment");

const pageid = "14598";
moment.locale("pt");

const now = moment();

now.utcOffset(0);

const timePickerElement = (
  <TimePickerPanel
    defaultValue={[
      moment("00:00:00", "HH:mm:ss"),
      moment("23:59:59", "HH:mm:ss"),
    ]}
  />
);

const formatStr = "DD/MM/YYYY";

function format(v) {
  return v ? v.format(formatStr) : "";
}

const browserHistory = createBrowserHistory({ basename: "Benefits Page" });
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: "a20b3d0d-87b9-41e6-bcde-892a425215fe",
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();

export class Beneficios extends Component {
  constructor(props) {
    super(props);
    this.state = {
          loading: true,
          error: [],
          active: "aTab",
          hasBenefits: false,
          benefits: [],
          DateString: [],
          DateStringValue: "",
          DateStart: new Date(),
          DateEnd: new Date(),
          DateStartCalendar: new Date(),
          DateEndCalendar: new Date(),
          Sort: "1",
          filtered: [],
          select2: undefined,
          sortSelect: "Maior Poupança",
          graphYearSelected: "Total",
          graphDataDisplay: null,
          graphTotalDisplay: null,
          graphYears: [],
          toggleActive: false,
      };

    this.filterInput = React.createRef();
    this.onStandaloneSelect = this.onStandaloneSelect.bind(this);
  }

  handleChangeDropdown = (event) => {
    switch (event.target.value) {
      case "updown":
        this.setState({ Sort: "1" });
        break;
      case "downup":
        this.setState({ Sort: "2" });
        break;
      case "a-z":
        this.setState({ Sort: "3" });
        break;
      case "z-a":
        this.setState({ Sort: "4" });
        break;
    }
  };

    componentDidMount() {
        //console.log("Programa beneficios context ", this.context)

        if (this.context.selectedUserID !== '' && this.context.selectedUserID !== undefined && this.context.selectedUserID !== null) {
            this.populateBenefitsData();
        }
    }

  errorLoadingPortfolio() {
    return (
      <div>
        <ErrorPage />
      </div>
    );
  }

  onFilteredChangeCustom = (value, accessor) => {
    let filtered = this.state.filtered;
    let insertNewFilter = 1;

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }

    this.setState({ filtered: filtered });
  };

  notfound = (
    <React.Fragment>
      <img
        src={require("../../images/poupado.png")}
        className="noDataImg"
        width="61px"
        height="60px"
        alt="erro"
      />
      <div className="noDataText">
        Estamos a recolher informação sobre os seus benefícios.
      </div>
      <div className="noDataText">Disponibilizá-la-emos em breve.</div>
    </React.Fragment>
  );

  async applyDateFilter() {
    this.onFilteredChangeCustom(this.state.DateStart, "transactionDate");
    this.setState({
      DateString: this.state.DateStringValue,
      DateStart: this.state.DateStartCalendar,
      DateEnd: this.state.DateEndCalendar,
    });
  }

  async onStandaloneSelect(value) {
    this.setState({
      DateStringValue: format(value[0]) + " - " + format(value[1]),
      DateStartCalendar: new Date(value[0]),
      DateEndCalendar: new Date(value[1]),
    });
  }

  onChange = (value) => {
    this.setState({ value });
  };

  onHoverChange = (hoverValue) => {
    this.setState({ hoverValue });
  };

  resetFilter = (benefits) => {
    if (benefits.benefitsAll.length > 0) {
      let a =
        new Intl.DateTimeFormat("pt-PT").format(
          new Date(benefits.benefitsAll[0].transactionDate)
        ) +
        " - " +
        new Intl.DateTimeFormat("pt-PT").format(new Date());
      let b = new Date("01/01/1000");
      let c = new Date("01/01/4000");
      this.setState({
        DateString: a,
        DateStart: b,
        DateEnd: c,
      });
      this.onFilteredChangeCustom(this.state.DateStart, "transactionDate");
    }
  };

  dropdownDate() {
    return (
      <div className="dropdown-greyarea">
        <span className="dropdown-date">{this.state.Date}</span>
        <img
          src={require("../../images/expand.png")}
          width="14px"
          height="14px"
          alt="data"
        ></img>
      </div>
    );
  }

  tabContentsA(benefits) {
    const listBenefitsA = benefits.benefitsAll;
    const listBenefitsACount =
      listBenefitsA === undefined || listBenefitsA.length >= 10
        ? "10"
        : listBenefitsA.length == 0
        ? "1"
        : listBenefitsA.length;
    const columnsA = [
      {
        Header: "Data",
        headerClassName: "tableheader",
        headerStyle: {
          textAlign: "left",
          paddingLeft: "10px",
          borderCollapse: "collapse",
          borderBottom: "2px solid #595959",
          background: "white",
        },
        accessor: "transactionDate",
        minWidth: 100,
        maxWidth: 160,
        filterMethod: (filter, row) => {
          let a = moment(row[filter.id]).toDate();
          let b = a.getTime();
          if (
            this.state.DateEnd.getTime() === null ||
            this.state.DateStart.getTime() === null
          ) {
            return false;
          }
          if (
            b >= this.state.DateStart.getTime() &&
            b <= this.state.DateEnd.getTime()
          ) {
            return row[filter.id];
          }
        },
        Cell: (row) => (
          <div
            className="data-beneficio"
            style={{
              fontFamily: "Open Sans",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "16px",
              lineHeight: "160 %",
              fontFeatureSettings: "'pnum' on, 'lnum' on",
              color: "#6D6F71",
              textAlign: "left",
              paddingLeft: "4px",
            }}
          >
            {new Intl.DateTimeFormat("pt-PT").format(new Date(row.value))}
          </div>
        ),
      },
      {
        Header: "Entidade",
        headerClassName: "tableheader tableheader-parceiro",
        headerStyle: {
          textAlign: "left",
          borderCollapse: "collapse",
          borderBottom: "2px solid #595959",
          background: "white",
        },
        accessor: "partner",
        minWidth: 120,
        maxWidth: 400,
        Cell: (row) => (
          <div className="image-cropper-wrapper">
            <div className="image-cropper divsidebyside">
              <img height={50} src={row.value.imageURL} alt={row.value.name} />
            </div>
            <div className="parceiro-name">{row.value.name}</div>
          </div>
        ),
      },
      {
        Header: "Montante Poupado",
        headerClassName: "tableheader tableheader-desktop",
        headerStyle: {
          textAlign: "right",
          paddingRight: "10px",
          borderCollapse: "collapse",
          borderBottom: "2px solid #595959",
          background: "white",
        },
        accessor: "savedValue",
        minWidth: 120,
        maxWidth: 150,
        className: "montante-desktop",
        Cell: (row) => (
          <div className="savedValue-col">
            {" "}
            {new Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
            }).format(row.value)}
          </div>
        ),
      },
      {
        Header: "Montante",
        headerClassName: "tableheader tableheader-mobile",
        headerStyle: {
          textAlign: "right",
          paddingRight: "10px",
          borderCollapse: "collapse",
          borderBottom: "2px solid #595959",
          background: "white",
        },
        accessor: "savedValue",
        minWidth: 120,
        maxWidth: 150,
        className: "montante-mobile",
        Cell: (row) => (
          <div className="savedValue-col">
            {" "}
            {new Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
            }).format(row.value)}
          </div>
        ),
      },
    ];

    return (
      <div id="ByDate" className="tabcontent">
        <div className="divfilter">
          <div className="filter-information visible-under-500">
            <table className="filter-information visible-under-500">
              <tbody>
                <tr>
                  <th className="filter-information-title">Já poupou</th>
                </tr>
                <tr>
                  <td className="filter-information-value">
                    {new Intl.NumberFormat("de-DE", {
                      style: "currency",
                      currency: "EUR",
                    }).format(benefits.totalBenefits)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="filter-dropdown">
            <div className="dropdown-title">Ver Período</div>
            <Popup
              trigger={() => (
                <div className="dropdown-greyarea">
                  <span className="dropdown-date">{this.state.DateString}</span>
                  <img
                    src={require("../../images/expand.png")}
                    width="14px"
                    height="14px"
                    alt=""
                  ></img>
                </div>
              )}
              contentStyle={{
                background: "none",
                border: "none",
                boxShadow: "none",
              }}
              position="bottom center"
              offsetX={-25}
              arrow={false}
              overlayStyle={{
                background: "none",
              }}
            >
                        {(close) => (
                            <div className="dropdown-popup" style={{ zIndex:  "1"}}>
                  <div className="popup-window-mobile-header">
                    <a style={{ float: "left" }} href="/">
                      <img
                        src={require("../../images/logoMobile.png")}
                        alt="logo"
                        height="45px"
                        className=""
                      ></img>
                    </a>
                    <img
                      className="popup-window-mobile-close"
                      src={require("../../images/closeIcon.png")}
                      alt="Fechar"
                      onClick={() => {
                        this.resetFilter(this.state.benefits);
                        close();
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </div>

                  <RangeCalendar
                    showToday={false}
                    showWeekNumber={false}
                    showDateInput={false}
                    filterable
                    dateInputPlaceholder={["start", "end"]}
                    locale={pt_PT}
                    style={{
                      borderRadious: "0px",
                      boxShadow: "none",
                      border: "none",
                    }}
                    showOk={false}
                    format={formatStr}
                    onSelect={this.onStandaloneSelect}
                    timePicker={timePickerElement}
                  />
                  <div className="data-buttons">
                    <button
                      className="cancelar-data-button"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.resetFilter(this.state.benefits);
                        close();
                      }}
                      height="40px"
                      width="165px"
                    >
                      Cancelar
                    </button>
                    <div
                      className="selecionar-data-button"
                      onClick={() => {
                        this.applyDateFilter();
                        close();
                      }}
                    >
                      Selecionar
                    </div>
                  </div>
                </div>
              )}
            </Popup>
          </div>
          <div className="filter-information">
            <table className="filter-information">
              <tbody>
                <tr>
                  <th className="badge-wrapper" rowSpan="2">
                    <img height={48} src={require("../../images/Badge.png")} alt=""/>
                  </th>
                  <th className="filter-information-title">Já poupou</th>
                </tr>
                <tr>
                  <td className="filter-information-value">
                    {new Intl.NumberFormat("de-DE", {
                      style: "currency",
                      currency: "EUR",
                    }).format(benefits.totalBenefits)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <ReactTable
          columns={columnsA}
          data={listBenefitsA}
          className="tableStyle"
          resizable={false}
          pageSize={listBenefitsACount}
          nextText=">"
          previousText="<"
          sortable={false}
          minRows="0"
          onFilteredChange={(filtered, column, value) => {
            this.onFilteredChangeCustom(value, column.id || column.accessor);
          }}
          defaultFilterMethod={(filter, row, column) => {
            const id = filter.pivotId || filter.id;
            return row[id] !== undefined
              ? String(row[id]).startsWith(filter.value)
              : true;
          }}
          filtered={this.state.filtered}
          showPagination={this.state.hasBenefits}
          noDataText={this.notfound}
          PaginationComponent={Pagination}
        />
      </div>
    );
  }

  setSort(value) {
    var a;
    switch (value) {
      case "1":
        a = "Maior Poupança";
        break;
      case "2":
        a = "Menor Poupança";
        break;
      case "3":
        a = "Ordem Alfabética A-Z";
        break;
      case "4":
        a = "Ordem Alfabética Z-A";
        break;
    }
    this.setState({
      Sort: value,
      sortSelect: a,
    });
  }

  tabContentsB(benefits) {
    const listBenefitsB = benefits.benefitsByPartner;
    const listBenefitsBCount =
      listBenefitsB === undefined || listBenefitsB.length >= 10
        ? "10"
        : listBenefitsB.length == 0
        ? "1"
        : listBenefitsB.length;
    const columnsB = [
      {
        Header: "Entidade",
        id: "partnerimg",
        headerClassName: "tableheader",
        headerStyle: {
          textAlign: "left",
          paddingLeft: "10px",
          borderCollapse: "collapse",
          borderBottom: "2px solid #595959",
          background: "white",
        },
        minWidth: 50,
        maxWidth: 90,
        accessor: "partner",
        Cell: (row) => (
          <div className="image-cropper divsidebyside">
            <img height={50} src={row.value.imageURL} alt={row.value.name} />
          </div>
        ),
      },
      {
        Header: "-",
        id: "partnersort",
        headerClassName: "tableheader",
        headerStyle: {
          textAlign: "left",
          paddingRight: "10px",
          borderCollapse: "collapse",
          borderBottom: "2px solid #595959",
          background: "white",
          color: "white",
        },
        accessor: "partner",
        Cell: (row) => (
          <div>
            <div
              className="parceiro-name"
              style={{
                fontFamily: "rawline",
                fontWeight: "600",
                fontSize: "16px",
                lineHeight: "160 %",
                color: "#6D6F71",
                textAlign: "left",
                paddingTop: "15px",
                paddingLeft: "0px",
              }}
            >
              {row.value.name}
            </div>
          </div>
        ),
      },
      {
        Header: "Montante Poupado",
        headerClassName: "tableheader tableheader-desktop",
        headerStyle: {
          textAlign: "right",
          paddingRight: "10px",
          borderCollapse: "collapse",
          borderBottom: "2px solid #595959",
          background: "white",
        },
        accessor: "partnerTotalValue",
        className: "montante-desktop",
        Cell: (row) => (
          <div className="savedValue-col">
            {new Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
            }).format(row.value)}
          </div>
        ),
      },
      {
        Header: "Montante",
        headerClassName: "tableheader tableheader-mobile",
        headerStyle: {
          textAlign: "right",
          paddingRight: "10px",
          borderCollapse: "collapse",
          borderBottom: "2px solid #595959",
          background: "white",
        },
        accessor: "partnerTotalValue",
        className: "montante-mobile",
        Cell: (row) => (
          <div className="savedValue-col">
            {new Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
            }).format(row.value)}
          </div>
        ),
      },
    ];

    const sortMethodUpdown = [
      {
        id: "partnerTotalValue",
        desc: true,
      },
    ];
    const sortMethodDownUp = [
      {
        id: "partnerTotalValue",
        desc: false,
      },
    ];
    const sortMethodA_Z = [
      {
        id: "partnersort",
        desc: true,
      },
    ];
    const sortMethodZ_A = [
      {
        id: "partnersort",
        desc: false,
      },
    ];

    return (
      <div id="ByPartner" className="tabcontent">
        <div className="divfilter">
          <div className="filter-information visible-under-500">
            <table className="filter-information visible-under-500">
              <tbody>
                <tr>
                  <th className="filter-information-title">Já poupou</th>
                </tr>
                <tr>
                  <td className="filter-information-value">
                    {new Intl.NumberFormat("de-DE", {
                      style: "currency",
                      currency: "EUR",
                    }).format(benefits.totalBenefits)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="filter-dropdown">
            <div className="dropdown-title">Ordenar Por</div>
            <UncontrolledDropdown>
              <DropdownToggle className="dropdown-benefits-button">
                {this.state.sortSelect}
              </DropdownToggle>
              <DropdownMenu className="dropdown-benefits-menu">
                <DropdownItem
                  className={
                    this.state.Sort == "1"
                      ? "dropdown-benefits-option selected"
                      : "dropdown-benefits-option"
                  }
                  onClick={this.setSort.bind(this, "1")}
                >
                  Maior Poupança
                </DropdownItem>
                <DropdownItem
                  className={
                    this.state.Sort == "2"
                      ? "dropdown-benefits-option selected"
                      : "dropdown-benefits-option"
                  }
                  onClick={this.setSort.bind(this, "2")}
                >
                  Menor Poupança
                </DropdownItem>
                <DropdownItem
                  className={
                    this.state.Sort == "3"
                      ? "dropdown-benefits-option selected"
                      : "dropdown-benefits-option"
                  }
                  onClick={this.setSort.bind(this, "3")}
                >
                  Ordenar por A-Z
                </DropdownItem>
                <DropdownItem
                  className={
                    this.state.Sort == "4"
                      ? "dropdown-benefits-option selected"
                      : "dropdown-benefits-option"
                  }
                  onClick={this.setSort.bind(this, "4")}
                >
                  Ordenar por Z-A
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          <div className="filter-information">
            <table className="filter-information">
              <tbody>
                <tr>
                  <th className="badge-wrapper" rowSpan="2">
                    <img height={48} src={require("../../images/Badge.png")} />
                  </th>
                  <th className="filter-information-title">Já poupou </th>
                </tr>
                <tr>
                  <td className="filter-information-value">
                    {new Intl.NumberFormat("de-DE", {
                      style: "currency",
                      currency: "EUR",
                    }).format(benefits.totalBenefits)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <ReactTable
            columns={columnsB}
            data={listBenefitsB}
            className="tableStyle"
            resizable={false}
            pageSize={listBenefitsBCount}
            nextText=">"
            minRows="0"
            previousText="<"
            sortable={false}
            defaultSorted={
              this.state.Sort == "1"
                ? sortMethodUpdown
                : this.state.Sort == "2"
                ? sortMethodDownUp
                : this.state.Sort == "3"
                ? sortMethodA_Z
                : sortMethodZ_A
            }
            showPagination={this.state.hasBenefits}
            noDataText={this.notfound}
            PaginationComponent={Pagination}
          ></ReactTable>
        </div>
      </div>
    );
  }

  render() {
    let contents = this.state.loading ? (
      <div className="main-page-loading">
        <div className="loading">
          <img src={require("../../images/loading.svg")}></img>
        </div>
      </div>
    ) : this.state.error ? (
      this.errorLoadingPortfolio()
    ) : (
      this.RenderBenefitiesData()
    );

    return <main>{contents}</main>;
  }

  RenderBenefitiesData() {
    const content = {
      aTab: this.tabContentsA(this.state.benefits),
      bTab: this.tabContentsB(this.state.benefits),
    };

    const toggleShowFunction = () => {
      this.setState({
        toggleActive: !this.state.toggleActive,
      });
    };

    const changeGraphYearFunction = (item) => {
      toggleShowFunction();

      if (item) {
        //vai limitar os dados mostrados ao ano selecionado
        //Define o filtro
        this.setState({
          graphYearSelected: item.year,
          graphDataDisplay: item.benefitsWithYear,
          graphTotalDisplay: item.yearSavedValue,
        });
      } else {
        this.setState({
          graphYearSelected: "Total",
          graphDataDisplay: this.state.benefits.benefitsByCategory,
          graphTotalDisplay: this.state.benefits.totalBenefits,
        });
      }

      setTimeout(function () {
        toggleShowFunction();
      }, 1);
    };

    const toggleShow = (state) => {
      if (!this.state.hasBenefits) {
        return (
          <div className="filtros-beneficios">
            <a className="toggle-text-inactive">Mostrar</a>
            <div className="inactive-tooltip">
              <img
                src={require("../../images/toggle-inactive.png")}
                style={{ cursor: "pointer" }}
                width="38.68px"
                height="22px"
              ></img>
              <span className="inactive-tooltip-text">Sem dados</span>
            </div>
          </div>
        );
      } else if (this.state.hasBenefits) {
        if (state.toggleActive) {
          return (
            <div className="filtros-beneficios">
              <a className="toggle-text-active">Mostrar</a>
              <img
                src={require("../../images/toggle-active.png")}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  toggleShowFunction();
                }}
                width="38.68px"
                height="22px"
              ></img>
            </div>
          );
        } else if (!state.toggleActive)
          return (
            <div className="filtros-beneficios">
              <a className="toggle-text-inactive">Mostrar</a>
              <img
                src={require("../../images/toggle-inactive.png")}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  toggleShowFunction();
                }}
                width="38.68px"
                height="22px"
              ></img>
            </div>
          );
      }
    };

    const filterGraph = (
      <div className="filter-dropdown filter-dropdown-graph ">
        <UncontrolledDropdown>
          <DropdownToggle className="dropdown-benefits-filter-graph">
            {this.state.graphYearSelected}
          </DropdownToggle>
          <DropdownMenu className="dropdown-benefits-menu">
            <DropdownItem
              className={
                this.state.graphYearSelected == "Total"
                  ? "dropdown-benefits-option selected"
                  : "dropdown-benefits-option"
              }
              onClick={changeGraphYearFunction.bind(this, null)}
            >
              Total
            </DropdownItem>
            {this.state.graphYears.map((item, index) => (
              <DropdownItem
                key={index}
                className={
                  this.state.Sort == item.year
                    ? "dropdown-benefits-option selected"
                    : "dropdown-benefits-option"
                }
                onClick={changeGraphYearFunction.bind(this, item)}
              >
                {item.year}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    );

      const pieChart = (
          <div className="pie-div" style={{ marginTop: "55px" }}>
        <BenefitsPie
          data={this.state.graphDataDisplay}
          valorPoupado={this.state.graphTotalDisplay}
        />
      </div>
    );

    return (
      <div className="main">
        <NotificationBanner type="0" />
        <div className="beneficios-title-div">
          <div
            className="beneficios-title-mobile"
            style={{ width: "101%", float: "left" }}
          >
            <p className="beneficios-title-top">Benefícios</p>
            <p className="beneficios-title-bottom">
              Os valores apresentados podem não representar a totalidade dos
              benefícios usufruídos
            </p>
          </div>

          <div className="beneficios-toggle-div">
            <div className="filter-graph">
              {this.state.toggleActive && filterGraph}
            </div>
            {toggleShow(this.state)}
          </div>
        </div>
        <div className="filter-graph-mobile">
          {this.state.toggleActive && filterGraph}
        </div>
        {this.state.toggleActive && pieChart}
        <div className="beneficios-title">Benefícios Gerados</div>
        <div className="tab">
          <Tabs
            active={this.state.active}
            onChange={(active) => this.setState({ active })}
          >
            <div key="aTab">DATA</div>
            <div key="bTab">ENTIDADES</div>
          </Tabs>
        </div>
        <div>{content[this.state.active]}</div>
      </div>
    );
  }

  async populateBenefitsData() {
    //const headers = {
    //    Authorization: `Bearer ${await authentication.getAccessToken()}`
    //    ,
    //};
    //const response = await fetch("/Benefits/GetBenefits", { headers });
    //appInsights.trackException({
    //  exception: "Benefits no content",
    //  severityLevel: 3,
    //});
    //if (response.status != "200") {
    //  this.setState({ error: true, loading: false });
    //  if (response.status === 204)
    //    appInsights.trackException({
    //      exception: "Benefits no content",
    //      severityLevel: 3,
    //    });
    //} else {
    //  const data = await response.json();

    //  let DateString = "";
    //  if (data.benefitsAll.length > 0) {
    //    DateString =
    //      new Intl.DateTimeFormat("pt-PT").format(
    //        new Date(data.benefitsAll[0].transactionDate)
    //      ) +
    //      " - " +
    //      new Intl.DateTimeFormat("pt-PT").format(new Date());
    //  }

    //  this.setState({
    //    graphDataDisplay: data.benefitsByCategory,
    //    graphTotalDisplay: data.totalBenefits,
    //    graphYears: data.benefitsByYearAndCategory,
    //    benefits: data,
    //    DateString: DateString,
    //    error: false,
    //    loading: false,
    //  });
    //  if (data.totalBenefits == 0) {
    //    this.setState({
    //      hasBenefits: false,
    //    });
    //  } else
    //    this.setState({
    //      hasBenefits: true,
    //    });
    //}

      var clientId = this.context.selectedUserID;

      //console.log("Programa Benefits ClientId ", clientId, " contexto ", this.context);

      const headers = {
          Authorization: `Bearer ${authentication.getAccessToken()}`,
          clientId: clientId,
          'Accept': 'application/json, text/plain',
          'Content-Type': 'application/json;charset=UTF-8'
      };

      await fetch(`/Benefits/GetBenefits`, {
          headers,
          method: 'GET',
      })
          .then((res) => {
              //console.log("res pages Beneficios.js ", res)

              return res.json();
          })
          .then((response) => {
              //console.log("response GetBenefits (pages Beneficios.js) ", response);

              let DateString = "";
              if (response.benefitsAll.length > 0) {
                  DateString =
                      new Intl.DateTimeFormat("pt-PT").format(
                          new Date(response.benefitsAll[0].transactionDate)
                      ) +
                      " - " +
                      new Intl.DateTimeFormat("pt-PT").format(new Date());
              }

              this.setState({
                  graphDataDisplay: response.benefitsByCategory,
                  graphTotalDisplay: response.totalBenefits,
                  graphYears: response.benefitsByYearAndCategory,
                  benefits: response,
                  DateString: DateString,
                  error: false,
                  loading: false,
              });

              if (response.totalBenefits == 0) {
                  this.setState({ hasBenefits: false });
              } else {
                  this.setState({ hasBenefits: true });
              }
          })
          .catch((error) => {
              console.log("error beneftis ", error);

              if (error.status != "200") {
                  this.setState({ error: true, loading: false });

                  if (error.status === 204) {
                      appInsights.trackException({
                          exception: "Benefits no content",
                          severityLevel: 3,
                      });
                  }
              }
          });

  }
}

Beneficios.contextType = ContextConstructor;

export default withAITracking(reactPlugin, Beneficios);
