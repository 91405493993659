
/* COMPONENT IMPORTS*/

/* MODULE IMPORTS */
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

/* CSS IMPORT */
import './RepresentativeResponse.css';

export class MaioridadePage extends Component {
    static displayName = MaioridadePage.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    render() {

        return (
            <div className="main">
                <center>
                    <img style={{ "marginBottom": "30px" }} src={require('../../images/erro.png')} alt="" width="80px" height="80px"></img>
                    <div className="error-title">
                        Já não é possível aceder a My Montepio,
                        <br />
                        uma vez que o jovem atingiu a maioridade.
                    </div>

                    <br />

                    <div className="success-description">
                        Agradecemos que seja concretizada uma nova adesão, com os dados pessoais deste,
                        de modo a poder continuar a usufruir deste serviço.
                    </div>

                    <div className="success-description">
                        <p>
                            <img src={require("../../images/alerta.png")} alt="erro" width="5%"></img>
                            Antes da nova adesão a My Montepio, devem ser confirmados
                            e atualizados os dados do jovem maior de idade.
                        </p>
                    </div>
                    <div id="MyDivsuccess" style={{top: "15px"}}>
                        <span>
                            <div id="tituloerro" className="erro-geral"></div>
                            <div id="conteudoerro" className="erro-geral">
                                Para mais informações, contacte o seu Gestor Mutualista
                                <br />
                                ou a Linha de Apoio.
                            </div>
                            <div id="duvidas2">212 248 112</div>
                            <br />

                            <div id="duvidas3" style={{ "color":"#FF5800"}}>
                                (atendimento personalizado, dias úteis das 09h às 21h.
                                <br />
                                Custo de Chamada para a rede fixa nacional).
                            </div>
                            <div id="duvidas3"> Suporte_MyMontepio@montepio.org </div>

                            <br />

                            <NavLink
                                onClick={() => {
                                    this.props.close();
                                }}
                                to="/"
                                style={{ color: "white", padding: "2%" }}
                                className="button-orange-filled"
                            >
                                Voltar à página inicial
                            </NavLink>
                        </span>
                    </div>                   
                </center>
            </div>
            );
    }
}
