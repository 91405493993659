import React, { Component } from "react";
import { NavLink } from 'react-router-dom';

export class SavingsCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            savingsIncome: this.props.income,
            savingsGain: this.props.gain
        };
    }

    render() {
        return (
            <div className="poupanca">
                <div className="title-home">
                    Poupança
                </div>
                <NavLink to="/poupanca" style={{ "textDecoration": "none" }}>
                    <div className="poupanca-card">
                        <img src={require('../../images/poupanca.svg')} alt=""></img>
                        <div className="poupanca-income">
                            {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(this.state.savingsIncome)}
                        </div>
                        <div className="poupanca-gain">
                            rendimento de +{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(this.state.savingsGain)}
                        </div>
                        <div className="poupanca-description">
                            Aplicados em modalidades de capitalização
                        </div>
                        <div className="subscribe">
                            <img src={require('../../images/more.png')} alt="Botão de subscrever" width="60px" height="60px"></img>
                            <div>
                                Subscrever
                            </div>
                        </div>
                    </div>
                </NavLink>
            </div>
        )
    }
}
