/* MODULE IMPORTS */
import React, { Component } from 'react';

/* CSS IMPORT */
import './erropg.css';


export class ErroSms extends Component {
    static displayName = ErroSms.name;

    render() {
        return (
			<div className="main" style={{ "paddingLeft": '0px' }}>
				<header className="toolbar">
					<nav className="toolbar_navigation">
						<div className="column">
							<a href="/">
								<div className="toolbar_logo">
									<img src={require('./imgs/mymontepio-registo.svg')} alt="MyMontepio" width="164" height="48" />
								</div>
							</a>
						</div>
						<div className="column-direita">
                            <div className="toolbar_logo"><img src={require('./imgs/Vector.png')} alt="Montepio" width="164" height="48" />
						</div>
								</div>
		</nav>
			</header>
				<div id="MyDiv">
					<img id="imgerro" src={require('./imgs/error.png')} alt="erro" />
					<span><div id="tituloerro">Excedeu o limite de tentativas.<br/>Tente mais tarde.</div>
						<div id="duvidas1">Linha de apoio My Montepio</div>
						<div id="duvidas2">213 248 112</div>
						<div id="duvidas3">Dias úteis, das 9h00 às 21h00. Custo de chamada para a rede fixa nacional</div>
						<div id="duvidas3"><a href="mailto:Suporte_MyMontepio@montepio.org" target="_top">Suporte_MyMontepio@montepio.org</a></div>
						<a href="/"><button id="btVoltar">Voltar à página inicial</button></a>
					</span>
				</div>
			</div>
             );
    };
};
