/* COMPONENT IMPORTS */
import ContextConstructor from "../../components/Context/Context";

/* MODULE IMPORTS */
import React, { Component } from "react";

class Tabs extends Component {

    render() {
        return (
            <div>
                {React.Children.map(this.props.children, (child, i) => {
                    let className = "Tabs__Tab";
                    if (child.key === this.props.active) {
                        className = `${className} Tabs__Tab--active`;
                    }
                    return (
                        <div
                            className={className}
                            onClick={() => {
                                this.props.onChange(child.key);
                            }}
                        >
                            {child}
                        </div>
                    );
                })}
            </div>
        );
    }
}

Tabs.contextType = ContextConstructor;

export default Tabs;
