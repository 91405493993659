import adb2cauth from './adb2cauth';

const getadb2cauth = async () => {
    return adb2cauth()
        .then(res => {
            var appConfig = {
                applicationId: res.clientID,
                instance: res.instance,
                tenant: res.tenant,
                signInPolicy: 'b2c_1a_signup_signin',
                resetPolicy: 'B2C_1A_PasswordReset',
                editPolicy: 'B2C_1A_ProfileEdit',
                cacheLocation: 'sessionStorage',
                scopes: ['openid'],
                /*redirecturi: 'https://localhost:5001/signin-oidc',*/
                redirectUri: res.redirectUri,
                postLogoutRedirectUri: res.postLogoutRedirectURI,
                validateAuthority: false,
            };
            
            return appConfig;
        });
}

export default getadb2cauth;