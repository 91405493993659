/* MODULE IMPORTS */
import React, { Component } from 'react';

/* CSS IMPORT */
import './success.css';

export class NewAccountSuccess extends Component {
    static displayName = NewAccountSuccess.name;

    render() {
        return (
            <div>
                <div className="header">
                    <header class="toolbar">
                        <nav class="toolbar_navigation">
                            <div class='column'>
                                <a href="/">
                                    <div class="toolbar_logo">
                                        <img src={require('./imgs/mymontepio-registo.svg')} alt="MyMontepio" width="164" height="48"></img>
                                    </div>
                                </a>
                            </div>
                            <div class='column-direita'>
                                <div class="toolbar_logo"><img src={require('./imgs/Vector.png')} alt="Montepio" width="164" height="48"></img>
                                </div>
                            </div>
                        </nav>
                    </header>
                </div >
                <div id ="MainContainer">
                    <div id="MyDivsuccess">
                    <img id="imgerro" src={require('./imgs/success.png')} alt="erro"/>
                        <p id="tituloerro">O seu registo em My Montepio <br/> foi criado com sucesso.</p>
                        <a href="/" ><button className="no-text-before" id="btVoltar">Consultar My Montepio</button></a>
                    </div>
                </div>
            </div>
            
                );
    };
};