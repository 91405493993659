
/* COMPONENT IMPORTS*/

/* MODULE IMPORTS */
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

/* CSS IMPORT */
import '../RepresentativeResponse/RepresentativeResponse.css';

export class NotAssociateHome extends Component {
    static displayName = NotAssociateHome.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    render() {

        return (
            <center>
                <div id="conteudoerro" className="erro-geral" style= {{"text-align": "center"}}>
                    Ainda não é Associado?
                    <br />
                    Junte-se a esta grande família, beneficie de soluções de Poupança e Proteção
                    <br />
                    e garanta muitas outras vantagens!
                    <br/>
                </div>
                        <img
                            src={require("../../images/icon-amm.png")}
                            alt=""
                        />
                <br />
                <br />
                <div id="duvidas1" style={{ "text-align": "center" }}>
                    Um projeto no qual mais é mais:
                    <br />
                    mais associados, mais vantangens
                </div>
            </center>
        );
    }
}
