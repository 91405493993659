
/* COMPONENT IMPORTS*/

/* MODULE IMPORTS */
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

/* CSS IMPORT */
import '../RepresentativeResponse/RepresentativeResponse.css';

export class LandingPage extends Component {
    static displayName = LandingPage.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    render() {

        return (
            <React.Fragment>
                <div id="duvidas1" style={{ "text-align": "left" }}>Bem-vindo</div>
                <br />
                <div id="conteudoerro" className="erro-geral" style={{ "text-align": "left" }}>
                    Para aceder à posição das modalidades subscritas pela criança/jovem a seu cargo, clique na seta
                    acima, junto ao seu Nome, e adicione o NIF da criança/jovem:
                </div>
                
                <NavLink
                    to="/AdicionaMenor"
                    style={{ textDecoration: "none"}}
                >
                    <div
                        onClick={this.closeMenuFunction}
                        className="dadosconta-button"
                        style={{ "width": "61%", "margin-left":"0%"}}
                    >
                        <img style={{ marginLeft: "-6px" }}
                            src={require("../../images/add_person.png")}
                            alt=""
                            width="26px"
                            height="´26px"
                        />

                        <div style={{ marginTop: "0px", marginLeft: "22px", textDecoration: "none"}}>Adicionar Criança/Jovem Associado</div>

                    </div>

                </NavLink>
                <br />
                <div id="conteudoerro" className="erro-geral" style={{ "text-align": "left" }}>
                    Depois de adicionado, poderá consultar as modalidades subscritas em nome da criança/jovem, fazer
                    reforços ou subscrição de novas modalidades, consultar o correio digital, os benefícios atribuídos e
                    outras informações.
                </div>
                
                <div id="conteudoerro" className="erro-geral" style={{ "text-align": "left" }}>
                    Visite-nos em montepio.org ou siga-nos nas redes sociais, onde encontra informação sempre
                    atualizada sobre o Montepio Associação Mutualista.
                </div>
            </React.Fragment>
        );
    }
}
