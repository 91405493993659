
/* COMPONENT IMPORTS*/
import { ErrorPage } from '../../components/ErrorPage/ErrorPage';
import { ErrorPageQuotes } from '../../components/ErrorPageQuotes/ErrorPageQuotes';
import authentication from '../../msalb2creact';
import ReactTable from 'react-table-v6';
import Pagination from "../Beneficios/Pagination.js";

/* MODULE IMPORTS */
import React, { Component } from "react";

/* CSS IMPORT */
import "./Fees.css";
import "../Beneficios/Pagination.css";

export class Fees extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        //this.populateData();
    }

    //renderQuotasListTable(quotasList) {
    //    const columns = [
    //        {
    //            Header: '',
    //            accessor: 'icon',
    //            minWidth: 20,
    //            maxWidth: 30,
    //            Cell: props => <img style={{ "width": "12px" }} src={require("../../images/alertaCircle.png")} alt="Alerta" />
    //        },
    //        {
    //            Header: '',
    //            accessor: 'date',
    //            minWidth: 105,
    //            maxWidth: 155,
    //            Cell: row => (
    //                <div className="quota-date">
    //                    {new Intl.DateTimeFormat('pt-PT').format(new Date(row.value))
    //                    }
    //                </div>)
    //        },
    //        {
    //            Header: '',
    //            accessor: 'productName',
    //            className: 'quota-column',
    //            Cell: row => (
    //                <div className="quota-value-wrapper">
    //                    < div className="quota-value-text">{row.value}</div >
    //                </div>
    //            )
    //        }/*,
    //        {
    //            Header: '',
    //            accessor: 'value',
    //            className: 'quota-column',
    //            Cell: row => (
    //                <div className="quota-value-wrapper">
    //                    <span className="quota-value-amount">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(row.value)}</span>
    //                </div>
    //            )
    //        }*/
    //    ];

    //    return <ReactTable
    //        data={quotasList}
    //        columns={columns}
    //        resizable={false}
    //        nextText=">"
    //        previousText="<"
    //        sortable={false}
    //        PaginationComponent={Pagination}
    //        defaultPageSize={6}
    //        minRows={0}
    //    />
    //}

    //renderPopup(/*quotasTotalAmount, quotas*/) {
    //    return (       
    //        <div className="quotas-page main">
    //            <div className="title-bar">
    //                <div className="title title-fees">Regularização de Quotas Associativas</div>
    //            </div>
    //            <div className="fees-text">
    //                Assegure o pagamento das suas quotas e preserve todas as vantagens associativas. Pague comodamente creditando a sua conta bancária.
    //            </div>
    //            <ErrorPageQuotes /> 
    //            {/*<div className="quotas-list-table-header-text">
    //                TOTAL EM DÍVIDA:&nbsp;<span className="quotas-list-table-header-amount">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(quotasTotalAmount)}</span>
    //            </div>*/
    //            }
    //            {/*this.renderQuotasListTable(quotas)*/}
    //        </div>
    //    );
    //}


    render() {
        let content = "";

        let feesContent =
            <div className="quotas-page main">
                <div className="title-bar">
                    <div className="title title-fees">Regularização de Quotas Associativas</div>
                </div>
                <div className="fees-text">
                    Assegure o pagamento das Quotas Associativas em atraso e continue a garantir todas as vantagens da sua Associação.
                    Para tal basta aprovisionar a conta à ordem onde as quotas são cobradas.
                </div>
                <br/>
                <ErrorPageQuotes /> 
            </div>

        if (!this.state.loading) {
            //let quotasTotalAmount = this.state.quotasList.totalValue;
            //let quotas = this.state.quotasList.feeLines;
            //content = this.state.error ? <ErrorPage /> : (quotasTotalAmount == 0.0) ? <ErrorPageQuotes /> : this.renderPopup(quotasTotalAmount, quotas);
            //content =  this.renderPopup(quotasTotalAmount, quotas);

            content = feesContent;
        }
        return feesContent;
    }

    //async populateData() {

    //    await new Promise(r => setTimeout(r, 1));
    //    const headers = { Authorization: `Bearer ${authentication.getAccessToken()}` };
    //    const response = await fetch("/Quotas/GetFees", { headers });

    //    if (response.status !== 200) {
    //        this.setState({ error: true, loading: false });
    //    } else {
    //        const data = await response.json();
    //        this.setState({
    //            quotasList: data,
    //            error: false,
    //            loading: false
    //        });
    //    }
    //}
}
